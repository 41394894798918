<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">CR-1 練習２ 検査２ p02</div>

    <!-- ↓ p02 ～ p07 は全く同じ --><!-- 本テスト 検査２ p01 を流用 -->

    <div class="row">

      <div class="col-12 px-3">
        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no range">{{ params[questionIds[0]].q_no }}～{{ params[questionIds[1]].q_no }}</div>
              <MyTextBox class="question_str_100" :canEdit="canEdit" v-model="params.s01" />
            </div>
          </div>
        </div>
        <MyTextBox class="my-3" :isTextarea="true" :canEdit="canEdit" v-model="params.s02" />
      </div>

      <div class="col-12 mt-2 mb-4 px-3">

        <div class="question_tab_wrapper">

          <div class="question_tab_contents">

            <div v-show="currentTabNo==1" :class="`question_tab_content ${questionIds[0]}`">

              <div class="question_table_wrapper">
                <div class="question_table">
                  <div class="question_table_row">
                    <div class="question_no">{{ params[questionIds[0]].q_no }}</div>
                    <div class="text_container_80 bg-lightyellow">
                      <MyTextBox class="question_str_100" :canEdit="canEdit" v-model="params[questionIds[0]].question" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="choice_table_wrapper ml-md-5">
                <div class="choice_table">
                  <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
                    <input type="checkbox" :id="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                            v-model="params[questionIds[0]].answer"
                            :ref="`checkbox_q${questionIds[0]}`"
                            @click="onClickCheckbox(params[questionIds[0]], $refs[`checkbox_q${questionIds[0]}`], $event)"
                            @change="sortCheckboxAnswers(params[questionIds[0]])"
                    />
                    <label :for="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox_placeholder has_value">
                      <div class="choice_value">{{ choiceValue }}：</div>
                    </label>
                    <label :for="`${questionIds[0]}_${choiceValue}`" class="">
                      <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[0]].choices[choiceIdx]" />
                    </label>
                  </div>
                </div>
              </div>

            </div><!-- question_tab_content -->

            <div v-show="currentTabNo==2" :class="`question_tab_content ${questionIds[1]}`">

              <div class="question_table_wrapper">
                <div class="question_table">
                  <div class="question_table_row">
                    <div class="question_no">{{ params[questionIds[1]].q_no }}</div>
                    <div class="text_container_80 bg-lightyellow">
                      <MyTextBox class="question_str_100" :canEdit="canEdit" v-model="params[questionIds[1]].question" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="choice_table_wrapper ml-md-5">
                <div class="choice_table">
                  <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[1]" :key="choiceValue">
                    <input type="checkbox" :id="`${questionIds[1]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                            v-model="params[questionIds[1]].answer"
                            :ref="`checkbox_q${questionIds[1]}`"
                            @click="onClickCheckbox(params[questionIds[1]], $refs[`checkbox_q${questionIds[1]}`], $event)"
                            @change="sortCheckboxAnswers(params[questionIds[1]])"
                    />
                    <label :for="`${questionIds[1]}_${choiceValue}`" class="choice_checkbox_placeholder has_value">
                      <div class="choice_value">{{ choiceValue }}：</div>
                    </label>
                    <label :for="`${questionIds[1]}_${choiceValue}`" class="">
                      <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[1]].choices[choiceIdx]" />
                    </label>
                  </div>
                </div>
              </div>

            </div><!-- question_tab_content -->

          </div><!-- question_tab_contents -->

          <QuestionTabButtons :tabCount="tabCount" v-model="currentTabNo" @input="tabHasBeenChanged=true" />

        </div><!-- question_tabs_wrapper -->

      </div><!-- col -->

    </div><!-- row -->

    <!-- ↑ p02 ～ p07 は全く同じ -->

    <div v-if="showDescription" class="my-3">

      <hr />

      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper bordered">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
import QuestionTabButtons from '../../../QuestionTabButtons';
const LogHeader = 'CR-1/練習２/検査２/p02';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    QuestionTabButtons,
  },
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q018', 'q019' ],
      choiceValues: [
        [ 'ア', 'イ', 'ウ', 'エ' ],
        [ 'ア', 'イ', 'ウ', 'エ' ],
      ],
      tabCount: 2,
      currentTabNo: 1,
      tabHasBeenChanged: false,
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

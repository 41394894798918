<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WT-1 練習２ 検査１ p03</div>

    <!-- ↓ p03 は単独 -->

    <div class="row">

      <div class="col-12 px-3">
        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no">{{ params[questionIds[0]].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" :isTextarea="true" v-model="params[questionIds[0]].question" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 my-3 px-3">
        <MyTextBox class="" :isTextarea="true" :canEdit="canEdit" v-model="params[questionIds[0]].s01" />
      </div>

      <div class="col-12 col-md-6">
        <div class="choice_table_wrapper ml-md-5">
          <div class="choice_table bg-lightyellow">
            <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
              <div class="choice_value text-red right_margin">{{ choiceValue }}</div>
              <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[0]].choices[choiceIdx]" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="checkbox_table_wrapper ml-md-5">
          <table class="checkbox_table">
            <tr>
              <td></td>
              <td v-for="choiceValue in choiceValues[0]" :key="choiceValue"><div class="choice_value">{{ choiceValue }}</div></td>
            </tr>
            <tr v-for="(answerMarker, answerIdx) in answerIndices" :key="answerMarker">
              <td class="answer_index">{{ answerMarker }}</td>
              <td v-for="choiceValue in choiceValues[0]" :key="`${answerMarker}_${choiceValue}`">
                <input type="radio" :id="`${questionIds[0]}_${answerMarker}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                        v-model="params[questionIds[0]].answer[answerIdx]"
                        :ref="`radio_${questionIds[0]}_${answerMarker}`"
                        @click="onClickMultiRadio2(questionIds[0], `radio_${questionIds[0]}_${answerMarker}`, answerIdx, $event, true/*排他選択*/)"
                />
                <label :for="`${questionIds[0]}_${answerMarker}_${choiceValue}`" class="choice_checkbox_placeholder"></label>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div><!-- row -->

    <!-- ↑ p03 は単独 -->

    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>
            <span class="pr-1">正解</span>
            <span v-for="(answerMarker, answerIdx) in answerIndices" :key="answerMarker">{{ answerMarker }}︓{{ getRawDescAnswer(params[questionId])[answerIdx] }}&nbsp;</span>
          </template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WT-1/練習２/検査１/p03';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q011' ],
      answerIndices: [ 'ア', 'イ', 'ウ'],
      choiceValues: [
        [ 'A', 'B', 'C' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

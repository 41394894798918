<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WT-1 練習２ 検査２ p07</div>

    <!-- ↓ p01 ～ p08, p13, p14 は全く同じ --><!-- 本テスト 検査２ p01 を流用 -->

    <div class="row">
      <div :class="`col-12 px-3 ${questionIds[0]}`">

        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no">{{ params[questionIds[0]].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" v-model="params[questionIds[0]].question" />
            </div>
          </div>
        </div>

        <div class="text_container mt-3 ml-md-5 pl-1 pl-md-3">
          <MyTextBox class="" :isTextarea="true" :canEdit="canEdit" v-model="params[questionIds[0]].s01" />
        </div>

        <div class="textbox_table_wrapper mt-4 ml-md-5">
          <div class="textbox_table">
            <div class="textbox_table_row">
              <input type="text" class="answer_textbox text-right" :disabled="!canAnswer" v-model="params[questionIds[0]].answer[0]"
                      :maxLength="AnsMaxLen_練習"
                      @change="onChangeTextbox"
              />
            </div>
          </div>
        </div>

      </div>
    </div><!-- row -->

    <!-- ↑ p01 ～ p08, p13, p14 は全く同じ -->

    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WT-1/練習２/検査２/p07';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q007' ],
      choiceValues: [ ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
.answer_textbox
  width: 8em
</style>

<template>

  <div>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 184">
        <!-- <circle :cx="cx" :cy="cy" :r="r1" stroke="#707070" stroke-width="0.3" fill="#E9E900" /> -->
        <path :d="`M ${cx},${cy} L ${pageRateStartPosX},${pageRateStartPosY} A ${r1} ${r1} 0 ${pageRate<=0.5?0:1} 1 ${pageRateEndPosX},${pageRateEndPosY} z`" stroke="#707070" stroke-width="0.3" fill="#E9E900" />
        <circle :cx="cx" :cy="cy" :r="r2" stroke="none" _stroke-width="0.3" fill="#F1F1F1" />
        <!-- <circle :cx="cx" :cy="cy" :r="r2" stroke="#707070" stroke-width="0.3" fill="#78BDEB" /> -->
        <path :d="`M ${cx},${cy} L ${timeRateStartPosX},${timeRateStartPosY} A ${r2} ${r2} 0 ${timeRate<=0.5?0:1} 1 ${timeRateEndPosX},${timeRateEndPosY} z`" stroke="#707070" stroke-width="0.3" fill="#78BDEB" />
        <circle :cx="cx" :cy="cy" :r="r1" stroke="#000000" stroke-width="2.0" fill="none" />
        <circle :cx="cx" :cy="cy" :r="r2" stroke="#000000" stroke-width="2.0" fill="none" />
        <text transform="translate(0 150)" font-size="20" font-family="Meiryo-Bold, Meiryo" font-weight="700" _text-anchor="middle"><tspan x="0" y="0">内：進行状況</tspan></text>
        <text transform="translate(0 180)" font-size="20" font-family="Meiryo-Bold, Meiryo" font-weight="700" _text-anchor="middle"><tspan x="0" y="0">外：制限時間(合計)</tspan></text>
      </svg>
    </div>
    <div v-if="isDebug" class="debug_str">
      <div>pageRate=[{{ `${pageRate}`.substr(0,5) }}]</div>
      <div>timeRate=[{{ `${timeRate}`.substr(0,5) }}]</div>
      <!--
      <div>pageRateEndPos=({{ `${pageRateEndPosX}`.substr(0,7) }}, {{ `${pageRateEndPosY}`.substr(0,7) }})</div>
      <div>timeRateEndPos=({{ `${timeRateEndPosX}`.substr(0,7) }}, {{ `${timeRateEndPosY}`.substr(0,7) }})</div>
      -->
    </div>
  </div>

</template>

<script>
const LogHeader = 'ProgressPieChart';
export default {
  // model: {
  //   prop: 'value',
  //   event: 'input',
  // },
  props: {
    value: { default: null },//不使用
    isDebug: { default: false },
    examinationPart: { default: null },
    currentPageNo: { default: null },//1-
  },
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      cx: 70,
      cy: 60,
      r1: 56,
      r2: 28,
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    // internalValue: {
    //   get() {
    //     return this.value;
    //   },
    //   set(newValue) {
    //     if (newValue != this.value) {
    //       //console.log(`[${LogHeader}] emitting(input) newValue=[${newValue}]`);//デバッグ用（本番コメントアウト）
    //       this.$emit('input', newValue);
    //     }
    //   }
    // }
    currentPageName() { return (this.examinationPart&&this.currentPageNo)? this.examinationPart.page_names[this.currentPageNo-1]: null; },
    currentPageParams() { return this.examinationPart&&this.currentPageName? this.examinationPart.all_page_params_set[this.currentPageName]: null; },
    pageRate() {
      let pageRate = 0.0;
      if ([2, 102, 112].includes(this.examinationPart.exam_part_no)) {//FIXME 現在の検査が「何分のいくつ」か算出できないのでハードコーディングでしのいでいる
        pageRate += 0.5;
      }
      if (this.currentPageParams) {
        console.log(`[${LogHeader}] pageRate() currentPageNo=[${this.currentPageNo}] page_count=[${this.examinationPart.page_count}]`);
        pageRate += (this.currentPageNo / this.examinationPart.page_count) / 2/*半円分なので÷２*/;
      }
      return pageRate;
    },
    timeRate() {
      let timeRate = 0.0;
      if ([2, 102, 112].includes(this.examinationPart.exam_part_no)) {//FIXME 現在の検査が「何分のいくつ」か算出できないのでハードコーディングでしのいでいる
        timeRate += 0.5;
      }
      if (this.examinationPart) {
        let sumTimeLimitSec = 0;
        for (let pageKey/* p01, p02, ... */ in this.examinationPart.all_page_params_set) {
          if (pageKey == this.currentPageName) {
            break;
          }
          let pageParams = this.examinationPart.all_page_params_set[pageKey];
          sumTimeLimitSec += pageParams.time_limit_sec;
        }
        console.log(`[${LogHeader}] timeRate() sumTimeLimitSec=[${sumTimeLimitSec}] examPart.time_limit_sec=[${this.examinationPart.time_limit_sec}]`);
        timeRate += (sumTimeLimitSec / this.examinationPart.time_limit_sec) / 2/*半円分なので÷２*/;
      }
      return timeRate;
    },
    pageRateStartPosX() { return this.cx; },
    pageRateStartPosY() { return this.cy - this.r1; },
    pageRateRadian() {
      const limitRate = 0.99;//円弧がほぼ360°になると描画がおかしくなる現象の対策
      return 2 * Math.PI * (this.pageRate<=limitRate? this.pageRate: limitRate);
    },
    pageRateEndPosX() { return this.cx + Math.sin(this.pageRateRadian) * this.r1; },
    pageRateEndPosY() { return this.cy - Math.cos(this.pageRateRadian) * this.r1; },
    timeRateStartPosX() { return this.cx; },
    timeRateStartPosY() { return this.cy - this.r2; },
    timeRateRadian() {
      const limitRate = 0.99;//円弧がほぼ360°になると描画がおかしくなる現象の対策
      return 2 * Math.PI * (this.timeRate<=limitRate? this.timeRate: limitRate);
    },
    timeRateEndPosX() { return this.cx + Math.sin(this.timeRateRadian) * this.r2; },
    timeRateEndPosY() { return this.cy - Math.cos(this.timeRateRadian) * this.r2; },
  },//computed
};
</script>

<style lang="sass" scoped>
@import "../sass/_base"

svg
  $orgWidth: 180 //120
  $orgHeight: 184
  $minWidth: 75px //60px
  min-width: $minWidth
  min-height: $minWidth / $orgWidth * $orgHeight
  $maxWidth: 120px //90px
  max-width: $maxWidth
  max-height: $maxWidth / $orgWidth * $orgHeight
  $width: 15vw * 4/3
  width: $width
  height: $width / $orgWidth * $orgHeight
  +mq(min_451)
    $width: 15vw * 4/3
    width: $width
    height: $width / $orgWidth * $orgHeight
  +mq(min_576)
    $width: 12vw * 4/3
    width: $width
    height: $width / $orgWidth * $orgHeight
  +mq(min_768)
    $width: 10vw * 4/3
    width: $width
    height: $width / $orgWidth * $orgHeight
  +mq(min_992)
    $width: 7vw * 4/3
    width: $width
    height: $width / $orgWidth * $orgHeight
</style>

<template>

  <div class="">
    <div>
      <svg class="clock_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 50">
        <path d="M23.333,0A23.319,23.319,0,0,0,0,23.167a23.334,23.334,0,0,0,46.666,0A23.319,23.319,0,0,0,23.333,0Zm0,5.792a17.375,17.375,0,1,1-17.5,17.375A17.414,17.414,0,0,1,23.333,5.792Zm-2.917,5.792V24.441l.933.753,2.917,2.9,1.983,2.2,4.2-4.17-2.217-1.969L26.25,22.182V11.7H20.416Z" fill="#979797"/>
      </svg>
      <svg class="timer_bar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 50" preserveAspectRatio="none"><!-- バーの縦横比に変化があるのでpreserveAspectRatio=none指定は必須 -->
        <rect v-if="/*isDebug||*/timeRate<=0.0" x="0" y="0" width="500" height="50" fill="lightgray"/>
        <template v-if="false">
          <circle cx="0" cy="0" r="10" fill="red" />
          <circle cx="500" cy="0" r="10" fill="red" />
          <circle cx="500" cy="50" r="10" fill="red" />
          <circle cx="0" cy="50" r="10" fill="red" />
        </template>
        <rect x="0" y="0" :width="500 * timeRate" height="50" :fill="barColor"/>
      </svg>
    </div>
    <div v-if="isDebug" class="debug_str">残り[{{ internalRemainingSec }}]秒 timeRate=[{{ `${timeRate}`.substr(0,5) }}]</div>
  </div>

</template>

<script>
const LogHeader = 'TimerGaugeBar';
export default {
  // model: {
  //   prop: 'value',
  //   event: 'input',
  // },
  props: {
    value: { default: null },//internalRemainingSec
    isDebug: { default: false },
  },
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      timerId: null,
      timeLimitSec: null,
      cutOffTime: null,
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    internalRemainingSec: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue != this.value) {
          //console.log(`[${LogHeader}] emitting(input) newValue=[${newValue}]`);//デバッグ用（本番コメントアウト）
          this.$emit('input', newValue);
        }
      }
    },
    timeRate() {
      if (this.internalRemainingSec == null || this.internalRemainingSec <= 0 ||
          this.timeLimitSec == null || this.timeLimitSec <= 0) {
        return 0.0;
      }
      return this.internalRemainingSec / this.timeLimitSec;
    },
    barColor() {
      if (this.timeRate < 0.20) {
        return '#B20000';
      } else if (this.timeRate < 0.40) {
        return '#E9E900';
      }
      return '#009505';
    },
  },//computed
  methods: {
    Start(timeLimitSec, remainingSec) {//親側から呼び出す
      //console.log(`[${LogHeader}] Start(timeLimitSec=[${timeLimitSec}],remainingSec=[${remainingSec}]) START`)
      this.Stop();//念の為
      this.timeLimitSec = timeLimitSec;
      const initialRemainingSec = remainingSec!==undefined? remainingSec: timeLimitSec;
      this.internalRemainingSec = initialRemainingSec;
      this.cutOffTime = (new Date()).getTime() + initialRemainingSec * 1000;
      this.timerId = setInterval(() => {
        this.internalRemainingSec = Math.ceil((this.cutOffTime - (new Date()).getTime()) / 1000);
        //console.log(`[${LogHeader}] internalRemainingSec=[${this.internalRemainingSec}]`);
        if (this.internalRemainingSec <= 0) {
          console.log(`[${LogHeader}] internalRemainingSec is UP !!`);
          //clearInterval忘れ防止の為にタイマーを止める
          this.Stop();
        }
      }, 1000);
      console.log(`[${LogHeader}] Start(timeLimitSec=[${timeLimitSec}],remainingSec=[${remainingSec}]) started timer (id=[${this.timerId}], initialRemainingSec=[${initialRemainingSec}])`);
      //console.log(`[${LogHeader}] Start(${timeLimitSec},${remainingSec}) END`)
    },
    Stop() {//親側から呼び出す
      //console.log(`[${LogHeader}] Stop() START`)
      if (this.timerId) {
        clearInterval(this.timerId);
        console.log(`[${LogHeader}] Stop() stopped timer (id=[${this.timerId}])`);
        this.timerId = null;
        //再Start()や停止後の参照にも対応できるよう、 cutOffTime, internalRemainingSec は保持
      }
      //console.log(`[${LogHeader}] Stop() END`)
    },
  },//methods
  // watch: {
  //   internalRemainingSec: {
  //     handler(newValue, oldValue) {
  //       //console.log(`[${LogHeader}] watch:remainingSec([${oldValue}]→[${newValue}]) CALLED`);
  //       if (newValue <= 0) {
  //       }
  //     }
  //   },
  // },
};
</script>

<style lang="sass" scoped>
@import "../sass/_base"

svg
  display: inline-block
  &.clock_icon
    $orgWidth: 70
    $orgHeight: 50
    $minWidth: 30px
    min-width: $minWidth
    min-height: $minWidth / $orgWidth * $orgHeight
    $maxWidth: 4.5em
    max-width: $maxWidth
    max-height: $maxWidth / $orgWidth * $orgHeight
    $width: 10vw
    width: $width
    height: $width / $orgWidth * $orgHeight
  &.timer_bar
    $orgWidth: 500
    $orgHeight: 50
    width: 75vw
    height: 1.5em
    max-width: 80%
    +mq(min_451)
      height: 2em
      max-width: 82%
    +mq(min_576)
      height: 2em
      max-width: 84%
    +mq(min_768)
      height: 2em
      max-width: 86%
    +mq(min_992)
      height: 2em
      max-width: 87%
    +mq(min_1200)
      height: 2em
      max-width: 83%
</style>

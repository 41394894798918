<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">CR-1 本テスト 検査１ p11</div>

    <!-- ↓ p11, p12 は全く同じ --><!-- 練習１ 検査１ p11へ流用 --><!-- 練習２ 検査１ p11へ流用 -->

    <MyTextBox class="" :canEdit="canEdit" v-model="params.s01" />

    <div class="row">
      <div class="col-12 px-3">

        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no">{{ params[questionIds[0]].q_no }}</div>
              <div class="text_container_80 bg-lightyellow">
                <MyTextBox class="_question_str_90" :canEdit="canEdit" v-model="params[questionIds[0]].question" />
              </div>
            </div>
          </div>
        </div>

        <div class="text_container mt-2 ml-md-5 pl-1 pl-md-3">
          <MyTextBox class="" :canEdit="canEdit" v-model="params[questionIds[0]].s01" />
        </div>

        <div class="choice_table_wrapper ml-md-5">
          <div class="choice_table">
            <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
              <input type="checkbox" :id="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                      v-model="params[questionIds[0]].answer"
                      :ref="`checkbox_q${questionIds[0]}`"
                      @click="onClickCheckbox(params[questionIds[0]], $refs[`checkbox_q${questionIds[0]}`], $event)"
                      @change="sortCheckboxAnswers(params[questionIds[0]])"
              />
              <label :for="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox_placeholder has_value">
                <div class="choice_value">{{ choiceValue }}：</div>
              </label>
              <label :for="`${questionIds[0]}_${choiceValue}`" class="">
                <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[0]].choices[choiceIdx]" />
              </label>
            </div>
          </div>
        </div>

      </div>
    </div><!-- row -->

    <!-- ↑ p11, p12 は全く同じ -->

    <div v-if="showDescription" class="my-3">

      <hr />

      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper bordered">
        <DescriptionTable :class="questionIds[0]">
          <template v-slot:q_no>{{ params[questionIds[0]].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionIds[0]]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionIds[0]].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'CR-1/本テスト/検査１/p11';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q013' ],
      choiceValues: [
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

<template>
  <div class="report_wrapper my-4">

    <div v-if="!scExStudentExamPartSet.is_practice" class="report_body">

<!-- 「CR－1　 試験結果画面2.xd」より -->
<!--
【調整のコツ】
・svgタグのwidth,heightを元にSASSを記述（縦横比が重要） ※svgのwidth,heightは無効化する
・中央寄せはtextタグやtspanタグ内の「text-anchor="middle"」 → 座標調整より先にやる！！
・tspanタグのx属性
・transform属性のtranslate関数の第１引数
-->
<svg xmlns="http://www.w3.org/2000/svg" _width="539" _height="670.164" viewBox="0 0 539 670.164">
  <g id="グループ_1727" data-name="グループ 1727" transform="translate(-18.857 -122.17)">
    <g id="グループ_1707" data-name="グループ 1707" transform="translate(-13.086 23.118)">
      <path id="パス_50" data-name="パス 50" d="M0,0H487.717" transform="translate(61.585 206.422)" fill="none" stroke="#000" stroke-width="0.5"/>
      <g id="グループ_1697" data-name="グループ 1697" transform="translate(25 4.053)">
        <rect id="長方形_93" data-name="長方形 93" width="103" height="76" rx="12" transform="translate(182.992 119.482)" fill="#f6f6f6"/>
        <path id="パス_203" data-name="パス 203" d="M11.517,0h104.61a11.766,11.766,0,0,1,11.517,12V64a11.766,11.766,0,0,1-11.517,12H11.517A11.766,11.766,0,0,1,0,64V12A11.766,11.766,0,0,1,11.517,0Z" transform="translate(43.869 118.482)" fill="#daf9f3"/>
        <rect id="長方形_94" data-name="長方形 94" width="104" height="76" rx="12" transform="translate(297.553 119.482)" fill="#f6f6f6"/>
        <rect id="長方形_95" data-name="長方形 95" width="104" height="75" rx="12" transform="translate(411.514 118.482)" fill="#f6f6f6"/>
        <line id="線_8" data-name="線 8" x2="487.283" y2="0.018" transform="translate(36.586 142.965)" fill="none" stroke="#000" stroke-width="0.5"/>
        <line id="線_9" data-name="線 9" y1="0.613" x2="487.717" transform="translate(36.586 168.352)" fill="none" stroke="#000" stroke-width="0.5"/>
        <text id="得点" transform="translate(96 134.721)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="0" y="0">得点</tspan></text>
        <text id="全国順位" transform="translate(86 186.352)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="0" y="0">全国順位</tspan></text>
        <text id="偏差値_全国_" data-name="偏差値（全国）" transform="translate(80.5 160.982)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="0" y="0">偏差値</tspan><tspan y="0" font-size="8">（全国）</tspan></text>
        <g id="総合" transform="translate(30.039 -38.518)">
          <text id="_30点" data-name="30点" transform="translate(202 175)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][0]['得点'] }}点</tspan></text>
          <text id="_53" data-name="53" transform="translate(201.553 200.5)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][0]['全国偏差値'] }}</tspan></text>
          <text id="_4578位_10000人中" data-name="4578位 / 10000人中" transform="translate(206.553 226)" font-size="9" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][0]['全国順位'] }}位 / 10000人中</tspan></text>
        </g>
        <g id="検査_" data-name="検査１" transform="translate(145.999 -39.518)">
          <text id="_21点" data-name="21点" transform="translate(202 175)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][1]['得点'] }}点</tspan></text>
          <text id="_59" data-name="59" transform="translate(201.553 200.5)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][1]['全国偏差値'] }}</tspan></text>
          <text id="_1821位_10000人中" data-name="1821位 / 10000人中" transform="translate(206.553 226)" font-size="9" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][1]['全国順位'] }}位 / 10000人中</tspan></text>
        </g>
        <g id="検査_2" data-name="検査１" transform="translate(259.961 -39.018)">
          <text id="_9点" data-name="9点" transform="translate(202 175)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][2]['得点'] }}点</tspan></text>
          <text id="_48" data-name="48" transform="translate(201.553 200.5)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][2]['全国偏差値'] }}</tspan></text>
          <text id="_6541位_10000人中" data-name="6541位 / 10000人中" transform="translate(206.553 226)" font-size="9" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][2]['全国順位'] }}位 / 10000人中</tspan></text>
        </g>
        <path id="パス_51" data-name="パス 51" d="M4.005,0h94.99A4.134,4.134,0,0,1,103,4.252V16.074a4.134,4.134,0,0,1-4.005,4.252H4.005A4.134,4.134,0,0,1,0,16.074V4.252A4.134,4.134,0,0,1,4.005,0Z" transform="translate(182.992 95)" fill="#28759d"/>
        <text id="総合_60点満点_" data-name="総合（60点満点）" transform="translate(238.591 109.163)" fill="#fff" stroke="#fff" stroke-width="0.1" font-size="9" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="0" y="0">総合（60点満点）</tspan></text>
        <path id="パス_52" data-name="パス 52" d="M4.044,0H99.956A4.152,4.152,0,0,1,104,4.252V16.074a4.152,4.152,0,0,1-4.044,4.252H4.044A4.152,4.152,0,0,1,0,16.074V4.252A4.152,4.152,0,0,1,4.044,0Z" transform="translate(297.553 95)" fill="#28759d"/>
        <text id="検査_30点満点_" data-name="検査Ⅰ（30点満点）" transform="translate(352.553 109.163)" fill="#fff" stroke="#fff" stroke-width="0.1" font-size="9" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="0" y="0">検査Ⅰ（30点満点）</tspan></text>
        <path id="パス_53" data-name="パス 53" d="M4.044,0H99.956A4.152,4.152,0,0,1,104,4.252V16.074a4.152,4.152,0,0,1-4.044,4.252H4.044A4.152,4.152,0,0,1,0,16.074V4.252A4.152,4.152,0,0,1,4.044,0Z" transform="translate(411.514 95)" fill="#28759d"/>
        <text id="検査_30点満点_2" data-name="検査Ⅱ（30点満点）" transform="translate(466.514 109.163)" fill="#fff" stroke="#fff" stroke-width="0.1" font-size="9" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="0" y="0">検査Ⅱ（30点満点）</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1726" data-name="グループ 1726" transform="translate(9993.212 6061.614)">
    <g id="グループ_1730" data-name="グループ 1730" transform="translate(14 -8)">
      <g id="分野別結果表" transform="translate(-9761.272 -5916.36)">
        <rect id="長方形_107" data-name="長方形 107" width="276.061" height="21" transform="translate(0 114.721)" fill="#daf9f3"/>
        <path id="パス_60" data-name="パス 60" d="M0,0H276.061V21H42.25v92.548H0V0Z" transform="translate(0 0.107)" fill="#daf9f3"/>
        <text id="_8_12_7_3_30" data-name="8
12
7
3
30" transform="translate(171.984 38.5)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em"><tspan x="0" y="0" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][0]['得点'] }}</tspan><tspan x="0" y="22.6" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][1]['得点'] }}</tspan><tspan x="0" y="45.2" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][0]['得点'] }}</tspan><tspan x="0" y="67.8" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][1]['得点'] }}</tspan><tspan x="0" y="90.4" text-anchor="middle">{{ section2DataSet['rows'][2]['rows'][0]['得点'] }}</tspan></text>
        <text id="_64_55_52_43_54" data-name="64
55
52
43
54" transform="translate(242.108 38.5)" font-size="11" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.025em"><tspan x="0" y="0" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][0]['全国偏差値'] }}</tspan><tspan x="0" y="22.6" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][1]['全国偏差値'] }}</tspan><tspan x="0" y="45.2" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][0]['全国偏差値'] }}</tspan><tspan x="0" y="67.8" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][1]['全国偏差値'] }}</tspan><tspan x="0" y="90.4" text-anchor="middle">{{ section2DataSet['rows'][2]['rows'][0]['全国偏差値'] }}</tspan></text>
        <text id="分野" transform="translate(88.527 13.5)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="-9" y="0">分野</tspan></text>
        <text id="得点-2" data-name="得点" transform="translate(171.984 13.5)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="-9" y="0">得点</tspan></text>
        <text id="偏差値" transform="translate(241.608 13.5)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="-13.5" y="0">偏差値</tspan></text>
        <text id="総_合_計" data-name="総   合   計" transform="translate(65.354 129.096)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="-20.949" y="0">総</tspan><tspan y="0" xml:space="preserve" font-family="SegoeUI-Bold, Segoe UI">   </tspan><tspan y="0">合</tspan><tspan y="0" xml:space="preserve" font-family="SegoeUI-Bold, Segoe UI">   </tspan><tspan y="0">計</tspan></text>
        <line id="線_77" data-name="線 77" x2="276.061" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-2" data-name="線 77" x2="276.061" transform="translate(0 21)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-3" data-name="線 77" x2="233.288" y2="0.055" transform="translate(42.772 45.141)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-4" data-name="線 77" x2="276.061" transform="translate(0 67.793)" fill="none" stroke="#28759d" stroke-width="2"/>
        <path id="パス_211" data-name="パス 211" d="M-20.11,0H213" transform="translate(63.061 90.391)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-5" data-name="線 77" x2="276.061" transform="translate(0 114.596)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-6" data-name="線 77" x2="276.061" transform="translate(0 135.596)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_78" data-name="線 78" y2="135.596" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-2" data-name="線 78" x1="0.228" y2="114.141" transform="translate(42.772)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-3" data-name="線 78" y2="135.596" transform="translate(138.03)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-4" data-name="線 78" y2="135.596" transform="translate(207.045)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-5" data-name="線 78" y2="135.596" transform="translate(276.061)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <text id="検査_3" data-name="検査Ⅰ" transform="translate(22.5 48.511)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="-13.5" y="0">検査Ⅰ</tspan></text>
        <text id="検査_4" data-name="検査Ⅱ" transform="translate(22.5 97.074)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700"><tspan x="-13.5" y="0">検査Ⅱ</tspan></text>
      </g>
      <g id="グループ_1725" data-name="グループ 1725" transform="translate(27 -22)">
        <text id="文章読解" transform="translate(-9727 -5867)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="25" y="10">文章読解</tspan></text>
        <text id="語い" transform="translate(-9713 -5845)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="12" y="10">語い</tspan></text>
        <text id="数的能力" transform="translate(-9726 -5823)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="25" y="10">数的能力</tspan></text>
        <text id="論理" transform="translate(-9713 -5799)" font-size="9" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="12" y="10">論理</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1814" data-name="グループ 1814" transform="translate(-26.5 -126.582)">
    <g id="グループ_1788" data-name="グループ 1788" transform="translate(8 4.894)">
      <g id="グループ_1787" data-name="グループ 1787">
        <rect id="長方形_423" data-name="長方形 423" width="210" height="15" transform="translate(83 436)" fill="#f3f3f3"/>
        <g id="グループ_1763" data-name="グループ 1763" transform="translate(9917 6010)">
          <g id="グループ_1740" data-name="グループ 1740" transform="translate(2 15)">
            <text id="_2" data-name="2" transform="translate(-9808 -5454)" font-size="7" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="7">2</tspan></text>
            <g id="グループ_1739" data-name="グループ 1739" transform="translate(167 189)">
              <path id="パス_222" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
              <path id="パス_222-2" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
              <path id="パス_222-3" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
              <path id="パス_222-4" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
              <path id="パス_222-5" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
              <path id="パス_222-6" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
              <path id="パス_222-7" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
              <path id="パス_222-8" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
              <path id="パス_222-9" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
              <path id="パス_222-10" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5655)" fill="#f3f3f3"/>
              <path id="パス_222-11" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5643)" fill="#fff"/>
              <path id="パス_222-12" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5631)" fill="#f3f3f3"/>
              <path id="パス_222-13" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5619)" fill="#fff"/>
              <path id="パス_222-14" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5607)" fill="#f3f3f3"/>
              <path id="パス_223" data-name="パス 223" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
            </g>
          </g>
          <path id="パス_226" data-name="パス 226" d="M0,0H209" transform="translate(-9834 -5546.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_227" data-name="パス 227" d="M0,0H209" transform="translate(-9834 -5534.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_240" data-name="パス 240" d="M0,0H209" transform="translate(-9834 -5558.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_228" data-name="パス 228" d="M0,0H209" transform="translate(-9834 -5522.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_229" data-name="パス 229" d="M0,0H209" transform="translate(-9834 -5510.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_230" data-name="パス 230" d="M0,0H209" transform="translate(-9834 -5498.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_231" data-name="パス 231" d="M0,0H209" transform="translate(-9834 -5486.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_232" data-name="パス 232" d="M0,0H209" transform="translate(-9834 -5474.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_233" data-name="パス 233" d="M0,0H209" transform="translate(-9834 -5462.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_234" data-name="パス 234" d="M0,0H209" transform="translate(-9834 -5450.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_235" data-name="パス 235" d="M0,0H209" transform="translate(-9834 -5438.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_236" data-name="パス 236" d="M0,0H209" transform="translate(-9834 -5426.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_237" data-name="パス 237" d="M0,0H209" transform="translate(-9834 -5414.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_238" data-name="パス 238" d="M0,0H209" transform="translate(-9834 -5402.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_239" data-name="パス 239" d="M0,0H209" transform="translate(-9834 -5390.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <g id="グループ_1742" data-name="グループ 1742" transform="translate(-2 16)">
            <rect id="長方形_80" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_2-2" data-name="2" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">2</tspan></text>
          </g>
          <g id="グループ_1764" data-name="グループ 1764" transform="translate(-2 4.107)">
            <rect id="長方形_80-2" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_1" data-name="1" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">1</tspan></text>
          </g>
          <g id="グループ_1743" data-name="グループ 1743" transform="translate(-2 28)">
            <rect id="長方形_80-3" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_3" data-name="3" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">3</tspan></text>
          </g>
          <g id="グループ_1744" data-name="グループ 1744" transform="translate(-2 40)">
            <rect id="長方形_80-4" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_4" data-name="4" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">4</tspan></text>
          </g>
          <g id="グループ_1745" data-name="グループ 1745" transform="translate(-2 52)">
            <rect id="長方形_80-5" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_5" data-name="5" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">5</tspan></text>
          </g>
          <g id="グループ_1746" data-name="グループ 1746" transform="translate(-2 64)">
            <rect id="長方形_80-6" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_6" data-name="6" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">6</tspan></text>
          </g>
          <g id="グループ_1747" data-name="グループ 1747" transform="translate(-2 76)">
            <rect id="長方形_80-7" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_7" data-name="7" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">7</tspan></text>
          </g>
          <g id="グループ_1748" data-name="グループ 1748" transform="translate(-2 88)">
            <rect id="長方形_80-8" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_8" data-name="8" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">8</tspan></text>
          </g>
          <g id="グループ_1749" data-name="グループ 1749" transform="translate(-2 100)">
            <rect id="長方形_80-9" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_9" data-name="9" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">9</tspan></text>
          </g>
          <g id="グループ_1756" data-name="グループ 1756" transform="translate(-2 124)">
            <rect id="長方形_80-10" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_11" data-name="11" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">11</tspan></text>
          </g>
          <g id="グループ_1761" data-name="グループ 1761" transform="translate(-2 112)">
            <rect id="長方形_80-11" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_10" data-name="10" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">10</tspan></text>
          </g>
          <g id="グループ_1757" data-name="グループ 1757" transform="translate(-2 136)">
            <rect id="長方形_80-12" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_12" data-name="12" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">12</tspan></text>
          </g>
          <g id="グループ_1758" data-name="グループ 1758" transform="translate(-2 148)">
            <rect id="長方形_80-13" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_13" data-name="13" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">13</tspan></text>
          </g>
          <g id="グループ_1759" data-name="グループ 1759" transform="translate(-2 160)">
            <rect id="長方形_80-14" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_14" data-name="14" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">14</tspan></text>
          </g>
          <g id="グループ_1760" data-name="グループ 1760" transform="translate(-2 172)">
            <rect id="長方形_80-15" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_15" data-name="15" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">15</tspan></text>
          </g>
        </g>
        <text id="熟語_熟語_熟語_熟語_熟語_熟語_熟語_熟語_熟語_文章読解_文章読解_文章読解_文章整序_文章整序_空欄補充_" data-name="熟語
熟語
熟語
熟語
熟語
熟語
熟語
熟語
熟語
文章読解
文章読解
文章読解
文章整序
文章整序
空欄補充
" transform="translate(109.144 448.107)" font-size="8.33" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="0">熟語</tspan><tspan x="0" y="12">熟語</tspan><tspan x="0" y="24">熟語</tspan><tspan x="0" y="36">熟語</tspan><tspan x="0" y="48">熟語</tspan><tspan x="0" y="60">熟語</tspan><tspan x="0" y="72">熟語</tspan><tspan x="0" y="84">熟語</tspan><tspan x="0" y="96">熟語</tspan><tspan x="0" y="108">文章読解</tspan><tspan x="0" y="120">文章読解</tspan><tspan x="0" y="132">文章読解</tspan><tspan x="0" y="144">文章整序</tspan><tspan x="0" y="156">文章整序</tspan><tspan x="0" y="168">空欄補充</tspan><tspan x="0" y="180"></tspan></text>
        <line id="線_557" data-name="線 557" x2="209" transform="translate(83.5 436.5)" fill="none" stroke="#000" stroke-width="1"/>
      </g>
      <text id="番号" transform="translate(86 426)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">番号</tspan></text>
      <text id="出題項目" transform="translate(111 425)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">出題項目</tspan></text>
      <text id="配点" transform="translate(204 425)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">配点</tspan></text>
      <text id="正答率" transform="translate(252 425)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正答率</tspan></text>
      <text id="正誤" transform="translate(228 425)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正誤</tspan></text>
      <g id="グループ_1786" data-name="グループ 1786" transform="translate(-1)">
        <text id="_10-2" data-name="10" transform="translate(257 608)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][14]['正答率'] }}</tspan></text>
        <text id="_22"   data-name="22" transform="translate(257 596)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][13]['正答率'] }}</tspan></text>
        <text id="_77"   data-name="77" transform="translate(257 584)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][12]['正答率'] }}</tspan></text>
        <text id="_36"   data-name="36" transform="translate(257 572)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][11]['正答率'] }}</tspan></text>
        <text id="_44"   data-name="44" transform="translate(257 560)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][10]['正答率'] }}</tspan></text>
        <text id="_54"   data-name="54" transform="translate(257 548)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][9]['正答率'] }}</tspan></text>
        <text id="_72"   data-name="72" transform="translate(257 536)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][8]['正答率'] }}</tspan></text>
        <text id="_33"   data-name="33" transform="translate(257 524)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][7]['正答率'] }}</tspan></text>
        <text id="_13-2" data-name="13" transform="translate(257 512)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][6]['正答率'] }}</tspan></text>
        <text id="_92"   data-name="92" transform="translate(257 500)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][5]['正答率'] }}</tspan></text>
        <text id="_45"   data-name="45" transform="translate(257 488)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][4]['正答率'] }}</tspan></text>
        <text id="_83"   data-name="83" transform="translate(257 476)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][3]['正答率'] }}</tspan></text>
        <text id="_22-2" data-name="22" transform="translate(257 464)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][2]['正答率'] }}</tspan></text>
        <text id="_41"   data-name="41" transform="translate(257 452)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][1]['正答率'] }}</tspan></text>
        <text id="_56"   data-name="56" transform="translate(257 440)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][0]['正答率'] }}</tspan></text>
      </g>
      <g id="グループ_1785" data-name="グループ 1785" transform="translate(1)">
        <text id="〇"                   transform="translate(231 608)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][14]['正誤マーク'] }}</tspan></text>
        <text id="〇-2"  data-name="〇" transform="translate(231 596)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][13]['正誤マーク'] }}</tspan></text>
        <text id="_"     data-name="×" transform="translate(231 584)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][12]['正誤マーク'] }}</tspan></text>
        <text id="〇-3"  data-name="〇" transform="translate(231 572)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][11]['正誤マーク'] }}</tspan></text>
        <text id="〇-4"  data-name="〇" transform="translate(231 560)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][10]['正誤マーク'] }}</tspan></text>
        <text id="_2-3"  data-name="×" transform="translate(231 548)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][9]['正誤マーク'] }}</tspan></text>
        <text id="_3-2"  data-name="×" transform="translate(231 536)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][8]['正誤マーク'] }}</tspan></text>
        <text id="_4-2"  data-name="×" transform="translate(231 524)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][7]['正誤マーク'] }}</tspan></text>
        <text id="〇-5"  data-name="〇" transform="translate(231 512)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][6]['正誤マーク'] }}</tspan></text>
        <text id="〇-6"  data-name="〇" transform="translate(231 500)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][5]['正誤マーク'] }}</tspan></text>
        <text id="〇-7"  data-name="〇" transform="translate(231 488)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][4]['正誤マーク'] }}</tspan></text>
        <text id="〇-8"  data-name="〇" transform="translate(231 476)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][3]['正誤マーク'] }}</tspan></text>
        <text id="〇-9"  data-name="〇" transform="translate(231 464)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][2]['正誤マーク'] }}</tspan></text>
        <text id="_5-2"  data-name="×" transform="translate(231 452)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][1]['正誤マーク'] }}</tspan></text>
        <text id="〇-10" data-name="〇" transform="translate(231 440)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][0]['正誤マーク'] }}</tspan></text>
      </g>
      <g id="グループ_1784" data-name="グループ 1784">
        <text id="_1-2" data-name="1" transform="translate(210 608)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-3" data-name="1" transform="translate(210 596)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-4" data-name="1" transform="translate(210 584)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-5" data-name="1" transform="translate(210 572)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-6" data-name="1" transform="translate(210 560)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-7" data-name="1" transform="translate(210 548)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-8" data-name="1" transform="translate(210 536)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-9" data-name="1" transform="translate(210 524)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-10" data-name="1" transform="translate(210 512)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-11" data-name="1" transform="translate(210 500)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-12" data-name="1" transform="translate(210 488)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-13" data-name="1" transform="translate(210 476)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-14" data-name="1" transform="translate(210 464)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-15" data-name="1" transform="translate(210 452)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-16" data-name="1" transform="translate(210 440)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      </g>
      <g id="グループ_1783" data-name="グループ 1783" transform="translate(1)">
        <g id="グループ_1765" data-name="グループ 1765" transform="translate(9785 6014)">
          <rect id="長方形_422" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1766" data-name="グループ 1766" transform="translate(9785 6002)">
          <rect id="長方形_422-2" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
          <text id="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        </g>
        <g id="グループ_1770" data-name="グループ 1770" transform="translate(9785 5990)">
          <rect id="長方形_422-3" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
          <text id="文-2" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        </g>
        <g id="グループ_1771" data-name="グループ 1771" transform="translate(9785 5978)">
          <rect id="長方形_422-4" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
          <text id="文-3" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        </g>
        <g id="グループ_1772" data-name="グループ 1772" transform="translate(9785 5966)">
          <rect id="長方形_422-5" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
          <text id="文-4" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        </g>
        <g id="グループ_1773" data-name="グループ 1773" transform="translate(9785 5954)">
          <rect id="長方形_422-6" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
          <text id="文-5" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        </g>
        <g id="グループ_1774" data-name="グループ 1774" transform="translate(9785 5942)">
          <rect id="長方形_422-7" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-2" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1775" data-name="グループ 1775" transform="translate(9785 5930)">
          <rect id="長方形_422-8" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-3" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1776" data-name="グループ 1776" transform="translate(9785 5918)">
          <rect id="長方形_422-9" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-4" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1777" data-name="グループ 1777" transform="translate(9785 5906)">
          <rect id="長方形_422-10" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-5" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1778" data-name="グループ 1778" transform="translate(9785 5894)">
          <rect id="長方形_422-11" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-6" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1779" data-name="グループ 1779" transform="translate(9785 5882)">
          <rect id="長方形_422-12" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-7" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1780" data-name="グループ 1780" transform="translate(9785 5870)">
          <rect id="長方形_422-13" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-8" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1781" data-name="グループ 1781" transform="translate(9785 5858)">
          <rect id="長方形_422-14" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-9" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
        <g id="グループ_1782" data-name="グループ 1782" transform="translate(9785 5846)">
          <rect id="長方形_422-15" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-10" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        </g>
      </g>
    </g>
    <g id="グループ_1801" data-name="グループ 1801" transform="translate(-4 7.894)">
      <g id="グループ_1791" data-name="グループ 1791" transform="translate(9423 6049.553)">
        <rect id="長方形_423-2" data-name="長方形 423" width="210" height="15" transform="translate(-9095 -5616.553)" fill="#f3f3f3"/>
        <g id="グループ_1740-2" data-name="グループ 1740" transform="translate(741 -27.553)">
          <text id="_2-4" data-name="2" transform="translate(-9808 -5454)" font-size="7" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="7">2</tspan></text>
          <g id="グループ_1739-2" data-name="グループ 1739" transform="translate(167 189)">
            <path id="パス_222-15" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
            <path id="パス_222-16" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
            <path id="パス_222-17" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
            <path id="パス_222-18" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
            <path id="パス_222-19" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
            <path id="パス_222-20" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
            <path id="パス_222-21" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
            <path id="パス_222-22" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
            <path id="パス_222-23" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
            <path id="パス_222-24" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5655)" fill="#f3f3f3"/>
            <path id="パス_222-25" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5643)" fill="#fff"/>
            <path id="パス_222-26" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5631)" fill="#f3f3f3"/>
            <path id="パス_222-27" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5619)" fill="#fff"/>
            <path id="パス_222-28" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5607)" fill="#f3f3f3"/>
            <path id="パス_223-2" data-name="パス 223" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
          </g>
        </g>
        <path id="パス_226-2" data-name="パス 226" d="M0,0H209" transform="translate(-9095 -5589.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_227-2" data-name="パス 227" d="M0,0H209" transform="translate(-9095 -5577.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_240-2" data-name="パス 240" d="M0,0H209" transform="translate(-9095 -5601.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_228-2" data-name="パス 228" d="M0,0H209" transform="translate(-9095 -5565.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_229-2" data-name="パス 229" d="M0,0H209" transform="translate(-9095 -5553.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_230-2" data-name="パス 230" d="M0,0H209" transform="translate(-9095 -5541.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_231-2" data-name="パス 231" d="M0,0H209" transform="translate(-9095 -5529.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_232-2" data-name="パス 232" d="M0,0H209" transform="translate(-9095 -5517.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_233-2" data-name="パス 233" d="M0,0H209" transform="translate(-9095 -5505.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_234-2" data-name="パス 234" d="M0,0H209" transform="translate(-9095 -5493.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_235-2" data-name="パス 235" d="M0,0H209" transform="translate(-9095 -5481.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_236-2" data-name="パス 236" d="M0,0H209" transform="translate(-9095 -5469.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_237-2" data-name="パス 237" d="M0,0H209" transform="translate(-9095 -5457.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_238-2" data-name="パス 238" d="M0,0H209" transform="translate(-9095 -5445.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_239-2" data-name="パス 239" d="M0,0H209" transform="translate(-9095 -5433.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <g id="グループ_1756-2" data-name="グループ 1756" transform="translate(737 81.447)">
          <rect id="長方形_80-16" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_26" data-name="26" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">26</tspan></text>
        </g>
        <g id="グループ_1761-2" data-name="グループ 1761" transform="translate(737 69.447)">
          <rect id="長方形_80-17" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_25" data-name="25" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">25</tspan></text>
        </g>
        <g id="グループ_1792" data-name="グループ 1792" transform="translate(737 57.447)">
          <rect id="長方形_80-18" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_24" data-name="24" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">24</tspan></text>
        </g>
        <g id="グループ_1793" data-name="グループ 1793" transform="translate(737 45.447)">
          <rect id="長方形_80-19" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_23" data-name="23" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">23</tspan></text>
        </g>
        <g id="グループ_1794" data-name="グループ 1794" transform="translate(737 33.447)">
          <rect id="長方形_80-20" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_22-3" data-name="22" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">22</tspan></text>
        </g>
        <g id="グループ_1795" data-name="グループ 1795" transform="translate(737 22.054)">
          <rect id="長方形_80-21" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_21" data-name="21" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">21</tspan></text>
        </g>
        <g id="グループ_1796" data-name="グループ 1796" transform="translate(737 9.447)">
          <rect id="長方形_80-22" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_20" data-name="20" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">20</tspan></text>
        </g>
        <g id="グループ_1797" data-name="グループ 1797" transform="translate(737 -2.553)">
          <rect id="長方形_80-23" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_19" data-name="19" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">19</tspan></text>
        </g>
        <g id="グループ_1798" data-name="グループ 1798" transform="translate(737 -14.553)">
          <rect id="長方形_80-24" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_18" data-name="18" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">18</tspan></text>
        </g>
        <g id="グループ_1799" data-name="グループ 1799" transform="translate(737 -26.053)">
          <rect id="長方形_80-25" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_17" data-name="17" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">17</tspan></text>
        </g>
        <g id="グループ_1800" data-name="グループ 1800" transform="translate(737 -38.553)">
          <rect id="長方形_80-26" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_16" data-name="16" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">16</tspan></text>
        </g>
        <g id="グループ_1757-2" data-name="グループ 1757" transform="translate(737 93.447)">
          <rect id="長方形_80-27" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_27" data-name="27" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">27</tspan></text>
        </g>
        <g id="グループ_1758-2" data-name="グループ 1758" transform="translate(737 105.447)">
          <rect id="長方形_80-28" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_28" data-name="28" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">28</tspan></text>
        </g>
        <g id="グループ_1759-2" data-name="グループ 1759" transform="translate(737 117.447)">
          <rect id="長方形_80-29" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_29" data-name="29" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">29</tspan></text>
        </g>
        <g id="グループ_1760-2" data-name="グループ 1760" transform="translate(737 129.447)">
          <rect id="長方形_80-30" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_30" data-name="30" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">30</tspan></text>
        </g>
        <line id="線_557-2" data-name="線 557" x2="209" transform="translate(-9094.5 -5616.053)" fill="none" stroke="#000" stroke-width="1"/>
        <text id="番号-2" data-name="番号" transform="translate(-9092 -5626.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">番号</tspan></text>
        <text id="出題項目-2" data-name="出題項目" transform="translate(-9067 -5627.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">出題項目</tspan></text>
        <text id="配点-2" data-name="配点" transform="translate(-8974 -5627.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">配点</tspan></text>
        <text id="正答率-2" data-name="正答率" transform="translate(-8926 -5627.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正答率</tspan></text>
        <text id="正誤-2" data-name="正誤" transform="translate(-8950 -5627.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正誤</tspan></text>

        <text id="_33-2" data-name="33" transform="translate(-8922 -5444.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][29]['正答率'] }}</tspan></text>
        <text id="_88"   data-name="88" transform="translate(-8922 -5456.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][28]['正答率'] }}</tspan></text>
        <text id="_33-3" data-name="33" transform="translate(-8922 -5468.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][27]['正答率'] }}</tspan></text>
        <text id="_21-2" data-name="21" transform="translate(-8922 -5480.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][26]['正答率'] }}</tspan></text>
        <text id="_40"   data-name="40" transform="translate(-8922 -5492.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][25]['正答率'] }}</tspan></text>
        <text id="_49"   data-name="49" transform="translate(-8922 -5504.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][24]['正答率'] }}</tspan></text>
        <text id="_50"   data-name="50" transform="translate(-8922 -5516.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][23]['正答率'] }}</tspan></text>
        <text id="_72-2" data-name="72" transform="translate(-8922 -5528.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][22]['正答率'] }}</tspan></text>
        <text id="_33-4" data-name="33" transform="translate(-8922 -5540.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][21]['正答率'] }}</tspan></text>
        <text id="_13-3" data-name="13" transform="translate(-8922 -5552.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][20]['正答率'] }}</tspan></text>
        <text id="_92-2" data-name="92" transform="translate(-8922 -5564.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][19]['正答率'] }}</tspan></text>
        <text id="_45-2" data-name="45" transform="translate(-8922 -5576.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][18]['正答率'] }}</tspan></text>
        <text id="_83-2" data-name="83" transform="translate(-8922 -5588.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][17]['正答率'] }}</tspan></text>
        <text id="_82"   data-name="82" transform="translate(-8922 -5600.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][16]['正答率'] }}</tspan></text>
        <text id="_34"   data-name="34" transform="translate(-8922 -5612.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section31DataSet['rows'][15]['正答率'] }}</tspan></text>

        <text id="N"                    transform="translate(-8946 -5444.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][29]['正誤マーク'] }}</tspan></text>
        <text id="N-2"   data-name="N"  transform="translate(-8946 -5456.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][28]['正誤マーク'] }}</tspan></text>
        <text id="N-3"   data-name="N"  transform="translate(-8946 -5468.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][27]['正誤マーク'] }}</tspan></text>
        <text id="〇-11" data-name="〇" transform="translate(-8946 -5480.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][26]['正誤マーク'] }}</tspan></text>
        <text id="N-4"   data-name="N"  transform="translate(-8946 -5492.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][25]['正誤マーク'] }}</tspan></text>
        <text id="〇-12" data-name="〇" transform="translate(-8946 -5504.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][24]['正誤マーク'] }}</tspan></text>
        <text id="〇-13" data-name="〇" transform="translate(-8946 -5516.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][23]['正誤マーク'] }}</tspan></text>
        <text id="_6-2"  data-name="×" transform="translate(-8946 -5528.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][22]['正誤マーク'] }}</tspan></text>
        <text id="〇-14" data-name="〇" transform="translate(-8946 -5540.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][21]['正誤マーク'] }}</tspan></text>
        <text id="〇-15" data-name="〇" transform="translate(-8946 -5552.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][20]['正誤マーク'] }}</tspan></text>
        <text id="_7-2"  data-name="×" transform="translate(-8946 -5564.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][19]['正誤マーク'] }}</tspan></text>
        <text id="〇-16" data-name="〇" transform="translate(-8946 -5576.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][18]['正誤マーク'] }}</tspan></text>
        <text id="〇-17" data-name="〇" transform="translate(-8946 -5588.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][17]['正誤マーク'] }}</tspan></text>
        <text id="〇-18" data-name="〇" transform="translate(-8946 -5600.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][16]['正誤マーク'] }}</tspan></text>
        <text id="_8-2"  data-name="×" transform="translate(-8946 -5612.553)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section31DataSet['rows'][15]['正誤マーク'] }}</tspan></text>

        <text id="_1-17" data-name="1" transform="translate(-8968 -5444.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-18" data-name="1" transform="translate(-8968 -5456.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-19" data-name="1" transform="translate(-8968 -5468.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-20" data-name="1" transform="translate(-8968 -5480.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-21" data-name="1" transform="translate(-8968 -5492.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-22" data-name="1" transform="translate(-8968 -5504.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-23" data-name="1" transform="translate(-8968 -5516.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-24" data-name="1" transform="translate(-8968 -5528.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-25" data-name="1" transform="translate(-8968 -5540.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-26" data-name="1" transform="translate(-8968 -5552.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-27" data-name="1" transform="translate(-8968 -5564.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-28" data-name="1" transform="translate(-8968 -5576.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-29" data-name="1" transform="translate(-8968 -5588.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-30" data-name="1" transform="translate(-8968 -5600.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-31" data-name="1" transform="translate(-8968 -5612.553)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <rect id="長方形_422-16" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5443.553)" fill="#209115"/>
        <text id="文-6" data-name="文" transform="translate(-9002 -5443.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        <rect id="長方形_422-17" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5455.553)" fill="#209115"/>
        <text id="文-7" data-name="文" transform="translate(-9002 -5455.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        <rect id="長方形_422-18" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5467.553)" fill="#209115"/>
        <text id="文-8" data-name="文" transform="translate(-9002 -5467.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        <rect id="長方形_422-19" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5479.553)" fill="#209115"/>
        <text id="文-9" data-name="文" transform="translate(-9002 -5479.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        <rect id="長方形_422-20" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5491.553)" fill="#209115"/>
        <text id="文-10" data-name="文" transform="translate(-9002 -5491.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
        <rect id="長方形_422-21" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5503.553)" fill="#1c4817"/>
        <text id="語-11" data-name="語" transform="translate(-9002 -5503.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-22" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5515.553)" fill="#1c4817"/>
        <text id="語-12" data-name="語" transform="translate(-9002 -5515.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-23" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5527.553)" fill="#1c4817"/>
        <text id="語-13" data-name="語" transform="translate(-9002 -5527.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-24" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5539.553)" fill="#1c4817"/>
        <text id="語-14" data-name="語" transform="translate(-9002 -5539.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-25" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5551.553)" fill="#1c4817"/>
        <text id="語-15" data-name="語" transform="translate(-9002 -5551.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-26" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5563.553)" fill="#1c4817"/>
        <text id="語-16" data-name="語" transform="translate(-9002 -5563.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-27" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5575.553)" fill="#1c4817"/>
        <text id="語-17" data-name="語" transform="translate(-9002 -5575.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-28" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5587.553)" fill="#1c4817"/>
        <text id="語-18" data-name="語" transform="translate(-9002 -5587.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-29" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5599.553)" fill="#1c4817"/>
        <text id="語-19" data-name="語" transform="translate(-9002 -5599.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <rect id="長方形_422-30" data-name="長方形 422" width="9" height="9" transform="translate(-9004 -5611.553)" fill="#1c4817"/>
        <text id="語-20" data-name="語" transform="translate(-9002 -5611.553)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
        <text id="空欄補充_文法_文法_二語の関係_二語の関係_二語の関係_二語の関係_二語の関係_二語の関係_二語の関係_文章読解_文章読解_文章読解_多義語_多義語_" data-name="空欄補充
文法
文法
二語の関係
二語の関係
二語の関係
二語の関係
二語の関係
二語の関係
二語の関係
文章読解
文章読解
文章読解
多義語
多義語
" transform="translate(-9068.71 -5603.447)" font-size="8.33" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="0">空欄補充</tspan><tspan x="0" y="12">文法</tspan><tspan x="0" y="24">文法</tspan><tspan x="0" y="36">二語の関係</tspan><tspan x="0" y="48">二語の関係</tspan><tspan x="0" y="60">二語の関係</tspan><tspan x="0" y="72">二語の関係</tspan><tspan x="0" y="84">二語の関係</tspan><tspan x="0" y="96">二語の関係</tspan><tspan x="0" y="108">二語の関係</tspan><tspan x="0" y="120">文章読解</tspan><tspan x="0" y="132">文章読解</tspan><tspan x="0" y="144">文章読解</tspan><tspan x="0" y="156">多義語</tspan><tspan x="0" y="168">多義語</tspan><tspan x="0" y="180"></tspan></text>
      </g>
    </g>
    <g id="グループ_1762" data-name="グループ 1762" transform="translate(9822.712 6364.238)">
      <g id="長方形_102" data-name="長方形 102" transform="translate(-9796.212 -5940.525)" fill="none" stroke="#28759d" stroke-width="2">
        <rect width="539" height="207" rx="14" stroke="none"/>
        <rect x="1" y="1" width="537" height="205" rx="13" fill="none"/>
      </g>
      <g id="グループ_1682" data-name="グループ 1682" transform="translate(865.97 -163.561)">
        <path id="パス_17" data-name="パス 17" d="M72.345,475.865c0,59.923,9.333,102.765,9.333,102.765H54.454c-3.384-.517-9.944-6.256-9.944-9.238V381.924c0-2.982,7.032-10.294,10.151-10.294l27.017,1.009S72.345,415.942,72.345,475.865Z" transform="translate(-10706.691 -6148.595)" fill="#28759d"/>
        <g id="グループ_533" data-name="グループ 533" transform="translate(-10655.123 -5707.819)">
          <g id="グループ_532" data-name="グループ 532" transform="translate(1 -2)">
            <path id="パス_18" data-name="パス 18" d="M62.745,442.055a4.166,4.166,0,0,0,2.746,1.858,3.069,3.069,0,0,0-.815,1.07,5,5,0,0,1-2.866-2.129,5.091,5.091,0,0,1-3.227,2.14,4.971,4.971,0,0,0-.755-.952c1.835-.518,2.639-1.22,2.974-1.987H58.607v-2.982H61.03v-.692h-1.5v-.55a7.817,7.817,0,0,1-.984.529,4.417,4.417,0,0,0-.348-.626v.518H57.108v.519c.324.378,1.163,1.534,1.343,1.8l-.72.994c-.131-.314-.371-.778-.623-1.232v4.591H55.789v-3.975a8.308,8.308,0,0,1-1.092,2.074,6.1,6.1,0,0,0-.647-1.145,10.109,10.109,0,0,0,1.655-3.63H54.326v-1.188h1.463v-2.215h1.319v2.215H58.2v.075a7.2,7.2,0,0,0,2.783-2.279h1.3a7.516,7.516,0,0,0,3.3,2.366,7.156,7.156,0,0,0-.731,1.123,9.8,9.8,0,0,1-.948-.5v.529H62.313v.692h2.555v2.982Zm-1.727-.983c.012-.119.012-.248.012-.367v-.649H59.843v1.016Zm2.1-3.749a7.631,7.631,0,0,1-1.451-1.362,7.2,7.2,0,0,1-1.392,1.362Zm-.8,2.733v.681c0,.108,0,.226-.011.335h1.283v-1.016Z" transform="translate(-54.05 -434.849)" fill="#fff"/>
            <path id="パス_19" data-name="パス 19" d="M61.726,465.96a9.9,9.9,0,0,0,3.669,1.87,5.011,5.011,0,0,0-.923,1.026,9.06,9.06,0,0,1-1.259-.529v4.094H65.18v1.123H54.338v-1.123h1.978V468.3a10.5,10.5,0,0,1-1.355.594,5.475,5.475,0,0,0-.887-.993,9.608,9.608,0,0,0,3.562-1.946H54.409v-1.112H59v-1.2h1.4v1.2H65.1v1.112Zm1.355,2.3a9.8,9.8,0,0,1-2.675-1.988v1.7H59v-1.739a9.78,9.78,0,0,1-2.6,2.031Zm-1.344,1.048H57.708v.432h4.029Zm-4.029,1.772h4.029v-.455H57.708Zm4.029,1.339v-.454H57.708v.454Z" transform="translate(-54.05 -434.849)" fill="#fff"/>
            <path id="パス_20" data-name="パス 20" d="M57.424,503.558v-1.341h1.489v-6.451H57.424v-1.353h4.635v1.353H60.583v6.451h1.476v1.341Z" transform="translate(-54.05 -434.849)" fill="#fff"/>
          </g>
        </g>
      </g>
    </g>
  </g>
  <g id="グループ_1826" data-name="グループ 1826" transform="translate(-26.5 -126)">
    <g id="グループ_1803" data-name="グループ 1803" transform="translate(9525.5 5755.819)">
      <g id="長方形_386" data-name="長方形 386" transform="translate(-9499 -5123)" fill="none" stroke="#28759d" stroke-width="2">
        <rect width="539" height="148.362" rx="14" stroke="none"/>
        <rect x="1" y="1" width="537" height="146.362" rx="13" fill="none"/>
      </g>
      <g id="グループ_1681" data-name="グループ 1681" transform="translate(-9542.409 -5696.982)">
        <path id="パス_31" data-name="パス 31" d="M73.254,632.669a432.2,432.2,0,0,0,9.654,88.3H54.566c-3.226,0-7.86-6.766-7.86-9.12V583.126c0-1.16-.033.343,1.112-2.488.964-2.391,5.113-6.825,6.748-6.825h4.148l24.194-1.206S73.254,585.36,73.254,632.669Z" transform="translate(-2.178 1.376)" fill="#28759d"/>
        <g id="グループ_538" data-name="グループ 538" transform="translate(52.331 613.809)">
          <g id="グループ_537" data-name="グループ 537" transform="translate(-1 2)">
            <path id="パス_32" data-name="パス 32" d="M62.745,616.167a4.161,4.161,0,0,0,2.746,1.857,3.07,3.07,0,0,0-.815,1.07,5,5,0,0,1-2.866-2.129,5.088,5.088,0,0,1-3.227,2.14,4.934,4.934,0,0,0-.755-.951c1.835-.519,2.639-1.221,2.974-1.987H58.607v-2.983H61.03v-.691h-1.5v-.551a7.7,7.7,0,0,1-.984.529,4.473,4.473,0,0,0-.348-.626v.519H57.108v.518c.324.378,1.163,1.535,1.343,1.8l-.72.994c-.131-.313-.371-.778-.623-1.231v4.591H55.789v-3.976a8.273,8.273,0,0,1-1.092,2.074,6.06,6.06,0,0,0-.647-1.144,10.125,10.125,0,0,0,1.655-3.63H54.326v-1.189h1.463V608.96h1.319v2.215H58.2v.075a7.2,7.2,0,0,0,2.783-2.279h1.3a7.507,7.507,0,0,0,3.3,2.366,7.205,7.205,0,0,0-.731,1.123,9.6,9.6,0,0,1-.948-.5v.53H62.313v.691h2.555v2.983Zm-1.727-.984c.012-.119.012-.248.012-.367v-.649H59.843v1.016Zm2.1-3.749a7.661,7.661,0,0,1-1.451-1.361,7.224,7.224,0,0,1-1.392,1.361Zm-.8,2.733v.681c0,.109,0,.227-.011.335h1.283v-1.016Z" transform="translate(-54.05 -608.96)" fill="#fff"/>
            <path id="パス_33" data-name="パス 33" d="M61.726,640.072a9.917,9.917,0,0,0,3.669,1.869,5.012,5.012,0,0,0-.923,1.026,8.936,8.936,0,0,1-1.259-.529v4.1H65.18v1.123H54.338v-1.123h1.978v-4.116a10.631,10.631,0,0,1-1.355.593,5.474,5.474,0,0,0-.887-.993,9.607,9.607,0,0,0,3.562-1.945H54.409v-1.113H59v-1.2h1.4v1.2H65.1v1.113Zm1.355,2.3a9.8,9.8,0,0,1-2.675-1.989v1.7H59v-1.74a9.8,9.8,0,0,1-2.6,2.032Zm-1.344,1.047H57.708v.432h4.029Zm-4.029,1.772h4.029v-.454H57.708Zm4.029,1.34v-.454H57.708v.454Z" transform="translate(-54.05 -608.96)" fill="#fff"/>
            <path id="パス_34" data-name="パス 34" d="M55.685,677.669v-1.34h1.558v-6.452H55.685v-1.353h8.126v1.353H62.253v6.452h1.558v1.34Zm4.94-7.792H58.871v6.452h1.754Z" transform="translate(-54.05 -608.96)" fill="#fff"/>
          </g>
        </g>
      </g>
    </g>
    <g id="グループ_1810" data-name="グループ 1810" transform="translate(9589.5 6275)">
      <rect id="長方形_423-3" data-name="長方形 423" width="210" height="15" transform="translate(-9499 -5623)" fill="#f3f3f3"/>
      <g id="グループ_1740-3" data-name="グループ 1740" transform="translate(337 -34)">
        <g id="グループ_1739-3" data-name="グループ 1739" transform="translate(167 189)">
          <path id="パス_222-29" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
          <path id="パス_222-30" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
          <path id="パス_222-31" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
          <path id="パス_222-32" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
          <path id="パス_222-33" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
          <path id="パス_222-34" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
          <path id="パス_222-35" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
          <path id="パス_222-36" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
          <path id="パス_222-37" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
          <path id="パス_223-3" data-name="パス 223" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
        </g>
      </g>
      <path id="パス_226-3" data-name="パス 226" d="M0,0H209" transform="translate(-9499 -5595.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_227-3" data-name="パス 227" d="M0,0H209" transform="translate(-9499 -5583.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_240-3" data-name="パス 240" d="M0,0H209" transform="translate(-9499 -5607.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_228-3" data-name="パス 228" d="M0,0H209" transform="translate(-9499 -5571.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_229-3" data-name="パス 229" d="M0,0H209" transform="translate(-9499 -5559.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_230-3" data-name="パス 230" d="M0,0H209" transform="translate(-9499 -5547.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_231-3" data-name="パス 231" d="M0,0H209" transform="translate(-9499 -5535.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_232-3" data-name="パス 232" d="M0,0H209" transform="translate(-9499 -5523.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_233-3" data-name="パス 233" d="M0,0H209" transform="translate(-9499 -5511.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <path id="パス_234-3" data-name="パス 234" d="M0,0H209" transform="translate(-9499 -5499.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
      <g id="グループ_1742-2" data-name="グループ 1742" transform="translate(333 -33)">
        <rect id="長方形_80-31" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_2-5" data-name="2" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">2</tspan></text>
      </g>
      <g id="グループ_1764-2" data-name="グループ 1764" transform="translate(333 -44.893)">
        <rect id="長方形_80-32" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_1-32" data-name="1" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">1</tspan></text>
      </g>
      <g id="グループ_1743-2" data-name="グループ 1743" transform="translate(333 -21)">
        <rect id="長方形_80-33" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_3-3" data-name="3" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">3</tspan></text>
      </g>
      <g id="グループ_1744-2" data-name="グループ 1744" transform="translate(333 -9)">
        <rect id="長方形_80-34" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_4-3" data-name="4" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">4</tspan></text>
      </g>
      <g id="グループ_1745-2" data-name="グループ 1745" transform="translate(333 3)">
        <rect id="長方形_80-35" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_5-3" data-name="5" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">5</tspan></text>
      </g>
      <g id="グループ_1746-2" data-name="グループ 1746" transform="translate(333 15)">
        <rect id="長方形_80-36" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_6-3" data-name="6" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">6</tspan></text>
      </g>
      <g id="グループ_1747-2" data-name="グループ 1747" transform="translate(333 27)">
        <rect id="長方形_80-37" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_7-3" data-name="7" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">7</tspan></text>
      </g>
      <g id="グループ_1748-2" data-name="グループ 1748" transform="translate(333 39)">
        <rect id="長方形_80-38" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_8-3" data-name="8" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">8</tspan></text>
      </g>
      <g id="グループ_1749-2" data-name="グループ 1749" transform="translate(333 51)">
        <rect id="長方形_80-39" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_9-2" data-name="9" transform="translate(-9822 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">9</tspan></text>
      </g>
      <g id="グループ_1761-3" data-name="グループ 1761" transform="translate(333 63)">
        <rect id="長方形_80-40" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
        <text id="_10-3" data-name="10" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">10</tspan></text>
      </g>
      <line id="線_557-3" data-name="線 557" x2="209" transform="translate(-9498.5 -5622.5)" fill="none" stroke="#000" stroke-width="1"/>
      <text id="番号-3" data-name="番号" transform="translate(-9496 -5633)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">番号</tspan></text>
      <text id="出題項目-3" data-name="出題項目" transform="translate(-9471 -5634)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">出題項目</tspan></text>
      <text id="配点-3" data-name="配点" transform="translate(-9378 -5634)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">配点</tspan></text>
      <text id="正答率-3" data-name="正答率" transform="translate(-9330 -5634)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正答率</tspan></text>
      <text id="正誤-3" data-name="正誤" transform="translate(-9354 -5634)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正誤</tspan></text>
      <g id="グループ_1835" data-name="グループ 1835" transform="translate(1.5)">
        <text id="_54-2" data-name="54" transform="translate(-9326 -5511)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][9]['正答率'] }}</tspan></text>
        <text id="_72-3" data-name="72" transform="translate(-9326 -5523)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][8]['正答率'] }}</tspan></text>
        <text id="_33-5" data-name="33" transform="translate(-9326 -5535)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][7]['正答率'] }}</tspan></text>
        <text id="_13-4" data-name="13" transform="translate(-9326 -5547)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][6]['正答率'] }}</tspan></text>
        <text id="_92-3" data-name="92" transform="translate(-9326 -5559)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][5]['正答率'] }}</tspan></text>
        <text id="_45-3" data-name="45" transform="translate(-9326 -5571)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][4]['正答率'] }}</tspan></text>
        <text id="_83-3" data-name="83" transform="translate(-9326 -5583)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][3]['正答率'] }}</tspan></text>
        <text id="_22-4" data-name="22" transform="translate(-9326 -5595)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][2]['正答率'] }}</tspan></text>
        <text id="_41-2" data-name="41" transform="translate(-9326 -5607)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][1]['正答率'] }}</tspan></text>
        <text id="_56-2" data-name="56" transform="translate(-9326 -5619)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][0]['正答率'] }}</tspan></text>
      </g>
      <g id="グループ_1809" data-name="グループ 1809">
        <text id="_9-3"  data-name="×" transform="translate(-9350 -5511)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][9]['正誤マーク'] }}</tspan></text>
        <text id="_10-4" data-name="×" transform="translate(-9350 -5523)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][8]['正誤マーク'] }}</tspan></text>
        <text id="_11-2" data-name="×" transform="translate(-9350 -5535)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][7]['正誤マーク'] }}</tspan></text>
        <text id="〇-19" data-name="〇" transform="translate(-9350 -5547)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][6]['正誤マーク'] }}</tspan></text>
        <text id="〇-20" data-name="〇" transform="translate(-9350 -5559)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][5]['正誤マーク'] }}</tspan></text>
        <text id="〇-21" data-name="〇" transform="translate(-9350 -5571)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][4]['正誤マーク'] }}</tspan></text>
        <text id="〇-22" data-name="〇" transform="translate(-9350 -5583)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][3]['正誤マーク'] }}</tspan></text>
        <text id="〇-23" data-name="〇" transform="translate(-9350 -5595)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][2]['正誤マーク'] }}</tspan></text>
        <text id="_12-2" data-name="×" transform="translate(-9350 -5607)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][1]['正誤マーク'] }}</tspan></text>
        <text id="〇-24" data-name="〇" transform="translate(-9350 -5619)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][0]['正誤マーク'] }}</tspan></text>
      </g>
      <g id="グループ_1807" data-name="グループ 1807" transform="translate(1)">
        <text id="_1-33" data-name="1" transform="translate(-9372 -5511)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-34" data-name="1" transform="translate(-9372 -5523)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-35" data-name="1" transform="translate(-9372 -5535)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-36" data-name="1" transform="translate(-9372 -5547)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_2-6" data-name="2" transform="translate(-9372 -5559)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
        <text id="_2-7" data-name="2" transform="translate(-9372 -5571)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
        <text id="_2-8" data-name="2" transform="translate(-9372 -5583)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
        <text id="_2-9" data-name="2" transform="translate(-9372 -5595)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
        <text id="_1-37" data-name="1" transform="translate(-9372 -5607)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
        <text id="_1-38" data-name="1" transform="translate(-9372 -5619)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      </g>
      <g id="グループ_1805" data-name="グループ 1805">
        <g id="グループ_1773-2" data-name="グループ 1773" transform="translate(204 -105)">
          <rect id="長方形_422-31" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
        </g>
        <g id="グループ_1774-2" data-name="グループ 1774" transform="translate(204 -117)">
          <rect id="長方形_422-32" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数-2" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
        </g>
        <g id="グループ_1775-2" data-name="グループ 1775" transform="translate(204 -129)">
          <rect id="長方形_422-33" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数-3" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
        </g>
        <g id="グループ_1776-2" data-name="グループ 1776" transform="translate(204 -141)">
          <rect id="長方形_422-34" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数-4" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
        </g>
        <g id="グループ_1777-2" data-name="グループ 1777" transform="translate(204 -153)">
          <rect id="長方形_422-35" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
          <text id="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
        </g>
        <g id="グループ_1778-2" data-name="グループ 1778" transform="translate(204 -165)">
          <rect id="長方形_422-36" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
          <text id="論-2" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
        </g>
        <g id="グループ_1779-2" data-name="グループ 1779" transform="translate(204 -177)">
          <rect id="長方形_422-37" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
          <text id="論-3" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
        </g>
        <g id="グループ_1780-2" data-name="グループ 1780" transform="translate(204 -189)">
          <rect id="長方形_422-38" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
          <text id="論-4" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
        </g>
        <g id="グループ_1781-2" data-name="グループ 1781" transform="translate(204 -201)">
          <rect id="長方形_422-39" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数-5" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
        </g>
        <g id="グループ_1782-2" data-name="グループ 1782" transform="translate(204 -213)">
          <rect id="長方形_422-40" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数-6" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
        </g>
      </g>
      <text id="損益算_損益算_推論_証言_推論_証言_推論_順序_推論_順序_集合_集合_分割払い_分割払い" data-name="損益算
損益算
推論（証言）
推論（証言）
推論（順序）
推論（順序）
集合
集合
分割払い
分割払い" transform="translate(-9473 -5610)" font-size="8.33" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="0">損益算</tspan><tspan x="0" y="12">損益算</tspan><tspan x="0" y="24">推論（証言）</tspan><tspan x="0" y="36">推論（証言）</tspan><tspan x="0" y="48">推論（順序）</tspan><tspan x="0" y="60">推論（順序）</tspan><tspan x="0" y="72">集合</tspan><tspan x="0" y="84">集合</tspan><tspan x="0" y="96">分割払い</tspan><tspan x="0" y="108">分割払い</tspan></text>
    </g>
    <g id="グループ_1825" data-name="グループ 1825" transform="translate(10275 5685)">
      <g id="グループ_1824" data-name="グループ 1824">
        <rect id="長方形_423-4" data-name="長方形 423" width="210" height="15" transform="translate(-9951.5 -5033)" fill="#f3f3f3"/>
        <g id="グループ_1740-4" data-name="グループ 1740" transform="translate(-115.5 556)">
          <g id="グループ_1739-4" data-name="グループ 1739" transform="translate(167 189)">
            <path id="パス_222-38" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
            <path id="パス_222-39" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
            <path id="パス_222-40" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
            <path id="パス_222-41" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
            <path id="パス_222-42" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
            <path id="パス_222-43" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
            <path id="パス_222-44" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
            <path id="パス_222-45" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
            <path id="パス_222-46" data-name="パス 222" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
            <path id="パス_223-4" data-name="パス 223" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
          </g>
        </g>
        <path id="パス_226-4" data-name="パス 226" d="M0,0H209" transform="translate(-9951.5 -5005.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_227-4" data-name="パス 227" d="M0,0H209" transform="translate(-9951.5 -4993.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_240-4" data-name="パス 240" d="M0,0H209" transform="translate(-9951.5 -5017.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_228-4" data-name="パス 228" d="M0,0H209" transform="translate(-9951.5 -4981.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_229-4" data-name="パス 229" d="M0,0H209" transform="translate(-9951.5 -4969.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_230-4" data-name="パス 230" d="M0,0H209" transform="translate(-9951.5 -4957.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_231-4" data-name="パス 231" d="M0,0H209" transform="translate(-9951.5 -4945.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_232-4" data-name="パス 232" d="M0,0H209" transform="translate(-9951.5 -4933.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_233-4" data-name="パス 233" d="M0,0H209" transform="translate(-9951.5 -4921.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <path id="パス_234-4" data-name="パス 234" d="M0,0H209" transform="translate(-9951.5 -4909.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        <g id="グループ_1761-4" data-name="グループ 1761" transform="translate(-119.5 653)">
          <rect id="長方形_80-41" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_20-2" data-name="20" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">20</tspan></text>
        </g>
        <g id="グループ_1815" data-name="グループ 1815" transform="translate(-119.5 641)">
          <rect id="長方形_80-42" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_19-2" data-name="19" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">19</tspan></text>
        </g>
        <g id="グループ_1816" data-name="グループ 1816" transform="translate(-119.5 629)">
          <rect id="長方形_80-43" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_18-2" data-name="18" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">18</tspan></text>
        </g>
        <g id="グループ_1817" data-name="グループ 1817" transform="translate(-119.5 617)">
          <rect id="長方形_80-44" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_17-2" data-name="17" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">17</tspan></text>
        </g>
        <g id="グループ_1818" data-name="グループ 1818" transform="translate(-119.5 605)">
          <rect id="長方形_80-45" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_16-2" data-name="16" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">16</tspan></text>
        </g>
        <g id="グループ_1819" data-name="グループ 1819" transform="translate(-119.5 593)">
          <rect id="長方形_80-46" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_15-2" data-name="15" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">15</tspan></text>
        </g>
        <g id="グループ_1820" data-name="グループ 1820" transform="translate(-119.5 581)">
          <rect id="長方形_80-47" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_14-2" data-name="14" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">14</tspan></text>
        </g>
        <g id="グループ_1821" data-name="グループ 1821" transform="translate(-119.5 569)">
          <rect id="長方形_80-48" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_13-5" data-name="13" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">13</tspan></text>
        </g>
        <g id="グループ_1822" data-name="グループ 1822" transform="translate(-119.5 557)">
          <rect id="長方形_80-49" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_12-3" data-name="12" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">12</tspan></text>
        </g>
        <g id="グループ_1823" data-name="グループ 1823" transform="translate(-119.5 545)">
          <rect id="長方形_80-50" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_11-3" data-name="11" transform="translate(-9824 -5573)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">11</tspan></text>
        </g>
        <line id="線_557-4" data-name="線 557" x2="209" transform="translate(-9951 -5032.5)" fill="none" stroke="#000" stroke-width="1"/>
        <text id="番号-4" data-name="番号" transform="translate(-9948.5 -5043)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">番号</tspan></text>
        <text id="出題項目-4" data-name="出題項目" transform="translate(-9923.5 -5044)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">出題項目</tspan></text>
        <text id="配点-4" data-name="配点" transform="translate(-9830.5 -5044)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">配点</tspan></text>
        <text id="正答率-4" data-name="正答率" transform="translate(-9782.5 -5044)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正答率</tspan></text>
        <text id="正誤-4" data-name="正誤" transform="translate(-9806.5 -5044)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正誤</tspan></text>
        <g id="グループ_1836" data-name="グループ 1836">
          <text id="_49-2" data-name="49" transform="translate(-9778.5 -4921)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][19]['正答率'] }}</tspan></text>
          <text id="_50-2" data-name="50" transform="translate(-9778.5 -4933)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][18]['正答率'] }}</tspan></text>
          <text id="_72-4" data-name="72" transform="translate(-9778.5 -4945)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][17]['正答率'] }}</tspan></text>
          <text id="_33-6" data-name="33" transform="translate(-9778.5 -4957)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][16]['正答率'] }}</tspan></text>
          <text id="_13-6" data-name="13" transform="translate(-9778.5 -4969)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][15]['正答率'] }}</tspan></text>
          <text id="_92-4" data-name="92" transform="translate(-9778.5 -4981)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][14]['正答率'] }}</tspan></text>
          <text id="_45-4" data-name="45" transform="translate(-9778.5 -4993)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][13]['正答率'] }}</tspan></text>
          <text id="_83-4" data-name="83" transform="translate(-9778.5 -5005)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][12]['正答率'] }}</tspan></text>
          <text id="_82-2" data-name="82" transform="translate(-9778.5 -5017)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][11]['正答率'] }}</tspan></text>
          <text id="_34-2" data-name="34" transform="translate(-9778.5 -5029)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.995" y="8">{{ section32DataSet['rows'][10]['正答率'] }}</tspan></text>
        </g>
        <g id="グループ_1809-2" data-name="グループ 1809" transform="translate(-452.5 590)">
          <text id="〇-25" data-name="〇" transform="translate(-9350 -5511)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][19]['正誤マーク'] }}</tspan></text>
          <text id="〇-26" data-name="〇" transform="translate(-9350 -5523)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][18]['正誤マーク'] }}</tspan></text>
          <text id="_13-7" data-name="×" transform="translate(-9350 -5535)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][17]['正誤マーク'] }}</tspan></text>
          <text id="〇-27" data-name="〇" transform="translate(-9350 -5547)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][16]['正誤マーク'] }}</tspan></text>
          <text id="〇-28" data-name="〇" transform="translate(-9350 -5559)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][15]['正誤マーク'] }}</tspan></text>
          <text id="_14-3" data-name="×" transform="translate(-9350 -5571)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][14]['正誤マーク'] }}</tspan></text>
          <text id="〇-29" data-name="〇" transform="translate(-9350 -5583)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][13]['正誤マーク'] }}</tspan></text>
          <text id="〇-30" data-name="〇" transform="translate(-9350 -5595)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][12]['正誤マーク'] }}</tspan></text>
          <text id="〇-31" data-name="〇" transform="translate(-9350 -5607)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][11]['正誤マーク'] }}</tspan></text>
          <text id="_15-3" data-name="×" transform="translate(-9350 -5619)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="3.45" y="8">{{ section32DataSet['rows'][10]['正誤マーク'] }}</tspan></text>
        </g>
        <g id="グループ_1807-2" data-name="グループ 1807" transform="translate(-452.5 590)">
          <text id="_2-10" data-name="2" transform="translate(-9372 -5511)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_2-11" data-name="2" transform="translate(-9372 -5523)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_2-12" data-name="2" transform="translate(-9372 -5535)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_1-39" data-name="1" transform="translate(-9372 -5547)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-40" data-name="1" transform="translate(-9372 -5559)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-41" data-name="1" transform="translate(-9372 -5571)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-42" data-name="1" transform="translate(-9372 -5583)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_2-13" data-name="2" transform="translate(-9372 -5595)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_2-14" data-name="2" transform="translate(-9372 -5607)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_2-15" data-name="2" transform="translate(-9372 -5619)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
        </g>
        <g id="グループ_1805-2" data-name="グループ 1805" transform="translate(-452.5 590)">
          <g id="グループ_1773-3" data-name="グループ 1773" transform="translate(204 -105)">
            <rect id="長方形_422-41" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
            <text id="論-5" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
          </g>
          <g id="グループ_1774-3" data-name="グループ 1774" transform="translate(204 -117)">
            <rect id="長方形_422-42" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
            <text id="論-6" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
          </g>
          <g id="グループ_1775-3" data-name="グループ 1775" transform="translate(204 -129)">
            <rect id="長方形_422-43" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-7" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
          </g>
          <g id="グループ_1776-3" data-name="グループ 1776" transform="translate(204 -141)">
            <rect id="長方形_422-44" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-8" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
          </g>
          <g id="グループ_1777-3" data-name="グループ 1777" transform="translate(204 -153)">
            <rect id="長方形_422-45" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-9" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
          </g>
          <g id="グループ_1778-3" data-name="グループ 1778" transform="translate(204 -165)">
            <rect id="長方形_422-46" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-10" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
          </g>
          <g id="グループ_1779-3" data-name="グループ 1779" transform="translate(204 -177)">
            <rect id="長方形_422-47" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-11" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
          </g>
          <g id="グループ_1780-3" data-name="グループ 1780" transform="translate(204 -189)">
            <rect id="長方形_422-48" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
            <text id="論-7" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
          </g>
          <g id="グループ_1781-3" data-name="グループ 1781" transform="translate(204 -201)">
            <rect id="長方形_422-49" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
            <text id="論-8" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
          </g>
          <g id="グループ_1782-3" data-name="グループ 1782" transform="translate(204 -213)">
            <rect id="長方形_422-50" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
            <text id="論-9" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
          </g>
        </g>
        <text id="推論_分類_推論_数量_推論_数量_確率_確率_表の読み取り_表の読み取り_表の読み取り_推論_分類_推論_分類_" data-name="推論（分類）
推論（数量）
推論（数量）
確率
確率
表の読み取り
表の読み取り
表の読み取り
推論（分類）
推論（分類）" transform="translate(-9924.5 -5020)" font-size="8.33" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="0">推論（分類）</tspan><tspan x="0" y="12">推論（数量）</tspan><tspan x="0" y="24">推論（数量）</tspan><tspan x="0" y="36">確率</tspan><tspan x="0" y="48">確率</tspan><tspan x="0" y="60">表の読み取り</tspan><tspan x="0" y="72">表の読み取り</tspan><tspan x="0" y="84">表の読み取り</tspan><tspan x="0" y="96">推論（分類）</tspan><tspan x="0" y="108">推論（分類）</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1834" data-name="グループ 1834" transform="translate(0 657)">
    <text id="_正誤判定のNは無選択の場合です_" data-name="  ★正誤判定のNは無選択の場合です。" transform="translate(0 7)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em">
      <tspan x="0" y="0" xml:space="preserve">  </tspan>
      <tspan font-family="SegoeUISymbol, Segoe UI Symbol">★</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">正誤判定のNは無選択の場合です。</tspan>
      <tspan xml:space="preserve">  </tspan>
      <tspan font-family="SegoeUISymbol, Segoe UI Symbol">★</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">正答率は、全国を基準としています。</tspan>
    </text>
    <g id="グループ_1830" data-name="グループ 1830" transform="translate(240 0)">
      <g id="グループ_1767" data-name="グループ 1767">
        <rect id="長方形_422-51" data-name="長方形 422" width="9" height="9" fill="#1c4817"/>
        <text id="語-21" data-name="語" transform="translate(2 0)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">語</tspan></text>
      </g>
      <g id="グループ_1680" data-name="グループ 1680" transform="translate(12 7)">
        <text id="_語いに配点" data-name="  語いに配点" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan font-family="YuGothicUI-Regular, Yu Gothic UI">語いに配点</tspan></text>
      </g>
      <g id="グループ_1827" data-name="グループ 1827" transform="translate(55 0)">
        <rect id="長方形_422-52" data-name="長方形 422" width="9" height="9" fill="#209115"/>
        <text id="文-11" data-name="文" transform="translate(2 0)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">文</tspan></text>
      </g>
      <g id="グループ_1679" data-name="グループ 1679" transform="translate(67 7)">
        <text id="_文章読解に配点" data-name="  文章読解に配点" font-size="6.5" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan font-size="7" font-family="YuGothicUI-Regular, Yu Gothic UI">文章読解に配点</tspan></text>
      </g>
      <g id="グループ_1828" data-name="グループ 1828" transform="translate(130 0)">
        <rect id="長方形_422-53" data-name="長方形 422" width="9" height="9" fill="#c22c2c"/>
        <text id="数-12" data-name="数" transform="translate(2 0)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">数</tspan></text>
      </g>
      <g id="グループ_1678" data-name="グループ 1678" transform="translate(142 7)">
        <text id="_数的能力に配点" data-name="  数的能力に配点" font-size="6.5" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan font-size="7" font-family="YuGothicUI-Regular, Yu Gothic UI">数的能力に配点</tspan></text>
      </g>
      <g id="グループ_1829" data-name="グループ 1829" transform="translate(205 0)">
        <rect id="長方形_422-54" data-name="長方形 422" width="9" height="9" fill="#f90"/>
        <text id="論-10" data-name="論" transform="translate(2 0)" fill="#fff" font-size="5" font-family="YuGothicUI-Bold, Yu Gothic UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="5">論</tspan></text>
      </g>
      <g id="グループ_1677" data-name="グループ 1677" transform="translate(218 7)">
        <text id="_論理に配点" data-name="  論理に配点" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan font-family="YuGothicUI-Regular, Yu Gothic UI">論理に配点</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1731" data-name="グループ 1731" transform="translate(6.814 -154.728)">
    <g id="グループ_1724" data-name="グループ 1724" transform="translate(10843.789 6138.728)">
      <rect id="長方形_101" data-name="長方形 101" width="228.932" height="172.228" rx="12" transform="translate(-10843.789 -5867.728)" fill="#daf9f3" opacity="0.37"/>
      <g id="グループ_1723" data-name="グループ 1723" transform="translate(-10806.934 -5842.607)">
        <text id="文章理解" transform="translate(63.609 7)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">文章理解</tspan></text>
        <text id="論理-2" data-name="論理" transform="translate(0 73.78)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">論理</tspan></text>
        <text id="語い-2" data-name="語い" transform="translate(141.22 73.78)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">語い</tspan></text>
        <text id="数的能力-2" data-name="数的能力" transform="translate(63.609 142.107)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">数的能力</tspan></text>
        <g id="グループ_1722" data-name="グループ 1722" transform="translate(17.437 10)">
          <g id="パス_212" data-name="パス 212" transform="translate(60.104 0) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 86.86808013916016 86.86808013916016 L 1.30757999420166 85.81363677978516 L 0.2531321346759796 0.2531321346759796 L 85.81363677978516 1.30757999420166 L 86.86808013916016 86.86808013916016 Z" stroke="none"/>
            <path d="M 0.5062789916992188 0.5062789916992188 L 1.5545654296875 85.566650390625 L 86.61493682861328 86.61493682861328 L 85.566650390625 1.5545654296875 L 0.5062789916992188 0.5062789916992188 M 0 0 L 86.06060791015625 1.06060791015625 L 87.1212158203125 87.1212158203125 L 1.06060791015625 86.06060791015625 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_213" data-name="パス 213" transform="translate(60.104 7.071) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 76.86775970458984 76.86775970458984 L 1.307251334190369 75.81407165527344 L 0.2535659372806549 0.2535659372806549 L 75.81407165527344 1.307251334190369 L 76.86775970458984 76.86775970458984 Z" stroke="none"/>
            <path d="M 0.5071182250976562 0.5071182250976562 L 1.5538330078125 75.56748962402344 L 76.61420440673828 76.61420440673828 L 75.56748962402344 1.5538330078125 L 0.5071182250976562 0.5071182250976562 M 0 0 L 76.06066131591797 1.060661315917969 L 77.12132263183594 77.12132263183594 L 1.060661315917969 76.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_214" data-name="パス 214" transform="translate(60.104 14.142) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 66.86721038818359 66.86721038818359 L 1.306744575500488 65.8145751953125 L 0.2541157305240631 0.2541157305240631 L 65.8145751953125 1.306744575500488 L 66.86721038818359 66.86721038818359 Z" stroke="none"/>
            <path d="M 0.5082321166992188 0.5082321166992188 L 1.552825927734375 65.56849670410156 L 66.61309051513672 66.61309051513672 L 65.56849670410156 1.552825927734375 L 0.5082321166992188 0.5082321166992188 M 0 0 L 66.06066131591797 1.060661315917969 L 67.12132263183594 67.12132263183594 L 1.060661315917969 66.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_215" data-name="パス 215" transform="translate(60.104 21.213) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 56.86645126342773 56.86645126342773 L 1.306067705154419 55.81525421142578 L 0.2548729479312897 0.2548729479312897 L 55.81525421142578 1.306067705154419 L 56.86645126342773 56.86645126342773 Z" stroke="none"/>
            <path d="M 0.509735107421875 0.509735107421875 L 1.551464080810547 55.56985855102539 L 56.61158752441406 56.61158752441406 L 55.56985855102539 1.551464080810547 L 0.509735107421875 0.509735107421875 M 0 0 L 56.06066131591797 1.060661315917969 L 57.12132263183594 57.12132263183594 L 1.060661315917969 56.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_216" data-name="パス 216" transform="translate(60.104 28.284) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 46.86536407470703 46.86536407470703 L 1.30509340763092 45.81622695922852 L 0.2559560835361481 0.2559560835361481 L 45.81622695922852 1.30509340763092 L 46.86536407470703 46.86536407470703 Z" stroke="none"/>
            <path d="M 0.5119209289550781 0.5119209289550781 L 1.549537658691406 45.57178115844727 L 46.60939788818359 46.60939788818359 L 45.57178115844727 1.549537658691406 L 0.5119209289550781 0.5119209289550781 M 0 0 L 46.06066131591797 1.060657501220703 L 47.12131881713867 47.12131881713867 L 1.060657501220703 46.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="長方形_419" data-name="長方形 419" transform="translate(60.104 36.855) rotate(45)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="0.5">
            <rect width="35" height="35" stroke="none"/>
            <rect x="0.25" y="0.25" width="34.5" height="34.5" fill="none"/>
          </g>
          <text id="_80_70_60_50_40_30_20" data-name="_80_70_60_50_40_30_20" transform="translate(54.0 7.5)" font-size="6" font-family="SegoeUI, Segoe UI">
            <tspan x="-2.475" y="0">80</tspan>
            <tspan x="-2.475" y="7.5">70</tspan>
            <tspan x="-2.475" y="15.0">60</tspan>
            <tspan x="-2.475" y="22.5">50</tspan>
            <tspan x="-2.475" y="30.0">40</tspan>
            <tspan x="-2.475" y="37.5">30</tspan>
            <tspan x="-2.475" y="45.0">20</tspan>
          </text>
          <line id="線_555" data-name="線 555" x2="120" transform="translate(0.347 61.751)" fill="none" stroke="#000" stroke-width="0.5"/>
          <line id="線_556" data-name="線 556" y2="122" transform="translate(60.104 0.748)" fill="none" stroke="#000" stroke-width="0.5"/>
          <g id="長方形_420" data-name="長方形 420" transform="translate(60.104 43.864) rotate(45)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="0.5">
            <rect width="25" height="25" stroke="none"/>
            <rect x="0.25" y="0.25" width="24.5" height="24.5" fill="none"/>
          </g>
          <!-- レーダーチャート -->
          <!--
          <path id="_調整用_80" data-name="_調整用_80" :d="`M 0,-${calcRadarChartLen(80)} L ${calcRadarChartLen(80)},0 0,${calcRadarChartLen(80)} -${calcRadarChartLen(80)},0 0,-${calcRadarChartLen(80)}    0,${calcRadarChartLen(80)}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#00ff00" :stroke-width="`${radar_chart.line_width}`"/>
          <path id="_調整用_50" data-name="_調整用_50" :d="`M 0,-${calcRadarChartLen(50)} L ${calcRadarChartLen(50)},0 0,${calcRadarChartLen(50)} -${calcRadarChartLen(50)},0 0,-${calcRadarChartLen(50)}    0,0 ${calcRadarChartLen(50)},0`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#c70707" :stroke-width="`${radar_chart.line_width}`"/>
          <path id="_調整用_20" data-name="_調整用_20" :d="`M 0,-${calcRadarChartLen(20)} L ${calcRadarChartLen(20)},0 0,${calcRadarChartLen(20)} -${calcRadarChartLen(20)},0 0,-${calcRadarChartLen(20)}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#00ff00" :stroke-width="`${radar_chart.line_width}`"/>
          -->
          <path id="パス_218" data-name="パス_218" :d="`M 0,-${calcRadarChartLen(section2DataSet['rows'][0]['rows'][0]['全国偏差値_adj_20_80'])} L ${calcRadarChartLen(section2DataSet['rows'][0]['rows'][1]['全国偏差値_adj_20_80'])},0 0,${calcRadarChartLen(section2DataSet['rows'][1]['rows'][0]['全国偏差値_adj_20_80'])} -${calcRadarChartLen(section2DataSet['rows'][1]['rows'][1]['全国偏差値_adj_20_80'])},0 0,-${calcRadarChartLen(section2DataSet['rows'][0]['rows'][0]['全国偏差値_adj_20_80'])}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#c70707" :stroke-width="`${radar_chart.line_width}`"/>
        </g>
      </g>
      <path id="パス_55" data-name="パス 55" d="M7.6,0H187.832c4.2,0,7.6,1.9,7.6,4.252V16.074c0,2.348-3.4,4.252-7.6,4.252H7.6c-4.2,0-7.6-1.9-7.6-4.252V4.252C0,1.9,3.4,0,7.6,0Z" transform="translate(-10824.039 -5867.728)" fill="#28759d"/>
    </g>
    <text id="分野別偏差値チャート" transform="translate(120.466 285.272)" fill="#fff" stroke="#fff" stroke-width="0.4" font-size="11" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="-50.694" y="0">分野別偏差値チャート</tspan></text>
  </g>
</svg>

    </div><!-- report_body -->
    <div v-else class="practice_report_body">

      <!-- ↓ CR, WT で同一 -->

      <table class="summery_table">
        <tr>
          <th class="correct_count my_h6">正解数</th>
          <td class="correct_count">{{ section1DataSet['正解数'] }}</td>
          <th class="correct_rate my_h6">正解率</th>
          <td class="correct_rate">{{ section1DataSet['正解率'] }}%</td>
        </tr>
      </table>

      <table class="question_table mt-3"><!-- CR,WTで同一 -->
        <thead>
          <tr>
            <th class="question_no my_h6">問題番号</th>
            <th class="unit my_h6">出題項目</th>
            <th class="correct_answer my_h6">正解</th>
            <th class="your_answer"><span class="my_h6" style="margin-right: 1em;">あなたの解答</span><br class="d-md-none" /><span class="small">※Nは無回答</span></th>
            <th class="correct_mark my_h6">正誤</th>
          </tr>
        </thead>
        <tbody>
          <tr class="section31" v-for="(row, idx) in section31DataSet['rows']" :key="`row_1_${idx}`">
            <td class="question_no">{{ row['問題番号'] }}</td>
            <td class="unit my_h6">{{ row['出題項目'] }}</td>
            <td class="correct_answer my_h6">{{ row['正解'].join('、') }}</td>
            <td class="your_answer my_h6">{{ row['あなたの解答'].length? row['あなたの解答'].map(value => value? value: 'N').join('、'): 'N' }}</td>
            <td class="correct_mark">{{ row['正誤マーク_2'] }}</td>
          </tr>
          <tr class="section32" v-for="(row, idx) in section32DataSet['rows']" :key="`row_2_${idx}`">
            <td class="question_no">{{ row['問題番号'] }}</td>
            <td class="unit my_h6">{{ row['出題項目'] }}</td>
            <td class="correct_answer my_h6">{{ row['正解'].join('、') }}</td>
            <td class="your_answer my_h6">{{ row['あなたの解答'].length? row['あなたの解答'].map(value => value? value: 'N').join('、'): 'N' }}</td>
            <td class="correct_mark">{{ row['正誤マーク_2'] }}</td>
          </tr>
        </tbody>
      </table>

      <!-- ↑ CR, WT で同一 -->

    </div><!-- practice_report_body -->

  </div><!-- report_wrapper -->
</template>

<script>
import baseMixin from './personal_report_base';
const LogHeader = 'PersonalReport_CR';

export default {
  props: {
  },
  mixins: [ baseMixin ],
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    const dev80_base_pos = 60.5;//調整用の偏差値80の枠が台紙の偏差値80の枠と重なるようにする
    const dev20_base_pos = 18.0;//調整用の偏差値20の枠が台紙の偏差値20の枠と重なるようにする
    const dev1_len = (dev80_base_pos - dev20_base_pos) / (80 - 20);
    return {
      radar_chart: {
        center_x: 61.5,//レーダーチャートの全体的な左右のズレはここで調整する
        center_y: 61.8,//レーダーチャートの全体的な上下のズレはここで調整する
        dev20_base_pos,
        dev1_len,
        line_width: 1.8,
      },
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    section1DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_1']; },
    section2DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_2']; },
    section31DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_3_1']; },
    section32DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_3_2']; },
  },
  methods: {
    calcRadarChartLen(deviationScore) {
      return this.radar_chart? this.radar_chart.dev20_base_pos + (deviationScore - 20) * this.radar_chart.dev1_len: 0;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/_base"

.report_wrapper

  .report_body
    svg
      display: block
      margin: 0 auto
      $orgWidth: 539
      $orgHeight: 670.164
      width: 84vw
      height: 84vw / $orgWidth * $orgHeight
      +mq(min_576)
        width: 80vw
        height: 80vw / $orgWidth * $orgHeight
      +mq(min_992)
        width: 60vw
        height: 60vw / $orgWidth * $orgHeight
      +mq(min_1200)
        width: 50vw
        height: 50vw / $orgWidth * $orgHeight

  .practice_report_body //$questionBgColor1, $questionBgColor2 を除き、CR,WTで同一
    $width: 100%
    .summery_table
      $bgColor: #A2E6FF
      width: $width
      th, td
        border: 1px solid black
        padding: 1em 0
        text-align: center
      th
        background-color: $bgColor
      th.correct_count
        width: 20%
      td.correct_count
        width: 25%
      th.correct_rate
        width: 20%
      td.correct_rate
        width: 25%
    .question_table
      $headerBgColor: #FFFFA2
      $questionBgColor1: #F6F6F6 //CR,WTで異なる
      $questionBgColor2: #F6F6F6 //CR,WTで異なる
      width: $width
      th, td
        border: 1px solid black
        text-align: center
      th
        background-color: $headerBgColor
      .section31
        td.question_no, td.unit
          background-color: $questionBgColor1
      .section32
        td.question_no, td.unit
          background-color: $questionBgColor2
      td.unit
        text-align: unset
        +mq(min_576)
          padding-left: 2em
      td.correct_mark
        color: red
      .question_no
        width: 15%
      .unit
        width: 25%
      .correct_answer
        width: 15%
      .your_answer
        width: 30%
      .correct_mark
        width: 15%

</style>

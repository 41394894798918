<template>
  <div class="report_wrapper my-4">

    <div v-if="!scExStudentExamPartSet.is_practice" class="report_body">

<!-- 「WT-1　 試験結果画面２.xd」より -->
<!--
【調整のコツ】
・svgタグのwidth,heightを元にSASSを記述（縦横比が重要） ※svgのwidth,heightは無効化する
・中央寄せはtextタグやtspanタグ内の「text-anchor="middle"」 → 座標調整より先にやる！！
・tspanタグのx属性
・transform属性のtranslate関数の第１引数
-->
<svg xmlns="http://www.w3.org/2000/svg" _width="517" _height="675.589" viewBox="0 0 517 675.589">
  <g id="グループ_1727" data-name="グループ 1727" transform="translate(-33.859 -122.17)">
    <g id="グループ_1707" data-name="グループ 1707" transform="translate(-13.086 23.118)">
      <path id="パス_50" data-name="パス 50" d="M0,0H487.717" transform="translate(61.585 206.422)" fill="none" stroke="#000" stroke-width="0.5"/>
      <g id="グループ_1697" data-name="グループ 1697" transform="translate(25 4.053)">
        <rect id="長方形_93" data-name="長方形 93" width="103" height="76" rx="12" transform="translate(182.992 119.482)" fill="#f6f6f6"/>
        <path id="パス_203" data-name="パス 203" d="M11.517,0h104.61a11.766,11.766,0,0,1,11.517,12V64a11.766,11.766,0,0,1-11.517,12H11.517A11.766,11.766,0,0,1,0,64V12A11.766,11.766,0,0,1,11.517,0Z" transform="translate(43.869 118.482)" fill="#daf9f3"/>
        <rect id="長方形_94" data-name="長方形 94" width="104" height="76" rx="12" transform="translate(297.553 119.482)" fill="#f6f6f6"/>
        <rect id="長方形_95" data-name="長方形 95" width="104" height="75" rx="12" transform="translate(411.514 118.482)" fill="#f6f6f6"/>
        <line id="線_8" data-name="線 8" x2="487.283" y2="0.018" transform="translate(36.586 142.965)" fill="none" stroke="#000" stroke-width="0.5"/>
        <line id="線_9" data-name="線 9" y1="0.613" x2="487.717" transform="translate(36.586 168.352)" fill="none" stroke="#000" stroke-width="0.5"/>
        <text id="得点" transform="translate(96 134.721)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="0" y="0">得点</tspan></text>
        <text id="全国順位" transform="translate(86 186.352)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="0" y="0">全国順位</tspan></text>
        <text id="偏差値_全国_" data-name="偏差値（全国）" transform="translate(80.5 160.982)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="0" y="0">偏差値</tspan><tspan y="0" font-size="8">（全国）</tspan></text>
        <g id="総合" transform="translate(30.039 -38.518)">
          <text id="_30点" data-name="30点" transform="translate(202 175)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][0]['得点'] }}点</tspan></text>
          <text id="_53" data-name="53" transform="translate(201.553 200.5)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][0]['全国偏差値'] }}</tspan></text>
          <text id="_4578位_10000人中" data-name="4578位 / 10000人中" transform="translate(204.553 226)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][0]['全国順位'] }}位 / 10000人中</tspan></text>
        </g>
        <g id="検査_" data-name="検査１" transform="translate(145.999 -39.518)">
          <text id="_21点" data-name="21点" transform="translate(202 175)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][1]['得点'] }}点</tspan></text>
          <text id="_59" data-name="59" transform="translate(201.553 200.5)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][1]['全国偏差値'] }}</tspan></text>
          <text id="_1821位_10000人中" data-name="1821位 / 10000人中" transform="translate(204.553 226)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][1]['全国順位'] }}位 / 10000人中</tspan></text>
        </g>
        <g id="検査_2" data-name="検査１" transform="translate(259.961 -39.018)">
          <text id="_9点" data-name="9点" transform="translate(202 175)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][2]['得点'] }}点</tspan></text>
          <text id="_48" data-name="48" transform="translate(201.553 200.5)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][2]['全国偏差値'] }}</tspan></text>
          <text id="_6541位_10000人中" data-name="6541位 / 10000人中" transform="translate(204.553 226)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em" text-anchor="middle"><tspan x="0" y="0">{{ section1DataSet['columns'][2]['全国順位'] }}位 / 10000人中</tspan></text>
        </g>
        <path id="パス_51" data-name="パス 51" d="M4.005,0h94.99A4.134,4.134,0,0,1,103,4.252V16.074a4.134,4.134,0,0,1-4.005,4.252H4.005A4.134,4.134,0,0,1,0,16.074V4.252A4.134,4.134,0,0,1,4.005,0Z" transform="translate(182.992 95)" fill="#28759d"/>
        <text id="総合_60点満点_" data-name="総合（60点満点）" transform="translate(238.591 109.163)" fill="#fff" stroke="#fff" stroke-width="0.1" font-size="9" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="0" y="0">総合（60点満点）</tspan></text>
        <path id="パス_52" data-name="パス 52" d="M4.044,0H99.956A4.152,4.152,0,0,1,104,4.252V16.074a4.152,4.152,0,0,1-4.044,4.252H4.044A4.152,4.152,0,0,1,0,16.074V4.252A4.152,4.152,0,0,1,4.044,0Z" transform="translate(297.553 95)" fill="#28759d"/>
        <text id="言語_30点満点_" data-name="言語（30点満点）" transform="translate(352.553 109.163)" fill="#fff" stroke="#fff" stroke-width="0.1" font-size="9" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="0" y="0">言語（30点満点）</tspan></text>
        <path id="パス_53" data-name="パス 53" d="M4.044,0H99.956A4.152,4.152,0,0,1,104,4.252V16.074a4.152,4.152,0,0,1-4.044,4.252H4.044A4.152,4.152,0,0,1,0,16.074V4.252A4.152,4.152,0,0,1,4.044,0Z" transform="translate(411.514 95)" fill="#28759d"/>
        <text id="非言語_30点満点_" data-name="非言語（30点満点）" transform="translate(466.514 109.163)" fill="#fff" stroke="#fff" stroke-width="0.1" font-size="9" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="0" y="0">非言語（30点満点）</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1726" data-name="グループ 1726" transform="translate(9984.211 6054.97)">
    <g id="グループ_1730" data-name="グループ 1730" transform="translate(14 -8)">
      <g id="分野別結果表" transform="translate(-9761.272 -5916.36)">
        <rect id="長方形_107" data-name="長方形 107" width="276.061" height="21" transform="translate(0 114.721)" fill="#daf9f3"/>
        <path id="パス_60" data-name="パス 60" d="M0,0H276.061V21H42.25v92.548H0V0Z" transform="translate(0 0.107)" fill="#daf9f3"/>
        <text id="_8_12_7_3_30" data-name="8
12
7
3
30" transform="translate(171.984 38.5)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em"><tspan x="0" y="0" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][0]['得点'] }}</tspan><tspan x="0" y="22.6" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][1]['得点'] }}</tspan><tspan x="0" y="45.2" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][0]['得点'] }}</tspan><tspan x="0" y="67.8" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][1]['得点'] }}</tspan><tspan x="0" y="90.4" text-anchor="middle">{{ section2DataSet['rows'][2]['rows'][0]['得点'] }}</tspan></text>
        <text id="_64_55_52_43_54" data-name="64
55
52
43
54" transform="translate(242.108 38.5)" font-size="11" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.025em"><tspan x="0" y="0" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][0]['全国偏差値'] }}</tspan><tspan x="0" y="22.6" text-anchor="middle">{{ section2DataSet['rows'][0]['rows'][1]['全国偏差値'] }}</tspan><tspan x="0" y="45.2" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][0]['全国偏差値'] }}</tspan><tspan x="0" y="67.8" text-anchor="middle">{{ section2DataSet['rows'][1]['rows'][1]['全国偏差値'] }}</tspan><tspan x="0" y="90.4" text-anchor="middle">{{ section2DataSet['rows'][2]['rows'][0]['全国偏差値'] }}</tspan></text>
        <text id="分野" transform="translate(88.527 13.5)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="-9" y="0">分野</tspan></text>
        <text id="得点-2" data-name="得点" transform="translate(171.984 13.5)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="-9" y="0">得点</tspan></text>
        <text id="偏差値" transform="translate(241.608 13.5)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="-13.5" y="0">偏差値</tspan></text>
        <text id="総_合_計" data-name="総   合   計" transform="translate(65.354 129.096)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="-19.629" y="0" xml:space="preserve">総   合   計</tspan></text>
        <line id="線_77" data-name="線 77" x2="276.061" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-2" data-name="線 77" x2="276.061" transform="translate(0 21)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-3" data-name="線 77" x2="233.288" y2="0.055" transform="translate(42.772 45.141)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-4" data-name="線 77" x2="276.061" transform="translate(0 67.793)" fill="none" stroke="#28759d" stroke-width="2"/>
        <path id="パス_211" data-name="パス 211" d="M-20.11,0H213" transform="translate(63.061 90.391)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-5" data-name="線 77" x2="276.061" transform="translate(0 114.596)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_77-6" data-name="線 77" x2="276.061" transform="translate(0 135.596)" fill="none" stroke="#28759d" stroke-width="2"/>
        <line id="線_78" data-name="線 78" y2="135.596" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-2" data-name="線 78" x1="0.228" y2="114.141" transform="translate(42.772)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-3" data-name="線 78" y2="135.596" transform="translate(138.03)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-4" data-name="線 78" y2="135.596" transform="translate(207.045)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <line id="線_78-5" data-name="線 78" y2="135.596" transform="translate(276.061)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
        <text id="言語" transform="translate(20.5 47.282)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="-9" y="0">言語</tspan></text>
        <text id="非言語" transform="translate(20 94.076)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700"><tspan x="-13.5" y="0">非言語</tspan></text>
      </g>
      <g id="グループ_1725" data-name="グループ 1725" transform="translate(27 -22)">
        <text id="文章読解" transform="translate(-9727 -5867)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="25" y="10">文章読解</tspan></text>
        <text id="語い" transform="translate(-9713 -5845)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="12" y="10">語い</tspan></text>
        <text id="数的能力" transform="translate(-9726 -5823)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="25" y="10">数的能力</tspan></text>
        <text id="論理" transform="translate(-9713 -5799)" font-size="9" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em" text-anchor="middle"><tspan x="12" y="10">論理</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1912" data-name="グループ 1912" transform="translate(-37.5 -121.632)">
    <g id="グループ_1911" data-name="グループ 1911" transform="translate(6 1)">
      <g id="グループ_1872" data-name="グループ 1872" transform="translate(-398 342)">
        <rect id="長方形_421" data-name="長方形 421" width="48" height="15" rx="7.5" transform="translate(494 74)" fill="#f55656"/>
        <text id="言語-2" data-name="言語" transform="translate(508 85)" fill="#fff" font-size="10" font-family="Meiryo"><tspan x="0" y="0">言語</tspan></text>
      </g>
      <g id="グループ_1873" data-name="グループ 1873" transform="translate(-171 342)">
        <rect id="長方形_421-2" data-name="長方形 421" width="48" height="15" rx="7.5" transform="translate(494 74)" fill="#f55656"/>
        <text id="非言語-2" data-name="非言語" transform="translate(503 85)" fill="#fff" font-size="10" font-family="Meiryo"><tspan x="0" y="0">非言語</tspan></text>
      </g>
    </g>
    <g id="グループ_1896" data-name="グループ 1896">
      <g id="長方形_102" data-name="長方形 102" transform="translate(37.5 411.105)" fill="none" stroke="#28759d" stroke-width="2">
        <rect width="517" height="386.115" rx="14" stroke="none"/>
        <rect x="1" y="1" width="515" height="384.115" rx="13" fill="none"/>
      </g>
      <path id="パス_17" data-name="パス 17" d="M83.561,566.156c0,112.321,13.117,192.626,13.117,192.626H52.645c-4.756-.97-7.967-4.573-7.967-10.161V382.831c0-5.59,3.584-10.165,7.967-10.165H96.678S83.561,453.833,83.561,566.156Z" transform="translate(-7.178 38.439)" fill="#28759d"/>
      <text id="正_誤_表_正_答_率" data-name="正
誤
表

正
答
率" transform="translate(56.5 553)" fill="#fff" font-size="13" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="-6.5" y="0">正</tspan><tspan x="-6.5" y="13">誤</tspan><tspan x="-6.5" y="26">表</tspan><tspan x="0.325" y="39"></tspan><tspan x="-6.5" y="52">正</tspan><tspan x="-6.5" y="65">答</tspan><tspan x="-6.5" y="78">率</tspan></text>
    </g>
  </g>
  <g id="グループ_1895" data-name="グループ 1895" transform="translate(9341.972 5518.213)">
    <g id="グループ_1844" data-name="グループ 1844" transform="translate(-8265.752 -377.606)">
      <g id="グループ_1850" data-name="グループ 1850">
        <g id="グループ_1849" data-name="グループ 1849" transform="translate(0 11)">
          <g id="グループ_1847" data-name="グループ 1847" transform="translate(-236 202)">
            <g id="グループ_1846" data-name="グループ 1846">
              <g id="グループ_1845" data-name="グループ 1845" transform="translate(26 -7)">
                <rect id="長方形_424" data-name="長方形 424" width="210" height="13" transform="translate(-807 -5006)" fill="#fff"/>
                <rect id="長方形_424-2" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4994)" fill="#f3f3f3"/>
                <rect id="長方形_424-3" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4982)" fill="#fff"/>
                <rect id="長方形_424-4" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4970)" fill="#f3f3f3"/>
                <rect id="長方形_424-5" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4958)" fill="#fff"/>
                <rect id="長方形_424-6" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4946)" fill="#f3f3f3"/>
                <rect id="長方形_424-7" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4934)" fill="#fff"/>
                <rect id="長方形_424-8" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4922)" fill="#f3f3f3"/>
                <rect id="長方形_424-9" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4910)" fill="#fff"/>
                <rect id="長方形_424-10" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4898)" fill="#f3f3f3"/>
                <rect id="長方形_424-11" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4886)" fill="#fff"/>
                <rect id="長方形_424-12" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4874)" fill="#f3f3f3"/>
                <rect id="長方形_424-13" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4862)" fill="#fff"/>
                <rect id="長方形_424-14" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4850)" fill="#f3f3f3"/>
                <rect id="長方形_424-15" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4838)" fill="#fff"/>
                <rect id="長方形_424-16" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4826)" fill="#f3f3f3"/>
                <rect id="長方形_424-17" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4814)" fill="#fff"/>
                <rect id="長方形_424-18" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4802)" fill="#f3f3f3"/>
                <rect id="長方形_424-19" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4790)" fill="#fff"/>
                <rect id="長方形_424-20" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4778)" fill="#f3f3f3"/>
                <rect id="長方形_424-21" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4766)" fill="#fff"/>
                <rect id="長方形_424-22" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4754)" fill="#f3f3f3"/>
                <rect id="長方形_424-23" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4742)" fill="#fff"/>
                <rect id="長方形_424-24" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4730)" fill="#f3f3f3"/>
                <rect id="長方形_424-25" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4718)" fill="#fff"/>
                <rect id="長方形_424-26" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4706)" fill="#f3f3f3"/>
                <rect id="長方形_424-27" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4694)" fill="#fff"/>
              </g>
              <line id="線_558" data-name="線 558" x2="209" transform="translate(-781 -5000.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_587" data-name="線 587" x2="209" transform="translate(-780.5 -5013)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_559" data-name="線 559" x2="209" transform="translate(-781 -4988.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_560" data-name="線 560" x2="209" transform="translate(-781 -4976.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_561" data-name="線 561" x2="209" transform="translate(-780.5 -4964.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_562" data-name="線 562" x2="209" transform="translate(-780.5 -4952.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_563" data-name="線 563" x2="209" transform="translate(-780.5 -4940.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_564" data-name="線 564" x2="209" transform="translate(-780.5 -4928.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_565" data-name="線 565" x2="209" transform="translate(-781 -4916.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_566" data-name="線 566" x2="209" transform="translate(-781 -4904.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_567" data-name="線 567" x2="209" transform="translate(-781 -4892.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_568" data-name="線 568" x2="209" transform="translate(-780.5 -4880.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_569" data-name="線 569" x2="209" transform="translate(-781 -4868.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_570" data-name="線 570" x2="209" transform="translate(-780.5 -4856.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_571" data-name="線 571" x2="209" transform="translate(-781 -4844.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_572" data-name="線 572" x2="209" transform="translate(-781 -4832.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_573" data-name="線 573" x2="209" transform="translate(-781 -4820.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_574" data-name="線 574" x2="209" transform="translate(-781 -4808.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_575" data-name="線 575" x2="209" transform="translate(-781 -4796.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_576" data-name="線 576" x2="209" transform="translate(-781 -4784.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_577" data-name="線 577" x2="209" transform="translate(-780.5 -4772.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_578" data-name="線 578" x2="209" transform="translate(-781 -4760.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_579" data-name="線 579" x2="209" transform="translate(-780.5 -4748.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_580" data-name="線 580" x2="209" transform="translate(-781 -4736.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_581" data-name="線 581" x2="209" transform="translate(-781 -4724.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_582" data-name="線 582" x2="209" transform="translate(-780.5 -4712.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <line id="線_583" data-name="線 583" x2="209" transform="translate(-781 -4700.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            </g>
            <line id="線_584" data-name="線 584" x2="209" transform="translate(-781 -4688.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          </g>
          <g id="グループ_1848" data-name="グループ 1848">
            <rect id="長方形_80" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4808.607)" fill="#28759d"/>
            <text id="_2" data-name="2" transform="translate(-1007 -4809.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">2</tspan></text>
            <rect id="長方形_80-2" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4820.5)" fill="#28759d"/>
            <text id="_1" data-name="1" transform="translate(-1007 -4821)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">1</tspan></text>
            <rect id="長方形_80-3" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4796.607)" fill="#28759d"/>
            <text id="_3" data-name="3" transform="translate(-1007 -4797.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">3</tspan></text>
            <rect id="長方形_80-4" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4784.607)" fill="#28759d"/>
            <text id="_4" data-name="4" transform="translate(-1007 -4785.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">4</tspan></text>
            <rect id="長方形_80-5" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4772.607)" fill="#28759d"/>
            <text id="_5" data-name="5" transform="translate(-1007 -4773.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">5</tspan></text>
            <rect id="長方形_80-6" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4760.607)" fill="#28759d"/>
            <text id="_6" data-name="6" transform="translate(-1007 -4761.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">6</tspan></text>
            <rect id="長方形_80-7" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4748.607)" fill="#28759d"/>
            <text id="_7" data-name="7" transform="translate(-1007 -4749.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">7</tspan></text>
            <rect id="長方形_80-8" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4736.607)" fill="#28759d"/>
            <text id="_8" data-name="8" transform="translate(-1007 -4737.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">8</tspan></text>
            <rect id="長方形_80-9" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4724.607)" fill="#28759d"/>
            <text id="_9" data-name="9" transform="translate(-1007 -4725.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">9</tspan></text>
            <rect id="長方形_80-10" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4700.607)" fill="#28759d"/>
            <text id="_11" data-name="11" transform="translate(-1009 -4701.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">11</tspan></text>
            <rect id="長方形_80-11" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4712.607)" fill="#28759d"/>
            <text id="_10" data-name="10" transform="translate(-1009 -4713.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">10</tspan></text>
            <rect id="長方形_80-12" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4688.607)" fill="#28759d"/>
            <text id="_12" data-name="12" transform="translate(-1009 -4689.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">12</tspan></text>
            <rect id="長方形_80-13" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4676.607)" fill="#28759d"/>
            <text id="_13" data-name="13" transform="translate(-1009 -4677.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">13</tspan></text>
            <rect id="長方形_80-14" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4664.607)" fill="#28759d"/>
            <text id="_14" data-name="14" transform="translate(-1009 -4665.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">14</tspan></text>
            <rect id="長方形_80-15" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4652.607)" fill="#28759d"/>
            <rect id="長方形_425" data-name="長方形 425" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4640.607)" fill="#28759d"/>
            <rect id="長方形_426" data-name="長方形 426" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4628.607)" fill="#28759d"/>
            <rect id="長方形_427" data-name="長方形 427" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4616.607)" fill="#28759d"/>
            <rect id="長方形_428" data-name="長方形 428" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4604.607)" fill="#28759d"/>
            <rect id="長方形_429" data-name="長方形 429" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4592.607)" fill="#28759d"/>
            <rect id="長方形_430" data-name="長方形 430" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4580.607)" fill="#28759d"/>
            <rect id="長方形_431" data-name="長方形 431" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4568.607)" fill="#28759d"/>
            <rect id="長方形_432" data-name="長方形 432" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4556.607)" fill="#28759d"/>
            <rect id="長方形_433" data-name="長方形 433" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4544.607)" fill="#28759d"/>
            <rect id="長方形_434" data-name="長方形 434" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4532.607)" fill="#28759d"/>
            <rect id="長方形_435" data-name="長方形 435" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4520.607)" fill="#28759d"/>
            <rect id="長方形_436" data-name="長方形 436" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4508.607)" fill="#28759d"/>
            <rect id="長方形_437" data-name="長方形 437" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4496.607)" fill="#28759d"/>
            <text id="_15" data-name="15" transform="translate(-1009 -4653.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">15</tspan></text>
            <text id="_16" data-name="16" transform="translate(-1009 -4641.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">16</tspan></text>
            <text id="_17" data-name="17" transform="translate(-1009 -4629.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">17</tspan></text>
            <text id="_18" data-name="18" transform="translate(-1009 -4617.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">18</tspan></text>
            <text id="_19" data-name="19" transform="translate(-1009 -4605.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">19</tspan></text>
            <text id="_20" data-name="20" transform="translate(-1009 -4593.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">20</tspan></text>
            <text id="_21" data-name="21" transform="translate(-1009 -4581.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">21</tspan></text>
            <text id="_22" data-name="22" transform="translate(-1009 -4569.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">22</tspan></text>
            <text id="_23" data-name="23" transform="translate(-1009 -4557.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">23</tspan></text>
            <text id="_24" data-name="24" transform="translate(-1009 -4545.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">24</tspan></text>
            <text id="_25" data-name="25" transform="translate(-1009 -4533.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">25</tspan></text>
            <text id="_26" data-name="26" transform="translate(-1009 -4521.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">26</tspan></text>
            <text id="_27" data-name="27" transform="translate(-1009 -4509.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">27</tspan></text>
            <text id="_28" data-name="28" transform="translate(-1009 -4497.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">28</tspan></text>
          </g>
        </g>
        <line id="線_585" data-name="線 585" x2="210.5" y2="0.106" transform="translate(-1017 -4814.606)" fill="none" stroke="#000" stroke-width="1"/>
      </g>
      <g id="グループ_1852" data-name="グループ 1852" transform="translate(-0.32 206.5)">
        <text id="番号" transform="translate(-1013 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">番号</tspan></text>
        <text id="出題項目" transform="translate(-989 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">出題項目</tspan></text>
        <text id="配点" transform="translate(-896 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">配点</tspan></text>
        <text id="正答率" transform="translate(-848 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正答率</tspan></text>
        <text id="正誤" transform="translate(-872 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正誤</tspan></text>
      </g>
    </g>
    <text id="熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_熟語の成り立ち_3文の完成_3文の完成_3文の完成_3文の完成_3文の完成_3文の完成_2文の完成_2文の完成_文章整序_文章整序_文章整序_適語補充_適文補充_適文補充_適文補充_文章読解_文章読解_文章読解" data-name="熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
熟語の成り立ち
3文の完成
3文の完成
3文の完成
3文の完成
3文の完成
3文の完成
2文の完成
2文の完成
文章整序
文章整序
文章整序
適語補充
適文補充
適文補充
適文補充
文章読解
文章読解
文章読解" transform="translate(-9256.644 -5180.158)" font-size="8.33" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="0">熟語の成り立ち</tspan><tspan x="0" y="12">熟語の成り立ち</tspan><tspan x="0" y="24">熟語の成り立ち</tspan><tspan x="0" y="36">熟語の成り立ち</tspan><tspan x="0" y="48">熟語の成り立ち</tspan><tspan x="0" y="60">熟語の成り立ち</tspan><tspan x="0" y="72">熟語の成り立ち</tspan><tspan x="0" y="84">熟語の成り立ち</tspan><tspan x="0" y="96">熟語の成り立ち</tspan><tspan x="0" y="108">熟語の成り立ち</tspan><tspan x="0" y="120">3文の完成</tspan><tspan x="0" y="132">3文の完成</tspan><tspan x="0" y="144">3文の完成</tspan><tspan x="0" y="156">3文の完成</tspan><tspan x="0" y="168">3文の完成</tspan><tspan x="0" y="180">3文の完成</tspan><tspan x="0" y="192">2文の完成</tspan><tspan x="0" y="204">2文の完成</tspan><tspan x="0" y="216">文章整序</tspan><tspan x="0" y="228">文章整序</tspan><tspan x="0" y="240">文章整序</tspan><tspan x="0" y="252">適語補充</tspan><tspan x="0" y="264">適文補充</tspan><tspan x="0" y="276">適文補充</tspan><tspan x="0" y="288">適文補充</tspan><tspan x="0" y="300">文章読解</tspan><tspan x="0" y="312">文章読解</tspan><tspan x="0" y="324">文章読解</tspan></text>
    <g id="グループ_1874" data-name="グループ 1874" transform="translate(-9891.447 -5646.552)">
      <text id="_1-2" data-name="1" transform="translate(737 625.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-3" data-name="1" transform="translate(737 637.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-4" data-name="1" transform="translate(737 649.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-5" data-name="1" transform="translate(737 661.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-6" data-name="1" transform="translate(737 673.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-7" data-name="1" transform="translate(737 685.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-8" data-name="1" transform="translate(737 698.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-9" data-name="1" transform="translate(737 709.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-10" data-name="1" transform="translate(737 721.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-11" data-name="1" transform="translate(737 733.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-12" data-name="1" transform="translate(737 745.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-13" data-name="1" transform="translate(737 757.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_2-2" data-name="2" transform="translate(737 769.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-3" data-name="2" transform="translate(737 781.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_1-14" data-name="1" transform="translate(737 613.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-15" data-name="1" transform="translate(737 601.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-16" data-name="1" transform="translate(737 589.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-17" data-name="1" transform="translate(737 577.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-18" data-name="1" transform="translate(737 565.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-19" data-name="1" transform="translate(737 553.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-20" data-name="1" transform="translate(737 541.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-21" data-name="1" transform="translate(737 529.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-22" data-name="1" transform="translate(737 517.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-23" data-name="1" transform="translate(737 505.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-24" data-name="1" transform="translate(737 493.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-25" data-name="1" transform="translate(737 481.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-26" data-name="1" transform="translate(737 469.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-27" data-name="1" transform="translate(737 457.894)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
    </g>
    <g id="グループ_1875" data-name="グループ 1875" transform="translate(-657 -546.049)">
      <text id="〇"                   transform="translate(-8479.447 -4474.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][14]['正誤マーク'] }}</tspan></text>
      <text id="〇-2"  data-name="〇" transform="translate(-8479.447 -4462.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][15]['正誤マーク'] }}</tspan></text>
      <text id="_"     data-name="×" transform="translate(-8479.447 -4450.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][16]['正誤マーク'] }}</tspan></text>
      <text id="〇-3"  data-name="〇" transform="translate(-8479.447 -4438.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][17]['正誤マーク'] }}</tspan></text>
      <text id="〇-4"  data-name="〇" transform="translate(-8479.447 -4426.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][18]['正誤マーク'] }}</tspan></text>
      <text id="〇-5"  data-name="〇" transform="translate(-8479.447 -4414.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][19]['正誤マーク'] }}</tspan></text>
      <text id="〇-6"  data-name="〇" transform="translate(-8479.447 -4402.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][20]['正誤マーク'] }}</tspan></text>
      <text id="〇-7"  data-name="〇" transform="translate(-8479.447 -4390.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][21]['正誤マーク'] }}</tspan></text>
      <text id="_2-4"  data-name="×" transform="translate(-8479.447 -4378.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][22]['正誤マーク'] }}</tspan></text>
      <text id="_3-2"  data-name="×" transform="translate(-8479.447 -4366.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][23]['正誤マーク'] }}</tspan></text>
      <text id="_4-2"  data-name="×" transform="translate(-8479.447 -4354.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][24]['正誤マーク'] }}</tspan></text>
      <text id="_5-2"  data-name="×" transform="translate(-8479.447 -4342.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][25]['正誤マーク'] }}</tspan></text>
      <text id="_6-2"  data-name="×" transform="translate(-8479.447 -4330.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][26]['正誤マーク'] }}</tspan></text>
      <text id="_7-2"  data-name="×" transform="translate(-8479.447 -4318.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][27]['正誤マーク'] }}</tspan></text>
      <text id="〇-8"  data-name="〇" transform="translate(-8479.447 -4486.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][13]['正誤マーク'] }}</tspan></text>
      <text id="_8-2"  data-name="×" transform="translate(-8479.447 -4498.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][12]['正誤マーク'] }}</tspan></text>
      <text id="〇-9"  data-name="〇" transform="translate(-8479.447 -4510.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][11]['正誤マーク'] }}</tspan></text>
      <text id="〇-10" data-name="〇" transform="translate(-8479.447 -4522.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][10]['正誤マーク'] }}</tspan></text>
      <text id="_9-2"  data-name="×" transform="translate(-8479.447 -4534.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][9]['正誤マーク'] }}</tspan></text>
      <text id="_10-2" data-name="×" transform="translate(-8479.447 -4546.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][8]['正誤マーク'] }}</tspan></text>
      <text id="_11-2" data-name="×" transform="translate(-8479.447 -4558.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][7]['正誤マーク'] }}</tspan></text>
      <text id="〇-11" data-name="〇" transform="translate(-8479.447 -4570.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][6]['正誤マーク'] }}</tspan></text>
      <text id="〇-12" data-name="〇" transform="translate(-8479.447 -4582.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][5]['正誤マーク'] }}</tspan></text>
      <text id="〇-13" data-name="〇" transform="translate(-8479.447 -4594.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][4]['正誤マーク'] }}</tspan></text>
      <text id="〇-14" data-name="〇" transform="translate(-8479.447 -4606.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][3]['正誤マーク'] }}</tspan></text>
      <text id="〇-15" data-name="〇" transform="translate(-8479.447 -4618.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][2]['正誤マーク'] }}</tspan></text>
      <text id="_12-2" data-name="×" transform="translate(-8479.447 -4630.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][1]['正誤マーク'] }}</tspan></text>
      <text id="〇-16" data-name="〇" transform="translate(-8479.447 -4642.109)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="6.45" y="8">{{ section31DataSet['rows'][0]['正誤マーク'] }}</tspan></text>
    </g>
    <g id="グループ_1876" data-name="グループ 1876" transform="translate(-655 -546.497)">
      <text id="_10-3" data-name="10" transform="translate(-8455.447 -4474.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][14]['正答率'] }}</tspan></text>
      <text id="_56"   data-name="56" transform="translate(-8455.447 -4462.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][15]['正答率'] }}</tspan></text>
      <text id="_41"   data-name="41" transform="translate(-8455.447 -4450.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][16]['正答率'] }}</tspan></text>
      <text id="_22-2" data-name="22" transform="translate(-8455.447 -4438.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][17]['正答率'] }}</tspan></text>
      <text id="_83"   data-name="83" transform="translate(-8455.447 -4426.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][18]['正答率'] }}</tspan></text>
      <text id="_45"   data-name="45" transform="translate(-8455.447 -4414.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][19]['正答率'] }}</tspan></text>
      <text id="_92"   data-name="92" transform="translate(-8455.447 -4402.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][20]['正答率'] }}</tspan></text>
      <text id="_13-2" data-name="13" transform="translate(-8455.447 -4390.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][21]['正答率'] }}</tspan></text>
      <text id="_33"   data-name="33" transform="translate(-8455.447 -4378.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][22]['正答率'] }}</tspan></text>
      <text id="_23-2" data-name="23" transform="translate(-8455.447 -4366.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][23]['正答率'] }}</tspan></text>
      <text id="_23-3" data-name="23" transform="translate(-8455.447 -4354.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][24]['正答率'] }}</tspan></text>
      <text id="_23-4" data-name="23" transform="translate(-8455.447 -4342.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][25]['正答率'] }}</tspan></text>
      <text id="_23-5" data-name="23" transform="translate(-8455.447 -4330.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][26]['正答率'] }}</tspan></text>
      <text id="_23-6" data-name="23" transform="translate(-8455.447 -4318.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][27]['正答率'] }}</tspan></text>
      <text id="_22-3" data-name="22" transform="translate(-8455.447 -4486.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][13]['正答率'] }}</tspan></text>
      <text id="_77"   data-name="77" transform="translate(-8455.447 -4498.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][12]['正答率'] }}</tspan></text>
      <text id="_36"   data-name="36" transform="translate(-8455.447 -4510.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][11]['正答率'] }}</tspan></text>
      <text id="_44"   data-name="44" transform="translate(-8455.447 -4522.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][10]['正答率'] }}</tspan></text>
      <text id="_54"   data-name="54" transform="translate(-8455.447 -4534.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][9]['正答率'] }}</tspan></text>
      <text id="_72"   data-name="72" transform="translate(-8455.447 -4546.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][8]['正答率'] }}</tspan></text>
      <text id="_33-2" data-name="33" transform="translate(-8455.447 -4558.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][7]['正答率'] }}</tspan></text>
      <text id="_13-3" data-name="13" transform="translate(-8455.447 -4570.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][6]['正答率'] }}</tspan></text>
      <text id="_92-2" data-name="92" transform="translate(-8455.447 -4582.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][5]['正答率'] }}</tspan></text>
      <text id="_45-2" data-name="45" transform="translate(-8455.447 -4594.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][4]['正答率'] }}</tspan></text>
      <text id="_83-2" data-name="83" transform="translate(-8455.447 -4606.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][3]['正答率'] }}</tspan></text>
      <text id="_22-4" data-name="22" transform="translate(-8455.447 -4618.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][2]['正答率'] }}</tspan></text>
      <text id="_41-2" data-name="41" transform="translate(-8455.447 -4630.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][1]['正答率'] }}</tspan></text>
      <text id="_56-2" data-name="56" transform="translate(-8455.447 -4642.109)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="7.995" y="8">{{ section31DataSet['rows'][0]['正答率'] }}</tspan></text>
    </g>
    <g id="グループ_1877" data-name="グループ 1877" transform="translate(-644 -547.049)">
      <g id="グループ_1765" data-name="グループ 1765" transform="translate(1074.553 931.891)">
        <rect id="長方形_422" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1878" data-name="グループ 1878" transform="translate(1074.553 943.891)">
        <rect id="長方形_422-2" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-2" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1879" data-name="グループ 1879" transform="translate(1074.553 955.891)">
        <rect id="長方形_422-3" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-3" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1880" data-name="グループ 1880" transform="translate(1074.553 967.891)">
        <rect id="長方形_422-4" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-4" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1881" data-name="グループ 1881" transform="translate(1074.553 979.891)">
        <rect id="長方形_422-5" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-5" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1882" data-name="グループ 1882" transform="translate(1074.553 991.891)">
        <rect id="長方形_422-6" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-6" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1883" data-name="グループ 1883" transform="translate(1074.553 1003.891)">
        <rect id="長方形_422-7" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-7" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1884" data-name="グループ 1884" transform="translate(1074.553 1015.891)">
        <rect id="長方形_422-8" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-8" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1885" data-name="グループ 1885" transform="translate(1074.553 1027.891)">
        <rect id="長方形_422-9" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-9" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1886" data-name="グループ 1886" transform="translate(1074.553 1039.891)">
        <rect id="長方形_422-10" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-10" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1887" data-name="グループ 1887" transform="translate(1074.553 1051.891)">
        <rect id="長方形_422-11" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-11" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1888" data-name="グループ 1888" transform="translate(1074.553 1063.891)">
        <rect id="長方形_422-12" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-12" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1889" data-name="グループ 1889" transform="translate(1074.553 1075.891)">
        <rect id="長方形_422-13" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-13" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1890" data-name="グループ 1890" transform="translate(1074.553 1087.891)">
        <rect id="長方形_422-14" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-14" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1766" data-name="グループ 1766" transform="translate(1074.553 919.891)">
        <rect id="長方形_422-15" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-15" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1770" data-name="グループ 1770" transform="translate(1074.553 907.891)">
        <rect id="長方形_422-16" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-16" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1771" data-name="グループ 1771" transform="translate(1074.553 895.891)">
        <rect id="長方形_422-17" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-17" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1772" data-name="グループ 1772" transform="translate(1074.553 883.891)">
        <rect id="長方形_422-18" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
        <text id="文-18" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
      </g>
      <g id="グループ_1773" data-name="グループ 1773" transform="translate(1074.553 871.891)">
        <path id="パス_241" data-name="パス 241" d="M0,0H9V9H0Z" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1774" data-name="グループ 1774" transform="translate(1074.553 859.891)">
        <rect id="長方形_422-19" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-2" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1775" data-name="グループ 1775" transform="translate(1074.553 847.891)">
        <rect id="長方形_422-20" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-3" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1776" data-name="グループ 1776" transform="translate(1074.553 835.891)">
        <rect id="長方形_422-21" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-4" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1777" data-name="グループ 1777" transform="translate(1074.553 823.891)">
        <rect id="長方形_422-22" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-5" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1778" data-name="グループ 1778" transform="translate(1074.553 811.891)">
        <rect id="長方形_422-23" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-6" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1779" data-name="グループ 1779" transform="translate(1074.553 799.891)">
        <rect id="長方形_422-24" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-7" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1780" data-name="グループ 1780" transform="translate(1074.553 787.891)">
        <rect id="長方形_422-25" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-8" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1781" data-name="グループ 1781" transform="translate(1074.553 775.891)">
        <rect id="長方形_422-26" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-9" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
      <g id="グループ_1782" data-name="グループ 1782" transform="translate(1074.553 763.891)">
        <rect id="長方形_422-27" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
        <text id="語-10" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
      </g>
    </g>
  </g>
  <g id="グループ_1906" data-name="グループ 1906" transform="translate(9282.667 5501.562)">
    <g id="グループ_1850-2" data-name="グループ 1850" transform="translate(-7979.526 -360.955)">
      <g id="グループ_1849-2" data-name="グループ 1849" transform="translate(0 11)">
        <g id="グループ_1847-2" data-name="グループ 1847" transform="translate(-236 202)">
          <g id="グループ_1846-2" data-name="グループ 1846">
            <g id="グループ_1845-2" data-name="グループ 1845" transform="translate(26 -7)">
              <rect id="長方形_424-28" data-name="長方形 424" width="210" height="13" transform="translate(-807 -5006)" fill="#fff"/>
              <rect id="長方形_424-29" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4994)" fill="#f3f3f3"/>
              <rect id="長方形_424-30" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4982)" fill="#fff"/>
              <rect id="長方形_424-31" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4970)" fill="#f3f3f3"/>
              <rect id="長方形_424-32" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4958)" fill="#fff"/>
              <rect id="長方形_424-33" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4946)" fill="#f3f3f3"/>
              <rect id="長方形_424-34" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4934)" fill="#fff"/>
              <rect id="長方形_424-35" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4922)" fill="#f3f3f3"/>
              <rect id="長方形_424-36" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4910)" fill="#fff"/>
              <rect id="長方形_424-37" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4898)" fill="#f3f3f3"/>
              <rect id="長方形_424-38" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4886)" fill="#fff"/>
              <rect id="長方形_424-39" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4874)" fill="#f3f3f3"/>
              <rect id="長方形_424-40" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4862)" fill="#fff"/>
              <rect id="長方形_424-41" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4850)" fill="#f3f3f3"/>
              <rect id="長方形_424-42" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4838)" fill="#fff"/>
              <rect id="長方形_424-43" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4826)" fill="#f3f3f3"/>
              <rect id="長方形_424-44" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4814)" fill="#fff"/>
              <rect id="長方形_424-45" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4802)" fill="#f3f3f3"/>
              <rect id="長方形_424-46" data-name="長方形 424" width="210" height="13" transform="translate(-807 -4790)" fill="#fff"/>
            </g>
            <line id="線_558-2" data-name="線 558" x2="209" transform="translate(-781 -5000.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_587-2" data-name="線 587" x2="209" transform="translate(-780.5 -5013)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_559-2" data-name="線 559" x2="209" transform="translate(-781 -4988.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_560-2" data-name="線 560" x2="209" transform="translate(-781 -4976.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_561-2" data-name="線 561" x2="209" transform="translate(-780.5 -4964.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_562-2" data-name="線 562" x2="209" transform="translate(-780.5 -4952.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_563-2" data-name="線 563" x2="209" transform="translate(-780.5 -4940.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_564-2" data-name="線 564" x2="209" transform="translate(-780.5 -4928.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_565-2" data-name="線 565" x2="209" transform="translate(-781 -4916.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_566-2" data-name="線 566" x2="209" transform="translate(-781 -4904.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_567-2" data-name="線 567" x2="209" transform="translate(-781 -4892.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_568-2" data-name="線 568" x2="209" transform="translate(-780.5 -4880.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_569-2" data-name="線 569" x2="209" transform="translate(-781 -4868.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_570-2" data-name="線 570" x2="209" transform="translate(-780.5 -4856.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_571-2" data-name="線 571" x2="209" transform="translate(-781 -4844.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_572-2" data-name="線 572" x2="209" transform="translate(-781 -4832.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_573-2" data-name="線 573" x2="209" transform="translate(-781 -4820.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_574-2" data-name="線 574" x2="209" transform="translate(-781 -4808.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_575-2" data-name="線 575" x2="209" transform="translate(-781 -4796.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <line id="線_576-2" data-name="線 576" x2="209" transform="translate(-781 -4784.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          </g>
        </g>
        <g id="グループ_1848-2" data-name="グループ 1848">
          <rect id="長方形_80-16" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4808.607)" fill="#28759d"/>
          <text id="_2-5" data-name="2" transform="translate(-1007 -4809.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">2</tspan></text>
          <rect id="長方形_80-17" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4820.5)" fill="#28759d"/>
          <text id="_1-28" data-name="1" transform="translate(-1007 -4821)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">1</tspan></text>
          <rect id="長方形_80-18" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4796.607)" fill="#28759d"/>
          <text id="_3-3" data-name="3" transform="translate(-1007 -4797.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">3</tspan></text>
          <rect id="長方形_80-19" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4784.607)" fill="#28759d"/>
          <text id="_4-3" data-name="4" transform="translate(-1007 -4785.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">4</tspan></text>
          <rect id="長方形_80-20" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4772.607)" fill="#28759d"/>
          <text id="_5-3" data-name="5" transform="translate(-1007 -4773.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">5</tspan></text>
          <rect id="長方形_80-21" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4760.607)" fill="#28759d"/>
          <text id="_6-3" data-name="6" transform="translate(-1007 -4761.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">6</tspan></text>
          <rect id="長方形_80-22" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4748.607)" fill="#28759d"/>
          <text id="_7-3" data-name="7" transform="translate(-1007 -4749.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">7</tspan></text>
          <rect id="長方形_80-23" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4736.607)" fill="#28759d"/>
          <text id="_8-3" data-name="8" transform="translate(-1007 -4737.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">8</tspan></text>
          <rect id="長方形_80-24" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4724.607)" fill="#28759d"/>
          <text id="_9-3" data-name="9" transform="translate(-1007 -4725.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">9</tspan></text>
          <rect id="長方形_80-25" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4700.607)" fill="#28759d"/>
          <text id="_11-3" data-name="11" transform="translate(-1009 -4701.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">11</tspan></text>
          <rect id="長方形_80-26" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4712.607)" fill="#28759d"/>
          <text id="_10-4" data-name="10" transform="translate(-1009 -4713.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">10</tspan></text>
          <rect id="長方形_80-27" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4688.607)" fill="#28759d"/>
          <text id="_12-3" data-name="12" transform="translate(-1009 -4689.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">12</tspan></text>
          <rect id="長方形_80-28" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4676.607)" fill="#28759d"/>
          <text id="_13-4" data-name="13" transform="translate(-1009 -4677.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">13</tspan></text>
          <rect id="長方形_80-29" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4664.607)" fill="#28759d"/>
          <text id="_14-2" data-name="14" transform="translate(-1009 -4665.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">14</tspan></text>
          <rect id="長方形_80-30" data-name="長方形 80" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4652.607)" fill="#28759d"/>
          <rect id="長方形_425-2" data-name="長方形 425" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4640.607)" fill="#28759d"/>
          <rect id="長方形_426-2" data-name="長方形 426" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4628.607)" fill="#28759d"/>
          <rect id="長方形_427-2" data-name="長方形 427" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4616.607)" fill="#28759d"/>
          <rect id="長方形_428-2" data-name="長方形 428" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4604.607)" fill="#28759d"/>
          <rect id="長方形_429-2" data-name="長方形 429" width="18.64" height="7.893" rx="3.946" transform="translate(-1014.32 -4592.607)" fill="#28759d"/>
          <text id="_15-2" data-name="15" transform="translate(-1009 -4653.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">15</tspan></text>
          <text id="_16-2" data-name="16" transform="translate(-1009 -4641.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">16</tspan></text>
          <text id="_17-2" data-name="17" transform="translate(-1009 -4629.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">17</tspan></text>
          <text id="_18-2" data-name="18" transform="translate(-1009 -4617.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">18</tspan></text>
          <text id="_19-2" data-name="19" transform="translate(-1009 -4605.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">19</tspan></text>
          <text id="_20-2" data-name="20" transform="translate(-1009 -4593.107)" fill="#fff" font-size="6.86" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0" y="7">20</tspan></text>
        </g>
      </g>
      <line id="線_585-2" data-name="線 585" x2="210.5" y2="0.106" transform="translate(-1017 -4814.606)" fill="none" stroke="#000" stroke-width="1"/>
    </g>
    <g id="グループ_1852-2" data-name="グループ 1852" transform="translate(-7979.847 -154.455)">
      <text id="番号-2" data-name="番号" transform="translate(-1013 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">番号</tspan></text>
      <text id="出題項目-2" data-name="出題項目" transform="translate(-989 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">出題項目</tspan></text>
      <text id="配点-2" data-name="配点" transform="translate(-896 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">配点</tspan></text>
      <text id="正答率-2" data-name="正答率" transform="translate(-848 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正答率</tspan></text>
      <text id="正誤-2" data-name="正誤" transform="translate(-872 -5032.106)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="0" y="8">正誤</tspan></text>
    </g>
    <text id="割合の計算_金銭の貸借_方程式_整数問題_整数問題_推論_順序_損益算_速さ_推論_数量_推論_数量_推論_数量_推論_順序_場合の数_確率_確率_表の読み取り_表の読み取り_集合_推論_位置_推論_位置_" data-name="割合の計算
金銭の貸借
方程式
整数問題
整数問題
推論（順序）
損益算
速さ
推論（数量）
推論（数量）
推論（数量）
推論（順序）
場合の数
確率
確率
表の読み取り
表の読み取り
集合
推論（位置）
推論（位置）" transform="translate(-8970.418 -5163.507)" font-size="8.33" font-family="Meiryo" letter-spacing="0.05em"><tspan x="0" y="0">割合の計算</tspan><tspan x="0" y="12">金銭の貸借</tspan><tspan x="0" y="24">方程式</tspan><tspan x="0" y="36">整数問題</tspan><tspan x="0" y="48">整数問題</tspan><tspan x="0" y="60">推論（順序）</tspan><tspan x="0" y="72">損益算</tspan><tspan x="0" y="84">速さ</tspan><tspan x="0" y="96">推論（数量）</tspan><tspan x="0" y="108">推論（数量）</tspan><tspan x="0" y="120">推論（数量）</tspan><tspan x="0" y="132">推論（順序）</tspan><tspan x="0" y="144">場合の数</tspan><tspan x="0" y="156">確率</tspan><tspan x="0" y="168">確率</tspan><tspan x="0" y="180">表の読み取り</tspan><tspan x="0" y="192">表の読み取り</tspan><tspan x="0" y="204">集合</tspan><tspan x="0" y="216">推論（位置）</tspan><tspan x="0" y="228">推論（位置）</tspan></text>
    <g id="グループ_1897" data-name="グループ 1897" transform="translate(-10)">
      <g id="グループ_1765-2" data-name="グループ 1765" transform="translate(716.778 401.493)">
        <rect id="長方形_422-28" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1878-2" data-name="グループ 1878" transform="translate(716.778 413.493)">
        <rect id="長方形_422-29" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-2" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1879-2" data-name="グループ 1879" transform="translate(716.778 425.493)">
        <rect id="長方形_422-30" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-3" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1880-2" data-name="グループ 1880" transform="translate(716.778 437.493)">
        <rect id="長方形_422-31" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-4" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1881-2" data-name="グループ 1881" transform="translate(716.778 449.493)">
        <rect id="長方形_422-32" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1882-2" data-name="グループ 1882" transform="translate(716.778 461.493)">
        <rect id="長方形_422-33" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-2" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1766-2" data-name="グループ 1766" transform="translate(716.778 389.493)">
        <rect id="長方形_422-34" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-5" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1770-2" data-name="グループ 1770" transform="translate(716.778 377.493)">
        <rect id="長方形_422-35" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-6" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1771-2" data-name="グループ 1771" transform="translate(716.778 365.493)">
        <rect id="長方形_422-36" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-3" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1772-2" data-name="グループ 1772" transform="translate(716.778 353.493)">
        <rect id="長方形_422-37" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-7" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1905" data-name="グループ 1905" transform="translate(726.778 353.493)">
        <rect id="長方形_422-38" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-4" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1773-2" data-name="グループ 1773" transform="translate(716.778 341.493)">
        <path id="パス_241-2" data-name="パス 241" d="M0,0H9V9H0Z" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-8" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1904" data-name="グループ 1904" transform="translate(726.778 341.493)">
        <path id="パス_241-3" data-name="パス 241" d="M0,0H9V9H0Z" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-5" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1774-2" data-name="グループ 1774" transform="translate(716.778 329.493)">
        <rect id="長方形_422-39" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-9" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1903" data-name="グループ 1903" transform="translate(726.778 329.493)">
        <rect id="長方形_422-40" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-6" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1775-2" data-name="グループ 1775" transform="translate(716.778 317.493)">
        <rect id="長方形_422-41" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-10" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1776-2" data-name="グループ 1776" transform="translate(716.778 305.493)">
        <rect id="長方形_422-42" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-11" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1777-2" data-name="グループ 1777" transform="translate(716.778 293.493)">
        <rect id="長方形_422-43" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-7" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1778-2" data-name="グループ 1778" transform="translate(716.778 281.493)">
        <rect id="長方形_422-44" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-12" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1902" data-name="グループ 1902" transform="translate(726.778 281.493)">
        <rect id="長方形_422-45" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-8" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1779-2" data-name="グループ 1779" transform="translate(716.778 269.493)">
        <rect id="長方形_422-46" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-13" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1901" data-name="グループ 1901" transform="translate(726.778 269.493)">
        <rect id="長方形_422-47" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
        <text id="論-9" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
      </g>
      <g id="グループ_1780-2" data-name="グループ 1780" transform="translate(716.778 257.493)">
        <rect id="長方形_422-48" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-14" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1781-2" data-name="グループ 1781" transform="translate(716.778 245.493)">
        <rect id="長方形_422-49" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-15" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
      <g id="グループ_1782-2" data-name="グループ 1782" transform="translate(716.778 233.493)">
        <rect id="長方形_422-50" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
        <text id="数-16" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
      </g>
    </g>
    <g id="グループ_1898" data-name="グループ 1898">
      <text id="_1-29" data-name="1" transform="translate(-8868.222 -5004.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-30" data-name="1" transform="translate(-8868.222 -4992.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_2-6" data-name="2" transform="translate(-8868.222 -4980.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_1-31" data-name="1" transform="translate(-8868.222 -4968.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_2-7" data-name="2" transform="translate(-8868.222 -4956.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-8" data-name="2" transform="translate(-8868.222 -4944.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_1-32" data-name="1" transform="translate(-8868.222 -5016.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-33" data-name="1" transform="translate(-8868.222 -5028.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_2-9" data-name="2" transform="translate(-8868.222 -5040.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-10" data-name="2" transform="translate(-8868.222 -5052.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-11" data-name="2" transform="translate(-8868.222 -5064.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-12" data-name="2" transform="translate(-8868.222 -5076.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_1-34" data-name="1" transform="translate(-8868.222 -5088.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-35" data-name="1" transform="translate(-8868.222 -5100.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_2-13" data-name="2" transform="translate(-8868.222 -5112.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-14" data-name="2" transform="translate(-8868.222 -5124.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_2-15" data-name="2" transform="translate(-8868.222 -5136.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">2</tspan></text>
      <text id="_1-36" data-name="1" transform="translate(-8868.222 -5148.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-37" data-name="1" transform="translate(-8868.222 -5160.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
      <text id="_1-38" data-name="1" transform="translate(-8868.222 -5172.007)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em"><tspan x="0.086" y="8">1</tspan></text>
    </g>
    <g id="グループ_1899" data-name="グループ 1899" transform="translate(4 -0.5)">
      <text id="_13-5" data-name="×" transform="translate(-8850.222 -5003.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][14]['正誤マーク'] }}</tspan></text>
      <text id="〇-17" data-name="〇" transform="translate(-8850.222 -4991.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][15]['正誤マーク'] }}</tspan></text>
      <text id="〇-18" data-name="〇" transform="translate(-8850.222 -4979.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][16]['正誤マーク'] }}</tspan></text>
      <text id="_14-3" data-name="×" transform="translate(-8850.222 -4967.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][17]['正誤マーク'] }}</tspan></text>
      <text id="〇-19" data-name="〇" transform="translate(-8850.222 -4955.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][18]['正誤マーク'] }}</tspan></text>
      <text id="〇-20" data-name="〇" transform="translate(-8850.222 -4943.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][19]['正誤マーク'] }}</tspan></text>
      <text id="〇-21" data-name="〇" transform="translate(-8850.222 -5015.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][13]['正誤マーク'] }}</tspan></text>
      <text id="〇-22" data-name="〇" transform="translate(-8850.222 -5027.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][12]['正誤マーク'] }}</tspan></text>
      <text id="〇-23" data-name="〇" transform="translate(-8850.222 -5039.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][11]['正誤マーク'] }}</tspan></text>
      <text id="_15-3" data-name="×" transform="translate(-8850.222 -5051.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][10]['正誤マーク'] }}</tspan></text>
      <text id="N"                    transform="translate(-8850.222 -5063.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][9]['正誤マーク'] }}</tspan></text>
      <text id="〇-24" data-name="〇" transform="translate(-8850.222 -5075.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][8]['正誤マーク'] }}</tspan></text>
      <text id="_16-3" data-name="×" transform="translate(-8850.222 -5087.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][7]['正誤マーク'] }}</tspan></text>
      <text id="〇-25" data-name="〇" transform="translate(-8850.222 -5099.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][6]['正誤マーク'] }}</tspan></text>
      <text id="〇-26" data-name="〇" transform="translate(-8850.222 -5111.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][5]['正誤マーク'] }}</tspan></text>
      <text id="_17-3" data-name="×" transform="translate(-8850.222 -5123.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][4]['正誤マーク'] }}</tspan></text>
      <text id="〇-27" data-name="〇" transform="translate(-8850.222 -5135.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][3]['正誤マーク'] }}</tspan></text>
      <text id="〇-28" data-name="〇" transform="translate(-8850.222 -5147.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][2]['正誤マーク'] }}</tspan></text>
      <text id="〇-29" data-name="〇" transform="translate(-8850.222 -5159.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][1]['正誤マーク'] }}</tspan></text>
      <text id="_18-3" data-name="×" transform="translate(-8850.222 -5171.507)" font-size="7.1" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="2.45" y="8">{{ section32DataSet['rows'][0]['正誤マーク'] }}</tspan></text>
    </g>
    <g id="グループ_1900" data-name="グループ 1900" transform="translate(2.196 -0.052)">
      <text id="_92-3" data-name="92" transform="translate(-8824.222 -5003.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][14]['正答率'] }}</tspan></text>
      <text id="_13-6" data-name="13" transform="translate(-8824.222 -4991.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][15]['正答率'] }}</tspan></text>
      <text id="_33-3" data-name="33" transform="translate(-8824.222 -4979.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][16]['正答率'] }}</tspan></text>
      <text id="_72-2" data-name="72" transform="translate(-8824.222 -4967.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][17]['正答率'] }}</tspan></text>
      <text id="_50"   data-name="50" transform="translate(-8824.222 -4955.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][18]['正答率'] }}</tspan></text>
      <text id="_49"   data-name="49" transform="translate(-8824.222 -4943.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][19]['正答率'] }}</tspan></text>
      <text id="_45-3" data-name="45" transform="translate(-8824.222 -5015.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][13]['正答率'] }}</tspan></text>
      <text id="_83-3" data-name="83" transform="translate(-8824.222 -5027.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][12]['正答率'] }}</tspan></text>
      <text id="_82"   data-name="82" transform="translate(-8824.222 -5039.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][11]['正答率'] }}</tspan></text>
      <text id="_34"   data-name="34" transform="translate(-8824.222 -5051.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][10]['正答率'] }}</tspan></text>
      <text id="_49-2" data-name="49" transform="translate(-8824.222 -5063.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][9]['正答率'] }}</tspan></text>
      <text id="_50-2" data-name="50" transform="translate(-8824.222 -5075.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][8]['正答率'] }}</tspan></text>
      <text id="_72-3" data-name="72" transform="translate(-8824.222 -5087.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][7]['正答率'] }}</tspan></text>
      <text id="_33-4" data-name="33" transform="translate(-8824.222 -5099.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][6]['正答率'] }}</tspan></text>
      <text id="_13-7" data-name="13" transform="translate(-8824.222 -5111.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][5]['正答率'] }}</tspan></text>
      <text id="_92-4" data-name="92" transform="translate(-8824.222 -5123.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][4]['正答率'] }}</tspan></text>
      <text id="_45-4" data-name="45" transform="translate(-8824.222 -5135.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][3]['正答率'] }}</tspan></text>
      <text id="_83-4" data-name="83" transform="translate(-8824.222 -5147.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][2]['正答率'] }}</tspan></text>
      <text id="_82-2" data-name="82" transform="translate(-8824.222 -5159.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][1]['正答率'] }}</tspan></text>
      <text id="_34-2" data-name="34" transform="translate(-8824.222 -5171.955)" font-size="7.1" font-family="SegoeUI, Segoe UI" letter-spacing="0.05em" text-anchor="middle"><tspan x="5.995" y="8">{{ section32DataSet['rows'][0]['正答率'] }}</tspan></text>
    </g>
  </g>
  <g id="グループ_1913" data-name="グループ 1913" transform="translate(-37.976 -124.109)">
    <g id="グループ_1909" data-name="グループ 1909" transform="translate(9264.627 6136)">
      <g id="グループ_1908" data-name="グループ 1908">
        <text id="_正答率は_全国を基準としています_" data-name="  ★正答率は、全国を基準としています。" transform="translate(-8947.26 -5417.836)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-family="SegoeUISymbol, Segoe UI Symbol">★</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">正答率は、全国を基準としています。</tspan></text>
        <text id="_正誤判定のNは無選択の場合です_" data-name="  ★正誤判定のNは無選択の場合です。" transform="translate(-8947.26 -5427.836)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-family="SegoeUISymbol, Segoe UI Symbol">★</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">正誤判定の</tspan><tspan y="0">N</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">は無選択の場合です。</tspan></text>
      </g>
      <g id="グループ_1907" data-name="グループ 1907">
        <text id="_1点ずつ配点します_" data-name="  1点ずつ配点します。" transform="translate(-8940.26 -5395.836)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  1</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">点ずつ配点します。</tspan></text>
        <text id="_非言語問題4_5_9_10_11問目に正解の場合は_数的能力と論理にそれぞれ" data-name="  ★非言語問題4,5,9,10,11問目に正解の場合は、数的能力と論理にそれぞれ" transform="translate(-8947.26 -5407.836)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-family="SegoeUISymbol, Segoe UI Symbol">★</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">非言語問題</tspan><tspan y="0">4,5,9,10,11</tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">問目に正解の場合は、数的能力と論理にそれぞれ</tspan></text>
      </g>
    </g>
    <g id="グループ_1910" data-name="グループ 1910" transform="translate(9049 6201)">
      <g id="グループ_1830" data-name="グループ 1830" transform="translate(1159.445 -268.836)">
        <g id="グループ_1767" data-name="グループ 1767" transform="translate(-263 218)">
          <rect id="長方形_422-51" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
          <text id="語-11" data-name="語" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">語</tspan></text>
        </g>
        <g id="グループ_1680" data-name="グループ 1680" transform="translate(-10330.705 -5912)">
          <text id="_語いに配点" data-name="  語いに配点" transform="translate(470 732)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">語いに配点</tspan></text>
        </g>
      </g>
      <g id="グループ_1833" data-name="グループ 1833" transform="translate(1159.445 -302.836)">
        <g id="グループ_1827" data-name="グループ 1827" transform="translate(-263 264)">
          <rect id="長方形_422-52" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
          <text id="文-19" data-name="文" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">文</tspan></text>
        </g>
        <g id="グループ_1679" data-name="グループ 1679" transform="translate(-10330.705 -5875)">
          <text id="_文章読解に配点" data-name="  文章読解に配点" transform="translate(470 741)" font-size="6.5" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-size="7" font-family="YuGothicUI-Regular, Yu Gothic UI">文章読解に配点</tspan></text>
        </g>
      </g>
      <g id="グループ_1831" data-name="グループ 1831" transform="translate(1159.445 -262.836)">
        <g id="グループ_1828" data-name="グループ 1828" transform="translate(-263 236)">
          <rect id="長方形_422-53" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
          <text id="数-17" data-name="数" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">数</tspan></text>
        </g>
        <g id="グループ_1678" data-name="グループ 1678" transform="translate(-10330.705 -5913)">
          <text id="_数的能力に配点" data-name="  数的能力に配点" transform="translate(470 751)" font-size="6.5" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-size="7" font-family="YuGothicUI-Regular, Yu Gothic UI">数的能力に配点</tspan></text>
        </g>
      </g>
      <g id="グループ_1832" data-name="グループ 1832" transform="translate(1159.445 -265.836)">
        <g id="グループ_1829" data-name="グループ 1829" transform="translate(-263 251)">
          <rect id="長方形_422-54" data-name="長方形 422" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
          <text id="論-10" data-name="論" transform="translate(-9610 -5405)" fill="#fff" font-size="5" font-family="SourceHanSansJP-Bold, Source Han Sans JP" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="6">論</tspan></text>
        </g>
        <g id="グループ_1677" data-name="グループ 1677" transform="translate(-10330.705 -5910)">
          <text id="_論理に配点" data-name="  論理に配点" transform="translate(470 763)" font-size="7" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="0" y="0" xml:space="preserve">  </tspan><tspan y="0" font-family="YuGothicUI-Regular, Yu Gothic UI">論理に配点</tspan></text>
        </g>
      </g>
    </g>
  </g>
  <g id="グループ_1731" data-name="グループ 1731" transform="translate(4 -157.631)">
    <g id="グループ_1724" data-name="グループ 1724" transform="translate(10843.789 6138.728)">
      <rect id="長方形_101" data-name="長方形 101" width="221.113" height="172.228" rx="12" transform="translate(-10843.789 -5867.728)" fill="#daf9f3" opacity="0.37"/>
      <g id="グループ_1723" data-name="グループ 1723" transform="translate(-10810.843 -5842.607)">
        <text id="文章理解" transform="translate(63.609 7)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">文章理解</tspan></text>
        <text id="論理-2" data-name="論理" transform="translate(0 73.78)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">論理</tspan></text>
        <text id="語い-2" data-name="語い" transform="translate(141.22 73.78)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">語い</tspan></text>
        <text id="数的能力-2" data-name="数的能力" transform="translate(63.609 142.107)" font-size="7" font-family="Meiryo"><tspan x="0" y="0">数的能力</tspan></text>
        <g id="グループ_1722" data-name="グループ 1722" transform="translate(15.937 10)">
          <g id="パス_212" data-name="パス 212" transform="translate(61.604 0) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 86.86808013916016 86.86808013916016 L 1.30757999420166 85.81363677978516 L 0.2531321346759796 0.2531321346759796 L 85.81363677978516 1.30757999420166 L 86.86808013916016 86.86808013916016 Z" stroke="none"/>
            <path d="M 0.5062789916992188 0.5062789916992188 L 1.5545654296875 85.566650390625 L 86.61493682861328 86.61493682861328 L 85.566650390625 1.5545654296875 L 0.5062789916992188 0.5062789916992188 M 0 0 L 86.06060791015625 1.06060791015625 L 87.1212158203125 87.1212158203125 L 1.06060791015625 86.06060791015625 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_213" data-name="パス 213" transform="translate(61.604 7.071) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 76.86775970458984 76.86775970458984 L 1.307251334190369 75.81407165527344 L 0.2535659372806549 0.2535659372806549 L 75.81407165527344 1.307251334190369 L 76.86775970458984 76.86775970458984 Z" stroke="none"/>
            <path d="M 0.5071182250976562 0.5071182250976562 L 1.5538330078125 75.56748962402344 L 76.61420440673828 76.61420440673828 L 75.56748962402344 1.5538330078125 L 0.5071182250976562 0.5071182250976562 M 0 0 L 76.06066131591797 1.060661315917969 L 77.12132263183594 77.12132263183594 L 1.060661315917969 76.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_214" data-name="パス 214" transform="translate(61.604 14.142) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 66.86721038818359 66.86721038818359 L 1.306744575500488 65.8145751953125 L 0.2541157305240631 0.2541157305240631 L 65.8145751953125 1.306744575500488 L 66.86721038818359 66.86721038818359 Z" stroke="none"/>
            <path d="M 0.5082321166992188 0.5082321166992188 L 1.552825927734375 65.56849670410156 L 66.61309051513672 66.61309051513672 L 65.56849670410156 1.552825927734375 L 0.5082321166992188 0.5082321166992188 M 0 0 L 66.06066131591797 1.060661315917969 L 67.12132263183594 67.12132263183594 L 1.060661315917969 66.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_215" data-name="パス 215" transform="translate(61.604 21.213) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 56.86645126342773 56.86645126342773 L 1.306067705154419 55.81525421142578 L 0.2548729479312897 0.2548729479312897 L 55.81525421142578 1.306067705154419 L 56.86645126342773 56.86645126342773 Z" stroke="none"/>
            <path d="M 0.509735107421875 0.509735107421875 L 1.551464080810547 55.56985855102539 L 56.61158752441406 56.61158752441406 L 55.56985855102539 1.551464080810547 L 0.509735107421875 0.509735107421875 M 0 0 L 56.06066131591797 1.060661315917969 L 57.12132263183594 57.12132263183594 L 1.060661315917969 56.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="パス_216" data-name="パス 216" transform="translate(61.604 28.284) rotate(45)" fill="rgba(255,255,255,0)">
            <path d="M 46.86536407470703 46.86536407470703 L 1.30509340763092 45.81622695922852 L 0.2559560835361481 0.2559560835361481 L 45.81622695922852 1.30509340763092 L 46.86536407470703 46.86536407470703 Z" stroke="none"/>
            <path d="M 0.5119209289550781 0.5119209289550781 L 1.549537658691406 45.57178115844727 L 46.60939788818359 46.60939788818359 L 45.57178115844727 1.549537658691406 L 0.5119209289550781 0.5119209289550781 M 0 0 L 46.06066131591797 1.060657501220703 L 47.12131881713867 47.12131881713867 L 1.060657501220703 46.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
          </g>
          <g id="長方形_419" data-name="長方形 419" transform="translate(61.604 36.855) rotate(45)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="0.5">
            <rect width="35" height="35" stroke="none"/>
            <rect x="0.25" y="0.25" width="34.5" height="34.5" fill="none"/>
          </g>
          <text id="_80_70_60_50_40_30_20" data-name="_80_70_60_50_40_30_20" transform="translate(55.5 7.5)" font-size="6" font-family="SegoeUI, Segoe UI">
            <tspan x="-2.475" y="0">80</tspan>
            <tspan x="-2.475" y="7.5">70</tspan>
            <tspan x="-2.475" y="15.0">60</tspan>
            <tspan x="-2.475" y="22.5">50</tspan>
            <tspan x="-2.475" y="30.0">40</tspan>
            <tspan x="-2.475" y="37.5">30</tspan>
            <tspan x="-2.475" y="45.0">20</tspan>
          </text>
          <line id="線_555" data-name="線 555" x2="120" transform="translate(1.846 61.751)" fill="none" stroke="#000" stroke-width="0.5"/>
          <line id="線_556" data-name="線 556" y2="122" transform="translate(61.604 0.748)" fill="none" stroke="#000" stroke-width="0.5"/>
          <g id="長方形_420" data-name="長方形 420" transform="translate(61.604 43.864) rotate(45)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="0.5">
            <rect width="25" height="25" stroke="none"/>
            <rect x="0.25" y="0.25" width="24.5" height="24.5" fill="none"/>
          </g>
          <!-- レーダーチャート -->
          <!--
          <path id="_調整用_80" data-name="_調整用_80" :d="`M 0,-${calcRadarChartLen(80)} L ${calcRadarChartLen(80)},0 0,${calcRadarChartLen(80)} -${calcRadarChartLen(80)},0 0,-${calcRadarChartLen(80)}    0,${calcRadarChartLen(80)}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#00ff00" :stroke-width="`${radar_chart.line_width}`"/>
          <path id="_調整用_50" data-name="_調整用_50" :d="`M 0,-${calcRadarChartLen(50)} L ${calcRadarChartLen(50)},0 0,${calcRadarChartLen(50)} -${calcRadarChartLen(50)},0 0,-${calcRadarChartLen(50)}    0,0 ${calcRadarChartLen(50)},0`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#c70707" :stroke-width="`${radar_chart.line_width}`"/>
          <path id="_調整用_20" data-name="_調整用_20" :d="`M 0,-${calcRadarChartLen(20)} L ${calcRadarChartLen(20)},0 0,${calcRadarChartLen(20)} -${calcRadarChartLen(20)},0 0,-${calcRadarChartLen(20)}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#00ff00" :stroke-width="`${radar_chart.line_width}`"/>
          -->
          <path id="パス_218" data-name="パス_218" :d="`M 0,-${calcRadarChartLen(section2DataSet['rows'][0]['rows'][0]['全国偏差値_adj_20_80'])} L ${calcRadarChartLen(section2DataSet['rows'][0]['rows'][1]['全国偏差値_adj_20_80'])},0 0,${calcRadarChartLen(section2DataSet['rows'][1]['rows'][0]['全国偏差値_adj_20_80'])} -${calcRadarChartLen(section2DataSet['rows'][1]['rows'][1]['全国偏差値_adj_20_80'])},0 0,-${calcRadarChartLen(section2DataSet['rows'][0]['rows'][0]['全国偏差値_adj_20_80'])}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#c70707" :stroke-width="`${radar_chart.line_width}`"/>
        </g>
      </g>
      <path id="パス_55" data-name="パス 55" d="M7.3,0H180.318c4.029,0,7.3,1.9,7.3,4.252V16.074c0,2.348-3.266,4.252-7.3,4.252H7.3c-4.029,0-7.3-1.9-7.3-4.252V4.252C0,1.9,3.266,0,7.3,0Z" transform="translate(-10824.039 -5867.728)" fill="#28759d"/>
    </g>
    <text id="分野別偏差値チャート" transform="translate(120.466 285.272)" fill="#fff" stroke="#fff" stroke-width="0.4" font-size="11" font-family="YuGothicUI-Regular, Yu Gothic UI" letter-spacing="0.05em"><tspan x="-50.694" y="0">分野別偏差値チャート</tspan></text>
  </g>
</svg>

    </div><!-- report_body -->
    <div v-else class="practice_report_body">

      <!-- ↓ CR, WT で同一 -->

      <table class="summery_table">
        <tr>
          <th class="correct_count my_h6">正解数</th>
          <td class="correct_count">{{ section1DataSet['正解数'] }}</td>
          <th class="correct_rate my_h6">正解率</th>
          <td class="correct_rate">{{ section1DataSet['正解率'] }}%</td>
        </tr>
      </table>

      <table class="question_table mt-3"><!-- CR,WTで同一 -->
        <thead>
          <tr>
            <th class="question_no my_h6">問題番号</th>
            <th class="unit my_h6">出題項目</th>
            <th class="correct_answer my_h6">正解</th>
            <th class="your_answer"><span class="my_h6" style="margin-right: 1em;">あなたの解答</span><br class="d-md-none" /><span class="small">※Nは無回答</span></th>
            <th class="correct_mark my_h6">正誤</th>
          </tr>
        </thead>
        <tbody>
          <tr class="section31" v-for="(row, idx) in section31DataSet['rows']" :key="`row_1_${idx}`">
            <td class="question_no">{{ row['問題番号'] }}</td>
            <td class="unit my_h6">{{ row['出題項目'] }}</td>
            <td class="correct_answer my_h6">{{ row['正解'].join('、') }}</td>
            <td class="your_answer my_h6">{{ row['あなたの解答'].length? row['あなたの解答'].map(value => value? value: 'N').join('、'): 'N' }}</td>
            <td class="correct_mark">{{ row['正誤マーク_2'] }}</td>
          </tr>
          <tr class="section32" v-for="(row, idx) in section32DataSet['rows']" :key="`row_2_${idx}`">
            <td class="question_no">{{ row['問題番号'] }}</td>
            <td class="unit my_h6">{{ row['出題項目'] }}</td>
            <td class="correct_answer my_h6">{{ row['正解'].join('、') }}</td>
            <td class="your_answer my_h6">{{ row['あなたの解答'].length? row['あなたの解答'].map(value => value? value: 'N').join('、'): 'N' }}</td>
            <td class="correct_mark">{{ row['正誤マーク_2'] }}</td>
          </tr>
        </tbody>
      </table>

      <!-- ↑ CR, WT で同一 -->

    </div><!-- practice_report_body -->

  </div><!-- report_wrapper -->
</template>

<script>
import baseMixin from './personal_report_base';
const LogHeader = 'PersonalReport_WT';

export default {
  props: {
  },
  mixins: [ baseMixin ],
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    const dev80_base_pos = 60.5;//調整用の偏差値80の枠が台紙の偏差値80の枠と重なるようにする
    const dev20_base_pos = 18.0;//調整用の偏差値20の枠が台紙の偏差値20の枠と重なるようにする
    const dev1_len = (dev80_base_pos - dev20_base_pos) / (80 - 20);
    return {
      radar_chart: {
        center_x: 61.5,//レーダーチャートの全体的な左右のズレはここで調整する
        center_y: 61.8,//レーダーチャートの全体的な上下のズレはここで調整する
        dev20_base_pos,
        dev1_len,
        line_width: 1.8,
      },
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    section1DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_1']; },
    section2DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_2']; },
    section31DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_3_1']; },
    section32DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_3_2']; },
  },
  methods: {
    calcRadarChartLen(deviationScore) {
      return this.radar_chart? this.radar_chart.dev20_base_pos + (deviationScore - 20) * this.radar_chart.dev1_len: 0;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/_base"

.report_wrapper

  .report_body
    svg
      display: block
      margin: 0 auto
      $orgWidth: 517
      $orgHeight: 675.589
      width: 84vw
      height: 84vw / $orgWidth * $orgHeight
      +mq(min_576)
        width: 80vw
        height: 80vw / $orgWidth * $orgHeight
      +mq(min_992)
        width: 60vw
        height: 60vw / $orgWidth * $orgHeight
      +mq(min_1200)
        width: 50vw
        height: 50vw / $orgWidth * $orgHeight

  .practice_report_body //$questionBgColor1, $questionBgColor2 を除き、CR,WTで同一
    $width: 100%
    .summery_table
      $bgColor: #A2E6FF
      width: $width
      th, td
        border: 1px solid black
        padding: 1em 0
        text-align: center
      th
        background-color: $bgColor
      th.correct_count
        width: 20%
      td.correct_count
        width: 25%
      th.correct_rate
        width: 20%
      td.correct_rate
        width: 25%
    .question_table
      $headerBgColor: #FFFFA2
      $questionBgColor1: #FFF5E9 //CR,WTで異なる
      $questionBgColor2: #EFFEFD //CR,WTで異なる
      width: $width
      th, td
        border: 1px solid black
        text-align: center
      th
        background-color: $headerBgColor
      .section31
        td.question_no, td.unit
          background-color: $questionBgColor1
      .section32
        td.question_no, td.unit
          background-color: $questionBgColor2
      td.unit
        text-align: unset
        +mq(min_576)
          padding-left: 2em
      td.correct_mark
        color: red
      .question_no
        width: 15%
      .unit
        width: 25%
      .correct_answer
        width: 15%
      .your_answer
        width: 30%
      .correct_mark
        width: 15%

</style>

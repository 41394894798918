<template>

  <div class="question_tab_buttons">
    <div class="question_tab_button" v-for="tabNo in tabCount" :key="`tab_button_${tabNo}`">
      <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="2em" viewBox="0 0 40 20" @click="onClickTabButton(tabNo)">
        <path d="M 0,0 L 10,20 30,20 40,0 0,0" stroke-width="1px" stroke="lightgray" :fill="internalTabNo==tabNo? '#00D3FF': 'white'" :ref="`tab_button_${tabNo}`" />
        <path d="M 40,0 L 0,0" stroke-width="1px" :stroke="internalTabNo==tabNo? '#00D3FF': 'lightgray'" />
        <text x="20" y="15" font-size="12" stroke="black" text-anchor="middle">{{ tabNo }}</text>
      </svg>
    </div>
  </div>

</template>

<script>
const LogHeader = 'QuestionTabButtons';
export default {
  // model: {
  //   prop: 'value',
  //   event: 'input',
  // },
  props: {
    value: { default: null },//internalTabNo
    tabCount: { default: 2 },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    internalTabNo: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue != this.value) {
          //console.log(`[${LogHeader}] emitting(input) newValue=[${newValue}]`);//デバッグ用（本番コメントアウト）
          this.$emit('input', newValue);
        }
      }
    }
  },
  methods: {
    onClickTabButton(tabNo) {
      this.internalTabNo = tabNo;
    }
  },
};
</script>

<style lang="sass" scoped>
</style>

const LogHeader = 'examination_parts/common_part';

export default {
  model: {
    prop: 'params',
    event: 'input',
  },
  props: {
    params: { default: null },
    canEdit: { default: false },//my_text_boxの入力モード(isEditing=true)遷移可／不可を表すが、管理画面／受験画面のどちらから呼ばれたかの判定にも用いている
    showDescription: { default: false },
    examinationPart: { default: null },
    currentPageNo: { default: null },//1-
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    currentPageComponent() { return (this.currentPageNo&&this.examinationPart)? this.examinationPart.page_component_names[this.currentPageNo-1]: null; },
    currentPageName() { return (this.currentPageNo&&this.examinationPart)? this.examinationPart.page_names[this.currentPageNo-1]: null; },
    allPageParamsSet() { return this.examinationPart? this.examinationPart.all_page_params_set: null; },
  },
  methods: {
  },
}

import MyTextBox from './MyTextBox';
import DescriptionTable from './DescriptionTable';
const LogHeader = 'examination_parts/common_page';

export default {
  components: {
    MyTextBox,
    DescriptionTable,
  },
  model: {
    prop: 'params',
    event: 'input',
  },
  props: {
    params: { default: null },
    examinationPart: { default: null },
    canEdit: { default: false },//my_text_boxの入力モード(isEditing=true)遷移可／不可を表すが、管理画面／受験画面のどちらから呼ばれたかの判定にも用いている
    showDescription: { default: false },
    isDebug: { default: false },
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      AnsMaxLen_本テスト: 7,
      AnsMaxLen_練習: 11,
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`, this.params);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`, this.params);
  },
  computed: {
    canAnswer() { return !(!this.canEdit && this.showDescription); },//学生側の解説表示でない？（＝チェックボックス選択＆テキスト入力可？）
    hasParams() {
      return this.params[this.questionIds[0]] &&
              (//問題修正管理でのonSave()時の delete 対策
                !this.showDescription || this.params[this.questionIds[0]].description//answerのチェックは省略
              )
      ;
    },
  },
  methods: {
    getRawDescAnswer(questionParams) {
      /* 210806
        受験後の解説時は、
          ・解説部には正解を（従来通り）
          ・問題表示部のチェックボックスには受験者の回答を（従来は正解を表示していた）
        表示する必要があるので、両者を params.answer で共用していたのを desc_answer と answer に分離した。
        但し、従来通り両者とも正解を表示（かつ連動）が必要な場合（問題修正管理）もあるので、
        desc_answer がセットされていなければ従来通りの動きをするようにする。
       */
      return questionParams.desc_answer || questionParams.answer || [/* onSave()時の delete answer 対策 */];
    },
    getDescAnswerStr(questionParams, delimiter) {
      const descAnswer = this.getRawDescAnswer(questionParams);
      return Array.isArray(descAnswer)? descAnswer.join(delimiter ?? '、'): descAnswer;
    },
    canClickCheckbox(choiceValue, groupQuestionIds, myQuestionId) {
      //console.log(`canClickCheckbox(choiceValue=[${choiceValue}],groupQuestionIds=[${groupQuestionIds}],myQuestionId=[${myQuestionId}])`);
      if (this.params[myQuestionId].answer == choiceValue) {//既に当該choiceValueをチェック済み（クリック動作＝チェックをはすす）？
        console.log(`canClickCheckbox(choiceValue=[${choiceValue}],groupQuestionIds=[${groupQuestionIds}],myQuestionId=[${myQuestionId}]) CALLED (True #1)`);
        return true;
      }
      for (let questionId of groupQuestionIds) {
        if (questionId != myQuestionId && this.params[questionId].answer == choiceValue) {//グループ内の他の設問で既に当該choiceValueをチェック済み？
          console.log(`canClickCheckbox(choiceValue=[${choiceValue}],groupQuestionIds=[${groupQuestionIds}],myQuestionId=[${myQuestionId}]) CALLED (False)`);
          return false;
        }
      }
      console.log(`canClickCheckbox(choiceValue=[${choiceValue}],groupQuestionIds=[${groupQuestionIds}],myQuestionId=[${myQuestionId}]) CALLED (True #2)`);
      return true;
    },
    //↓【利用非推奨】新たに利用する場合は onClickCheckbox2() を使用すること //XXX onClickCheckbox() と onClickCheckbox2() の主要ロジックの整合を維持すること！！（2021-12現在、排他選択以外のロジックは同一）
    onClickCheckbox(questionParams, refs, event) {
      console.log(`onClickCheckbox(questionParams.answer=[${questionParams.answer}] .is_multi=[${questionParams.is_multi}],event.target.value=[${event.target.value}] .checked=[${event.target.checked}])`, questionParams);
      if (this.canEdit) {//管理画面？
        if (questionParams.answer.length == 1 && !event.target.checked/*操作後の（＝目的の）値*/) {//現在１個しかないチェックを外そうとしている？
          alert('正解の選択なしの異常事態を防ぐ為に、先に新しい選択肢をチェックして下さい。');
          event.preventDefault();
        } else if (!confirm('正解の選択肢を変更してもよろしいですか？\n（最終的に更新ボタンを押下しない限り確定されません。）')) {
          event.preventDefault();
        }
      } else {//受験画面？
        //FIXME 管理画面側と違って、チェック動作（＝クリック）によりanswerや見た目のチェック状態（とchecked）が変更されない現象の対策
        console.log(`answer=[${questionParams.answer}] checked values=[${refs.map(ref => ref.checked).join(', ')}]`);
        if (questionParams.is_multi) {//複数選択可？
          if (questionParams.answer.includes(event.target.value)) {//チェック済をクリックした？
            questionParams.answer = questionParams.answer.filter(a => a != event.target.value);//チェックされた回答（選択肢）を除去
          } else {//未チェックをクリックした？
            questionParams.answer.push(event.target.value);//チェックされた回答（選択肢）を追加
          }
        } else {//複数選択不可？
          if (questionParams.answer.includes(event.target.value)) {//チェック済をクリックした？
            questionParams.answer = [];//チェックをはずす（＝無回答に変更）
          } else {//未チェックをクリックした？
            questionParams.answer = [ event.target.value ];//チェックを阻止せず、ラジオボタンのように振る舞う（＝チェックされた回答（選択肢）に変更）
          }
        }
        console.log(`answer=[${questionParams.answer}] checked values=[${refs.map(ref => ref.checked).join(', ')}]`);
        this.$nextTick(() => {
          for (let i=0; i<refs.length; i++) {
            refs[i].checked = (questionParams.answer.includes(refs[i].value));
          }
          console.log(`answer=[${questionParams.answer}] checked values=[${refs.map(ref => ref.checked).join(', ')}]`);
        });
      }
    },
    //XXX onClickCheckbox() と onClickCheckbox2() の主要ロジックの整合を維持すること！！（2021-12現在、排他選択以外のロジックは同一）
    onClickCheckbox2(questionId, refsIdx, event, groupQuestionIds/*排他選択チェックが必要な場合のみセット*/) {
      console.log(`onClickCheckbox2(questionId=[${questionId}],refsIdx=[${refsIdx}],event.target={value=[${event.target.value}],checked=[${event.target.checked}]},groupQuestionIds=[${groupQuestionIds}])`);
      const questionParams = this.params[questionId];
      const refs = this.$refs[refsIdx];

      if (this.canEdit) {//管理画面？

        if (questionParams.answer.length == 1 && !event.target.checked/*操作後の（＝目的の）値*/) {//現在１個しかないチェックを外そうとしている？
          alert('正解の選択なしの異常事態を防ぐ為に、先に新しい選択肢をチェックして下さい。');
          event.preventDefault();
        } else if (!confirm('正解の選択肢を変更してもよろしいですか？\n（最終的に更新ボタンを押下しない限り確定されません。）')) {
          event.preventDefault();
        }

      } else {//受験画面？

        //内部状態(questionParams.answer)を仕様通りに更新
        console.log(`answer=[${questionParams.answer}] checked=[${refs.map(ref => ref.checked).join(', ')}] (before)`);
        if (questionParams.is_multi) {//複数選択可？

          if (questionParams.answer.includes(event.target.value)) {//チェック済をクリックした？
            questionParams.answer = questionParams.answer.filter(a => a != event.target.value);//チェックされた回答（選択肢）を除去
          } else {//未チェックをクリックした？

            //回答グループが設定されている場合は排他選択チェックが必要
            const canCheck = Array.isArray(groupQuestionIds)? this.canClickCheckbox(event.target.value, groupQuestionIds, questionId): true;

            if (canCheck) {
              questionParams.answer.push(event.target.value);//チェックされた回答（選択肢）を追加
            } else {
              //何もしない（＝内部状態を維持） ※但し、見た目のチェック状態（checked）は変わってしまっているので、$nextTickで元に戻す必要がある
              console.log(`CAN NOT CHECK "${event.target.value}"`);
              alert(`選択肢${event.target.value}は既に選択済みです。`);
            }
          }

        } else {//複数選択不可？

          if (questionParams.answer.includes(event.target.value)) {//チェック済をクリックした？
            questionParams.answer = [];//チェックをはずす（＝無回答に変更）
          } else {//未チェックをクリックした？

            //回答グループが設定されている場合は排他選択チェックが必要
            const canCheck = Array.isArray(groupQuestionIds)? this.canClickCheckbox(event.target.value, groupQuestionIds, questionId): true;

            if (canCheck) {
              questionParams.answer = [ event.target.value ];//チェックを阻止せず、ラジオボタンのように振る舞う（＝チェックされた回答（選択肢）に変更）
            } else {
              //何もしない（＝内部状態を維持） ※但し、見た目のチェック状態（checked）は変わってしまっているので、$nextTickで元に戻す必要がある
              console.log(`CAN NOT CHECK "${event.target.value}"`);
              //alert(`選択肢${event.target.value}は既に選択済みです。`);
            }
          }

        }//if(is_multi)-else
        console.log(`answer=[${questionParams.answer}] checked=[${refs.map(ref => ref.checked).join(', ')}] (after)`);

        //内部状態を強制的に表示に反映 ※例えば、排他チェックNGの際はこれがないと内部状態通りの表示とならない
        this.$nextTick(() => {
          for (let i=0; i<refs.length; i++) {
            refs[i].checked = (questionParams.answer.includes(refs[i].value));
          }
          console.log(`answer=[${questionParams.answer}] checked=[${refs.map(ref => ref.checked).join(', ')}] (nextTick)`);
        });
      }//if(canEdit)-else
    },
    canClickRadio(choiceValue, questionId, myAnswerIdx) {
      //console.log(`canClickRadio(choiceValue=[${choiceValue}],questionId=[${questionId}],myAnswerIdx=[${myAnswerIdx}])`);
      if (this.params[questionId].answer[myAnswerIdx] == choiceValue) {//既に当該choiceValueをチェック済み（クリック動作＝チェックをはずす）？
        console.log(`canClickRadio(choiceValue=[${choiceValue}],questionId=[${questionId}],myAnswerIdx=[${myAnswerIdx}]) CALLED (True #1)`);
        return true;
      }
      for (let answerIdx in this.params[questionId].answer) {
        if (answerIdx != myAnswerIdx && this.params[questionId].answer[answerIdx] == choiceValue) {//設問内の他のanswerIdxで既に当該choiceValueをチェック済み？
          console.log(`canClickRadio(choiceValue=[${choiceValue}],questionId=[${questionId}],myAnswerIdx=[${myAnswerIdx}]) CALLED (False)`);
          return false;
        }
      }
      console.log(`canClickRadio(choiceValue=[${choiceValue}],questionId=[${questionId}],myAnswerIdx=[${myAnswerIdx}]) CALLED (True #2)`);
      return true;
    },
    //↓【利用非推奨】新たに利用する場合は onClickMultiRadio2() を使用すること //XXX onClickMultiRadio() と onClickMultiRadio2() の主要ロジックの整合を維持すること！！（2021-12現在、排他選択以外のロジックは同一）
    onClickMultiRadio(questionParams, answerIdx, refs, event) {
      console.log(`onClickMultiRadio(questionParams.answer=[${questionParams.answer}],answerIdx=[${answerIdx}]),event.target.value=[${event.target.value}] .checked=[${event.target.checked}]`, questionParams);
      //【注意】radioには「チェックを外す＝event.target.checkedがFalse」が発生しない！！
      if (this.canEdit) {//管理画面？
        if (event.target.value != questionParams.answer[answerIdx] &&//選択を変更しようとしている？
            !confirm('正解の選択肢を変更してもよろしいですか？\n（最終的に更新ボタンを押下しない限り確定されません。）')) {
          event.preventDefault();
        }
      } else {//受験画面？
        //FIXME 管理画面側と違って、チェック動作（＝クリック）によりanswer[answerIdx]や見た目のチェック状態（とchecked）が変更されない現象の対策
        //console.log(`refs=`, refs);
        console.log(`answer=[${questionParams.answer}]`);
        console.log(`answer[${answerIdx}]=[${questionParams.answer[answerIdx]}] checked values=[${refs.map(ref => ref.checked).join(', ')}]`);
        if (event.target.value == questionParams.answer[answerIdx]) {//チェック済みのradioをさらにクリックした？
          questionParams.answer[answerIdx] = '';//チェックを外す ※nullをセットするとremoveCommaやwideAlphaNumToHalfが動かないので注意
        } else {//現在のチェックと違うradioをクリックした？
          questionParams.answer[answerIdx] = event.target.value;//チェック動作でanswer[answerIdx]が“即座に”変更されない対策（この後もう一度チェック動作でここを通ると先ほど期待した値に書き換わっている。）
          //this.$set(questionParams.answer, answerIdx, event.target.value);//↑でも$setでも変わらなかった
          console.log(`answer[${answerIdx}]=[${questionParams.answer[answerIdx]}] checked values=[${refs.map(ref => ref.checked).join(', ')}]`);
        }
        this.$nextTick(() => {//answer[answerIdx]の変更がcheckedに波及しない対策
          for (let i=0; i<refs.length; i++) {
            refs[i].checked = (refs[i].value === questionParams.answer[answerIdx]);
          }
          console.log(`answer[${answerIdx}]=[${questionParams.answer[answerIdx]}] checked values=[${refs.map(ref => ref.checked).join(', ')}]`);
          console.log(`answer=[${questionParams.answer}]`);
        });
      }
    },
    //XXX onClickMultiRadio() と onClickMultiRadio2() の主要ロジックの整合を維持すること！！（2021-12現在、排他選択以外のロジックは同一）
    onClickMultiRadio2(questionId, refsIdx, answerIdx, event, isExclusive) {
      console.log(`onClickMultiRadio2(questionId=[${questionId}],refsIdx=[${refsIdx}],answerIdx=[${answerIdx}]),event.target={value=[${event.target.value}],checked=[${event.target.checked}]},isExclusive=[${isExclusive}]`);
      const questionParams = this.params[questionId];
      const refs = this.$refs[refsIdx];

      //【注意】radioには「チェックをはずす＝event.target.checkedがFalse」が発生しない！！
      if (this.canEdit) {//管理画面？

        if (event.target.value != questionParams.answer[answerIdx] &&//選択を変更しようとしている？
            !confirm('正解の選択肢を変更してもよろしいですか？\n（最終的に更新ボタンを押下しない限り確定されません。）')) {
          event.preventDefault();
        }

      } else {//受験画面？

        //内部状態(questionParams.answer)を仕様通りに更新
        //console.log(`refs=`, refs);
        console.log(`answer=[${questionParams.answer}] (before)`);
        console.log(`answer[${answerIdx}]=[${questionParams.answer[answerIdx]}] checked=[${refs.map(ref => ref.checked).join(', ')}] (before)`);
        if (event.target.value == questionParams.answer[answerIdx]) {//チェック済みのradioをさらにクリックした？
          questionParams.answer[answerIdx] = '';//チェックをはずす ※nullをセットするとremoveCommaやwideAlphaNumToHalfが動かないので注意
        } else {//現在のチェックと違うradioをクリックした？

          //排他選択が要求されている場合は同一answer内での選択肢の排他チェックが必要
          const canCheck = isExclusive? this.canClickRadio(event.target.value, questionId, answerIdx): true;

          if (canCheck) {
            questionParams.answer[answerIdx] = event.target.value;//チェック動作でanswer[answerIdx]が“即座に”変更されない対策（この後もう一度チェック動作でここを通ると先ほど期待した値に書き換わっている。）
            //this.$set(questionParams.answer, answerIdx, event.target.value);//↑でも$setでも変わらなかった
          } else {
            //何もしない（＝内部状態を維持） ※但し、見た目のチェック状態（checked）は変わってしまっているので、$nextTickで元に戻す必要がある
            console.log(`CAN NOT CHECK "${event.target.value}"`);
            //alert(`選択肢${event.target.value}は既に選択済みです。`);
          }

        }
        console.log(`answer[${answerIdx}]=[${questionParams.answer[answerIdx]}] checked=[${refs.map(ref => ref.checked).join(', ')}] (after)`);
        //console.log(`answer=[${questionParams.answer}] (after)`);

        //（念のため）内部状態を強制的に表示に反映 ※answer[answerIdx]の変更がcheckedに波及しない対策
        this.$nextTick(() => {
          for (let i=0; i<refs.length; i++) {
            refs[i].checked = (refs[i].value === questionParams.answer[answerIdx]);
          }
          console.log(`answer[${answerIdx}]=[${questionParams.answer[answerIdx]}] checked=[${refs.map(ref => ref.checked).join(', ')}] (nextTick)`);
          console.log(`answer=[${questionParams.answer}] (nextTick)`);
        });
      }//if(canEdit)-else
    },
    onChangeTextbox(event) {
      if (this.canEdit) {//管理画面？
        //TODO テキスト入力をキャンセルする（変更前に戻す）機能が未実装 ※最悪、無理なら実装をあきらめる（＝ここでは何もしない）
        // if (!confirm('正解を変更してもよろしいですか？\n（最終的に更新ボタンを押下しない限り確定されません。）')) {
        //   event.preventDefault();
        // }
      }
    },
    sortCheckboxAnswers(questionParams) {
      questionParams.answer.sort();//チェック順に格納されているのでソート
    },
    // allAnswersAreEmpty(doAlert) {
    //   if (doAlert === undefined) {
    //     doAlert = false;
    //   }
    //   let areAllEmpty = true;
    //   for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in this.params) {
    //     if (questionKey.match(/^q[0-9]+$/)) {
    //       const questionParams = this.params[questionKey];
    //       const answer = questionParams.answer ?? [];
    //       const isEmpty = answer.length === 0;
    //       areAllEmpty &= isEmpty;
    //       //console.log(`Answer [${questionKey}]=[${answer}] typeof=[${typeof answer}] Array.isArray()=[${Array.isArray(answer)}] length=[${answer.length}] isEmpty=[${isEmpty}]`);
    //       console.log(`Answer [${questionKey}]=[${answer}] isEmpty=[${isEmpty}]`);
    //     }
    //   }
    //   console.log(`areAllEmpty=[${areAllEmpty}]`);
    //   if (areAllEmpty && doAlert) {
    //     alert('解答していない問題があります。');
    //   }
    //   return areAllEmpty;
    // },
    // anyAnswerExceptFirstIsEmpty(doAlert) {
    //   if (doAlert === undefined) {
    //     doAlert = false;
    //   }
    //   let isAnyEmpty = false;
    //   for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in this.params) {
    //     if (questionKey.match(/^q[0-9]+$/)) {
    //       const questionParams = this.params[questionKey];
    //       const answer = questionParams.answer ?? [];
    //       const isEmpty = answer.length === 0;
    //       if (questionParams.q_no != this.params.first_q_no) {//ページ先頭の問題は未解答を許容
    //         isAnyEmpty |= isEmpty;
    //       }
    //       //console.log(`Answer [${questionKey}]=[${answer}] typeof=[${typeof answer}] Array.isArray()=[${Array.isArray(answer)}] length=[${answer.length}] isEmpty=[${isEmpty}]`);
    //       console.log(`Answer [${questionKey}]=[${answer}] isEmpty=[${isEmpty}]`);
    //     }
    //   }
    //   console.log(`isAnyEmpty=[${isAnyEmpty}]`);
    //   if (isAnyEmpty && doAlert) {
    //     alert('解答していない問題があります。');
    //   }
    //   return isAnyEmpty;
    // },
    canMovePage() {
      console.log(`[${LogHeader}] canMovePage() CALLED`);
      if (this.canAnswer) {
        if (this.tabCount !== undefined) {//タブあり？
          console.log(`tabHasBeenChanged=[${this.tabHasBeenChanged}]`);
          if (this.tabCount > 1 && !this.tabHasBeenChanged) {//タブが複数 かつ タブを一度も切り替えていない？
            alert('解答していない問題があります。');
            return false;
          }
        }
      }
      return true;
    },
  },//methods
  watch: {
    params: {
      handler(newValue, oldValue) {

        //newValue/oldValueが同一ページのparamsかどうかチェックする ※onSave()時に無駄にemitが発生する対策
        const newValueKeys = Object.keys(newValue).filter((value,index,array)=>value.startsWith('q')).sort().join('_');
        const oldValueKeys = Object.keys(oldValue).filter((value,index,array)=>value.startsWith('q')).sort().join('_');
        if (newValueKeys != oldValueKeys) {
          console.log(`[${LogHeader}] oldValueKeys=[${oldValueKeys}]`);
          console.log(`[${LogHeader}] newValueKeys=[${newValueKeys}] -> NOT emitting(input)`);
          return;
        }

        console.log(`[${LogHeader}] params changed -> emitting(input)`, this.params);
        this.$emit('input', this.params);
      },
      deep: true
    },
  },
}

<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WT-1 練習２ 検査１ p02</div><!-- 練習１ 検査１ p01 流用 -->

    <!-- ↓ p02 は単独 -->

    <MyTextBox class="" :canEdit="canEdit" v-model="params.s01" />

    <div class="row">

      <div class="col-12 col-md-6 mt-4 px-3">

        <div class="question_table_wrapper">
          <div class="question_table">
            <div :class="`question_table_row ${questionId}`" v-for="questionId in questionIds" :key="questionId">
              <div class="question_no">{{ params[questionId].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" v-model="params[questionId].question" />
            </div>
          </div>
        </div>

      </div>

      <div class="col-12 col-md-6 _mt-3 px-3">

        <div class="textbox_table_wrapper">
          <div class="textbox_table">
            <div :class="`textbox_table_row ${questionId}`" v-for="(questionId, idx) in questionIds" :key="questionId">
              <div class="question_no">{{ params[questionId].q_no }}</div>
              <input type="text" class="answer_textbox" :disabled="!canAnswer" v-model="params[questionId].answer[0]"
                      :maxlength="maxLens[idx]"
                      @change="onChangeTextbox"
              />
              （{{ maxLens[idx] }}字）
            </div>
          </div>
        </div>

      </div>

    </div><!-- row -->

    <!-- ↑ p02 は単独 -->

    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" :canUseFormula="true" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WT-1/練習２/検査１/p02';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q006', 'q007', 'q008', 'q009', 'q010' ],
      choiceValues: [ ],
      maxLens: [ 1, 3, 3, 2, 6],//XXX 正解一式から生成できた方がスマート
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
.answer_textbox
  width: 8em
</style>

<template>
  <div v-if="examinationPart&&currentPageComponent">
    <div v-if="isDebug" class="debug_str">ページのコンポーネント（{{ currentPageComponent }}）</div>
    <keep-alive>
      <component class="examination_page" :is="currentPageComponent" ref="refPageComponent" :isDebug="isDebug"
                  :examinationPart="examinationPart" :canEdit="canEdit" :showDescription="showDescription"
                  v-model="currentPageParams"
      />
    </keep-alive>

  </div>
</template>

<script>
import commonMixin from '../../common_exam_part';
import cr1_t2_p01 from './検査２/p01';
import cr1_t2_p02 from './検査２/p02';
import cr1_t2_p03 from './検査２/p03';
import cr1_t2_p04 from './検査２/p04';
import cr1_t2_p05 from './検査２/p05';
import cr1_t2_p06 from './検査２/p06';
import cr1_t2_p07 from './検査２/p07';
import cr1_t2_p08 from './検査２/p08';
import cr1_t2_p09 from './検査２/p09';
import cr1_t2_p10 from './検査２/p10';
const LogHeader = 'CR-1/本テスト/検査２';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    cr1_t2_p01,
    cr1_t2_p02,
    cr1_t2_p03,
    cr1_t2_p04,
    cr1_t2_p05,
    cr1_t2_p06,
    cr1_t2_p07,
    cr1_t2_p08,
    cr1_t2_p09,
    cr1_t2_p10,
  },
  props: {
    isDebug: { default: false },
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
    currentPageParams: {
      get() {
        return this.allPageParamsSet[this.currentPageName];
      },
      set(newValue) {//子コンポーネントで変更が発生したが為に呼ばれるので変更チェックは不要
        //console.log(`[${LogHeader}] pageParams(${this.currentPageName}) changed -> emitting(input)`, newValue);//デバッグ用（本番コメントアウト）
        this.$emit('input', {
          p01: this.currentPageName=='p01'? newValue: this.params.p01,
          p02: this.currentPageName=='p02'? newValue: this.params.p02,
          p03: this.currentPageName=='p03'? newValue: this.params.p03,
          p04: this.currentPageName=='p04'? newValue: this.params.p04,
          p05: this.currentPageName=='p05'? newValue: this.params.p05,
          p06: this.currentPageName=='p06'? newValue: this.params.p06,
          p07: this.currentPageName=='p07'? newValue: this.params.p07,
          p08: this.currentPageName=='p08'? newValue: this.params.p08,
          p09: this.currentPageName=='p09'? newValue: this.params.p09,
          p10: this.currentPageName=='p10'? newValue: this.params.p10,
        });
      }
    },
  },
  methods: {
    canMovePage() {
      console.log(`[${LogHeader}] canMovePage() CALLED`);
      if (typeof this.$refs.refPageComponent.canMovePage === 'function') {
        return this.$refs.refPageComponent.canMovePage();
      }
      return true;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>

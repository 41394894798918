<template>
  <div class="description_table_wrapper">
    <div class="description_table">
      <div class="description_header">
      <div class="question_no">
        <slot name="q_no"></slot>
      </div>
      <div class="desc_answer">
        <slot name="answer"></slot>
      </div>
      </div>
      <div class="description_body">
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script>
//const LogHeader = 'examination_parts/desc_table';

export default {
//   mixins: [
//   ],
//   props: {
//   },
//   data() {
//     console.log(`[${LogHeader}] data() CALLED`);
//     return {
//     };
//   },
//   created() {
//     console.log(`[${LogHeader}] created() CALLED`);
//   },
//   mounted() {
//     console.log(`[${LogHeader}] mounted() CALLED`);
//   },
//   computed: {
//   },
//   methods: {
//   },
};
</script>

<style lang="sass" scoped>
</style>

<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WT-1 本テスト 検査２ p16</div>

    <!-- ↓ p16 は単独 -->

    <div class="row">
      <div class="col-12 px-3">
        <MyTextBox class="" :isTextarea="true" :canEdit="canEdit" v-model="params.s01" />
        <!-- <MyTextBox class="" :isTextarea="true" :canEdit="canEdit" v-model="params.s02" /> -->
      </div>
    </div><!-- row -->

    <div :class="`row ${questionIds[0]}`"><!-- q016 -->

      <div class="col-12 mt-3 px-3">
        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no">{{ params[questionIds[0]].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" v-model="params[questionIds[0]].question" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 px-3">
        <div class="textbox_table_wrapper ml-md-5">
          <div class="textbox_table">
            <div class="textbox_table_row">
              <input type="text" class="answer_textbox text-right" :disabled="!canAnswer" v-model="params[questionIds[0]].answer[0]"
                      :maxLength="AnsMaxLen_本テスト"
                      @change="onChangeTextbox"
              />
            </div>
          </div>
        </div>
      </div>

    </div><!-- row -->

    <div :class="`row ${questionIds[1]}`"><!-- q017 -->

      <div class="col-12 mt-3 px-3">
        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no">{{ params[questionIds[1]].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" v-model="params[questionIds[1]].question" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 px-3">
        <div class="choice_table_wrapper ml-md-5">
          <div class="choice_table bg-lightyellow">
            <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
              <div class="choice_value text-red right_margin">{{ choiceValue }}</div>
              <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[1]].choices[choiceIdx]" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 px-3">
        <div class="checkbox_table_wrapper ml-md-5">
          <table class="checkbox_table">
            <tr>
              <td v-for="choiceValue in choiceValues[0]" :key="choiceValue"><div class="choice_value">{{ choiceValue }}</div></td>
            </tr>
            <tr>
              <td v-for="choiceValue in choiceValues[0]" :key="choiceValue">
                <input type="checkbox" :id="`${questionIds[1]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                        v-model="params[questionIds[1]].answer"
                        :ref="`checkbox_q${questionIds[1]}`"
                        @click="onClickCheckbox(params[questionIds[1]], $refs[`checkbox_q${questionIds[1]}`], $event)"
                        @change="sortCheckboxAnswers(params[questionIds[1]])"
                />
                <label :for="`${questionIds[1]}_${choiceValue}`" class="choice_checkbox_placeholder"></label>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div><!-- row -->

    <!-- ↑ p16 は単独 -->

    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" :canUseFormula="true" v-model="params[questionId].description" /><!-- MathJax -->
          </template>
        </DescriptionTable>
      </div>

    </div>

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WT-1/本テスト/検査２/p16';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q016', 'q017' ],
      choiceValues: [
        [ 'A', 'B', 'C', 'D', 'E', 'F' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
.q016
  .answer_textbox
    width: 8em
</style>
